
<div v-if="total > perPage">
    <ul class="ecm-pagination">
        <li v-if="currentPage !== 1" class="page-item">
            <button class="arrow-button left-arrow" @click.prevent="changePage(currentPage - 1)"><icon key="left" icon="angle-left-solid" /></button>
        </li>
        

        <!-- If there is maximum of 6 pages in total, show them all -->
        <li v-if="lastPage < 7" class="page-items-container">
            <div v-for="page in lastPage" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </div>
        </li>

        <!-- If there is more than 6 pages, show only nearest 4 pages + first page / last page + ... after first page / ... before last page -->
        <li v-if="lastPage > 6 && currentPage > 3" class="page-item first-page">
            <a href="#" @click.prevent="changePage(1)">1</a>
        </li>
        
        <li v-if="lastPage > 6 && currentPage > 4" class="page-item">
            <span>...</span>
        </li>

        <li v-if="lastPage > 6" class="page-items-container">
            <div v-for="page in visiblePages" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
            </div>
        </li>

        <li v-if="lastPage > 6 && lastPage - currentPage > 3" class="page-item">
            <span>...</span>
        </li>

        <li v-if="lastPage > 6 && lastPage - currentPage > 2" class="page-item last-page">
            <a href="#" @click.prevent="changePage(lastPage)">{{ lastPage }}</a>
        </li>
        

        <li v-if="currentPage !== lastPage" class="page-item">
            <button class="arrow-button right-arrow" @click.prevent="changePage(currentPage + 1)"><icon key="right" icon="angle-right-solid" /></button>
        </li>
    </ul>
</div>
