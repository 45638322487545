
<div>
    <!-- Agency stripe -->
    <div v-if="newAgencyBarVisible" class="py-1" :style="{backgroundColor: accountAgency.color}" />
    <nav class="ecomail-navbar">

        <div class="ec-container">

            <notifications :channel-name="notificationsChannel" />

            <div v-if="newAgencyBarVisible && !smallScreen">
                <button type="button" class="flex items-center z-ultimate group" @click="toggleDropdown()">
                    <img v-if="currentAccount.logo" class="h-[36px] object-contain w-[75px] rounded-[3px]" :src="currentAccount.logo" alt="Current account logo">
                    <div v-else-if="currentAccount.logoPlaceholder" class="flex h-[36px] items-center justify-center w-[75px] rounded-[3px]" :style="currentAccount.logoPlaceholder">
                        <div>
                            {{ getInitials(currentAccount.name, 4) }}
                        </div>
                    </div>

                    <p class="font-medium ml-2 mr-1 xl:ml-4 xl:mr-2 text-gray-600 truncate max-w-[170px] txt-13 group-hover:text-white">
                        {{ currentAccount.name }}
                    </p>
                    <icon class="text-gray-600 group-hover:text-white" icon="angle-down-solid" />
                </button>

                <div v-if="dropdownOpen" ref="dropdown" class="agency-bar">
                    <div v-on-clickaway="closeDropdown" class="agency-bar__dropdown">
                        <div class="agency-bar__top rounded-t-[4px]">
                            <p class="agency-bar__description">
                                {{ $t('navbar.in_current_account') }}
                            </p>
                            <div class="agency-bar__dropdown-selected">
                                <img v-if="currentAccount.logo" class="object-contain rounded-[3px]" :src="currentAccount.logo" alt="Current account logo">
                                <div v-else-if="currentAccount.logoPlaceholder" class="agency-bar__logo-placeholder" :style="currentAccount.logoPlaceholder">
                                    <div>{{ getInitials(currentAccount.name, 4) }}</div>
                                </div>
                                <div class="flex flex-col items-start ml-4">
                                    <strong class="agency-bar__account-name">
                                        {{ currentAccount.name }}
                                    </strong>
                                    <strong class="agency-bar__account-url">
                                        {{ currentAccount.url }}
                                    </strong>
                                </div>
                            </div>
                        </div>
                        <div class="agency-bar__dropdown-options">
                            <p class="agency-bar__description">
                                {{ $t('navbar.log_into') }}
                            </p>
                            <div class="agency-bar__dropdown-options-list">
                                <a
                                        v-if="loggedFromAgency"
                                        :href="`https://${agencyDomain}.ecomailapp.${topLevelDomain}`"
                                        class="agency-bar__dropdown-option">
                                    <img
                                            v-if="agencyLogo"
                                            class="object-contain rounded-[3px]"
                                            :src="agencyLogo"
                                            alt="Agency logo">
                                    <div v-else-if="agencyLogoPlaceholder" class="agency-bar__logo-placeholder" :style="agencyLogoPlaceholder">
                                        <div>
                                            {{ getInitials(agencyDisplayName, 4) }}
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-start ml-4">
                                        <strong class="agency-bar__account-name">
                                            {{ agencyDisplayName }}
                                        </strong>
                                        <strong class="agency-bar__account-url">
                                            {{ `${agencyDomain}.ecomailapp.${topLevelDomain}` }}
                                        </strong>
                                    </div>
                                </a>
                                <a
                                        v-for="subaccount in subaccounts"
                                        :key="subaccount.name"
                                        :href="getSubaccountLoginUrl(subaccount.name)"
                                        class="agency-bar__dropdown-option"
                                        :class="isCurrentAccount(subaccount.name) && 'agency-bar__dropdown-option--selected'">
                                    <img
                                            v-if="getSubaccountLogo(subaccount)"
                                            class="object-contain rounded-[3px]"
                                            :src="getSubaccountLogo(subaccount)"
                                            alt="Subaccount logo">
                                    <div v-else-if="getSubaccountLogoPlaceholder(subaccount)" class="agency-bar__logo-placeholder" :style="getSubaccountLogoPlaceholder(subaccount)">
                                        <div>{{ getInitials(getSubaccountDisplayName(subaccount), 4) }}</div>
                                    </div>
                                    <div class="flex flex-col items-start ml-4">
                                        <strong class="agency-bar__account-name">
                                            {{ getSubaccountDisplayName(subaccount) }}
                                        </strong>
                                        <strong class="agency-bar__account-url">
                                            {{ getSubaccountUrl(subaccount.name) }}
                                        </strong>
                                    </div>
                                    <icon v-if="isCurrentAccount(subaccount.name)" icon="circle-check-solid" size="large" class="text-messages-info-300 ml-auto mr-2.5" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="logo-container">
                <a href="/">
                    <img src="/images/logo-tiny.png" alt="Ecomail.cz">
                </a>
            </div>

            <div class="content">
                <div class="main-options">
                    <template v-for="(section, index) in sections">
                        <router-link v-if="isSpa && section.isSpa" :key="`section-${index}`" :to="{name: section.name}" class="main-link">
                            {{ section.label }}
                        </router-link>
                        <a v-else :key="`section-${index}`" :href="section.link" class="main-link" :class="{'active': active === section.active}">
                            {{ section.label }}
                        </a>
                    </template>
                    <span v-if="checkRole(user, 'manage-more') && user.role !== 'reader' && user.role !== 'support'" v-dropdown="'open'" class="tw-dropdown">
                        <a class="main-link" :class="{'more-section-active': moreSectionActive}" dropdown-toggle>
                            {{ $t('navbar.more') }}
                            <icon class="angle-icon" icon="angle-down-solid" />
                        </a>
                        <div class="tw-dropdown-items">
                            <div>
                                <template v-for="(section, index) in moreSections">
                                    <template v-if="!section.hideForEnglishAccount || !account.isEnglishAccount">
                                        <a :key="`more-section-${index}`" :href="section.link">
                                            {{ section.label }}
                                        </a>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="user-options">
                    <div class="onboarding txt-13">
                        <a
                                v-if="account.isEnglishAccount"
                                href="#"
                                @click.prevent="showOnboardingModal()">
                            <icon class="onboarding-icon" icon="circle-question-solid" size="medium" />
                            {{ $t('navbar.onboarding') }}
                        </a>
                        <a v-else target="_blank" :href="$t('common.support_link')">
                            <icon class="onboarding-icon" icon="circle-question-solid" size="medium" />
                            {{ $t('navbar.hint') }}
                        </a>
                    </div>
                    <div v-if="checkRole(user, 'manage-notifications') && user.role !== 'reader' && user.role !== 'support'" class="notifications-wrapper" :class="account && account.isEnglishAccount && 'notifications-wrapper--border-r'">
                        <notifications-menu :notifications="notifications" />
                    </div>
                    <user-menu :is-english-account="account.isEnglishAccount" :gravatar="gravatar" :user="user" :is-agency="account.isAgency" :is-spa="false" @logout="logout" />
                    <a href="#" class="search" @click.prevent="showSearchModal"><icon icon="search-solid" /></a>
                </div>
            </div>

        </div>
    </nav>
    <nav class="ecomail-navbar-mobile">

        <div class="ec-container">

            <div class="logo-container">
                <div :class="{'mobile-active': searchOpened || mobileNavOpened }">
                    <a href="/">
                        <img alt="Ecomail.cz" src="https://ecomail-assets.s3.amazonaws.com/old/images/logo-tiny.png">
                    </a>
                    <portal-target class="section-name" name="navbar-section-name" />
                </div>
                <div>
                    <div class="notifications-section" :class="{'mobile-active': searchOpened || mobileNavOpened }">
                        <ul v-if="checkRole(user, 'manage-notifications') && user.role !== 'reader' && user.role !== 'support'" class="notifications-wrapper">
                            <notifications-menu :notifications="notifications" />
                        </ul>
                    </div>
                    <div class="menu-divider" :class="{'mobile-active': searchOpened }" />
                    <button class="search-section" :class="{'mobile-active': mobileNavOpened, 'mobile-active-exact': searchOpened }" @click="toggleSearch">
                        <icon v-if="!searchOpened" icon="search-solid" size="large" />
                        <icon v-else key="close-left" class="close-icon" icon="times-solid-thin" size="large" />
                    </button>
                    <div class="menu-divider" :class="{'mobile-active': mobileNavOpened || searchOpened }" />
                    <button class="menu-section" :class="{'mobile-active': searchOpened, 'mobile-active-exact': mobileNavOpened }" @click="toggleNav">
                        <icon v-if="!mobileNavOpened" icon="hamburger" size="large" />
                        <icon v-else key="close-right" class="close-icon" icon="times-solid-thin" size="large" />
                    </button>
                </div>
            </div>

            <div class="search-content" :class="{'search-open': searchOpened}">
                <input v-model="query" class="tw-input" type="text" :placeholder="$t('navbar.search_placeholder')">
                <button class="btn btn-primary btn-lg" @click="triggerSearch">{{ $t('navbar.search_button') }}</button>
            </div>

            <div class="content" :class="{'nav-open': mobileNavOpened}">
                <div class="main-options">
                    <template v-for="(section, index) in sections">
                        <router-link v-if="isSpa && section.isSpa" :key="`section-${index}`" :to="{name: section.name}" class="main-link">
                            {{ section.label }}
                        </router-link>
                        <a v-else :key="`section-${index}`" :href="section.link" class="main-link" :class="{'active': active === section.active}">
                            {{ section.label }}
                        </a>
                    </template>
                    <!-- Agency sections -->
                    <span
                            v-if="newAgencyBarVisible && smallScreen"
                            class="tw-dropdown">
                        <a
                                class="main-link"
                                @click.prevent="toggleDropdown()">
                            <icon
                                    v-if="dropdownOpen"
                                    key="caret-down"
                                    class="caret-icon"
                                    icon="caret-down-solid" />
                            <icon
                                    v-else
                                    key="caret-right"
                                    class="caret-icon"
                                    icon="caret-right-solid" />
                            {{ $t('navbar.log_into') }}
                        </a>
                    </span>
                    <div v-if="dropdownOpen" class="tw-dropdown-items">
                        <a
                                v-if="loggedFromAgency"
                                :href="`https://${agencyDomain}.ecomailapp.${topLevelDomain}`">
                            {{ agencyDisplayName }}
                        </a>
                        <a
                                v-for="subaccount in subaccounts"
                                :key="subaccount.name"
                                :href="`/account/login-as/${subaccount.name}`">
                            {{ getSubaccountDisplayName(subaccount) }}
                        </a>
                    </div>
                    <!-- -->
                    <span v-if="checkRole(user, 'manage-more') && user.role !== 'reader' && user.role !== 'support'" v-dropdown="'open'" class="tw-dropdown">
                        <a class="main-link" :class="{'more-section-active': moreSectionActive || moreSectionFocus}" dropdown-toggle @click.prevent="moreSectionFocus = ! moreSectionFocus">
                            <icon v-if="moreSectionFocus" key="caret-down" class="caret-icon" icon="caret-down-solid" />
                            <icon v-else key="caret-right" class="caret-icon" icon="caret-right-solid" />
                            {{ $t('navbar.more') }}
                        </a>
                        <div class="tw-dropdown-items">
                            <div>
                                <template v-for="(section, index) in moreSections">
                                    <template v-if="!section.hideForEnglishAccount || !account.isEnglishAccount">
                                        <a :key="`more-section-${index}`" :href="section.link">
                                            {{ section.label }}
                                        </a>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </span>
                </div>
                <a class="help-link" :href="$t('common.support_link')" target="_blank"><icon class="help-icon" icon="question-circle-solid" />{{ $t('usermenu.help') }}</a>

                <div class="user-options">
                    <a class="user-link" href="/account">
                        <img :src="gravatar" alt="Avatar">
                        <span>{{ user.email }}</span>
                        <icon icon="caret-right-solid" />
                    </a>
                    <a class="logout" href="#" @click.prevent="logout"><icon class="logout-icon" icon="unlock-solid" />{{ $t('usermenu.logout') }}</a>
                </div>
            </div>

        </div>
    </nav>

    <OnboardingModal ref="onboardingModal" />
</div>
