<template>

    <transition name="slidein">
        <div v-if="visible" class="search-modal-bg" @mousedown.self="closeModal" @keyup="keyUp">

            <div class="search-modal-container">
                <div class="search-modal-content">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center w-full">
                            <input ref="search-input" v-model="query" type="text" :placeholder="$t('search-modal.input_placeholder')" class="ml-1">
                        </div>
                        <button class="btn btn-primary btn-lg" @click="search">{{ $t('search-modal.button') }}</button>
                    </div>
                </div>

            </div>

        </div>
    </transition>

</template>

<script>

import Tracker from "@js/tracker";

export default {

    name: 'TwModal',

    props: ['title', 'closeButton', 'primaryButtonText', 'size', 'classname'],


    data () {
        return {
            visible: false,
            mdata: null,
            mtitle: null,
            startingElement: null,
            callbacks: {},
            query: ''
        }
    },

    computed: {
        mclass () {
            return this.classname
        }
    },

    watch: {},

    created: function () {
        document.addEventListener('keyup', (evt) => this.keyUp(evt))
    },

    methods: {

        showModal() {
            this.mdata = null
            this.visible = true

            this.$nextTick(() => {
                this.$refs['search-input'].focus()
            })
        },

        keyUp(event) {
            if (event.keyCode === 27) {
                this.closeModal()
            } else if(event.keyCode === 13) {
                this.search()
            }
        },

        showModalWithData(data) {
            this.mdata = JSON.parse(data)
            this.visible = true
        },

        addCallback(name, callback) {
            this.callbacks[name] = callback
        },

        showModalRawData(data) {
            this.mdata = data
            this.visible = true
        },

        setTitle(title) {
            this.mtitle = title
        },

        search() {
            if(!this.query) {
                return
            }
            window.location.href = encodeURI(`/search?query=${encodeURIComponent(this.query)}`)
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'search',
                name: 'match search'
            })
        },

        closeModal() {
            this.visible = false
            this.$emit('closed')
        }

    },

}
</script>
