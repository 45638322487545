import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        'newTemplateName': null,
    },


    getters: {
        newTemplateName: state => state.newTemplateName,
    },

    mutations: {
        SET_NEW_TEMPLATE_NAME (state, name) {
            state.newTemplateName = name
        }
    },

    actions: {
        setNewTemplateName ({commit}, name) {
            commit('SET_NEW_TEMPLATE_NAME', name)
        },
    }

})
