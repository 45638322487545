<template>

    <transition name="slidein">
        <div v-if="visible" class="fixed top-0 left-0 w-full h-full z-50 grid modal-bg overflow-auto" style="background-color: #0f1120a6" @mousedown.self="closeModal" @keyup="keyUp">

            <div class="bg-white m-auto rounded" style="width: 592px; max-width: 592px">
                
                <div class="flex items-start justify-between">
                    <div class="font-semibold text-gray-1000 txt-20 mt-[18px] ml-6">
                        {{ $t('onboarding_modal.header') }}
                    </div>

                    <div class="flex justify-end mt-3 mr-4">
                        <a href="#" class="p-0.5 bg-gray-500 hover:bg-gray-600 rounded-full modal-close" @click.stop.prevent="closeModal">
                            <icon icon="times-solid" size="small" class="text-gray-800" />
                        </a>
                    </div>
                </div>
                <div class="pt-0 p-6">

                    <ul class="mt-4 space-y-3">
                        <li
                                v-for="item in items"
                                :key="`onboarding-${item.name}`"
                                class="bg-gray-50 border border-gray-500 cursor-pointer flex gap-5 hover:bg-white hover:border-2 hover:border-green-400 hover:px-[15px] hover:py-[11px] items-center px-4 py-3 rounded item-shadow"
                                @click="goToUrl(item)">
                            <div class="flex flex-none h-15 rounded-full icon-shadow w-15 text-green-500">
                                <svg-vue :icon="item.icon" :style="`width: ${item.iconWidth}`" class="m-auto" :class="item.name === 'integrations' && 'rotate-[36deg]'" />
                            </div>
                            <div class="flex flex-col gap-[3px]">
                                <div class="font-medium text-base text-gray-1000">
                                    {{ $t(`onboarding_modal.${item.name}.heading`) }}
                                </div>
                                <div class="text-gray-900 txt-14" v-html="$t(`onboarding_modal.${item.name}.description`)" />
                            </div>
                        </li>
                    </ul>

                    <a type="button" class="btn btn-primary btn-lg btn-fluid mt-5" :href="$t('common.support_link')" target="_blank">
                        {{ $t('onboarding_modal.button') }}
                        <icon icon="arrow-right-solid" size="large" class="ml-3" />
                    </a>
                </div>
            </div>

        </div>
    </transition>

</template>

<script>
import Tracker from '../../tracker'

export default {

    name: 'OnboardingModal',

    data () {
        return {
            visible: false,
            items: [
                {
                    name: 'introduction',
                    icon: 'flag-checkered-duotone',
                    iconWidth: 28,
                },
                {
                    name: 'integrations',
                    icon: 'plug-duotone',
                    iconWidth: 24,
                },
                {
                    name: 'import',
                    icon: 'file-import-duotone',
                    iconWidth: 32,
                },
                {
                    name: 'domain',
                    icon: 'browser-duotone',
                    iconWidth: 32,
                },
                {
                    name: 'campaign',
                    icon: 'paper-plane-duotone',
                    iconWidth: 32,
                },
            ],
        }
    },

    watch: {
        visible(val) {
            this.$root.modalOpened = val
        }
    },

    mounted() {
        this.visible = this.$route.query.showOnboarding !== undefined
    },

    created() {
        document.addEventListener('keyup', (evt) => this.keyUp(evt))
        this.$options.tracker = new Tracker()
    },

    methods: {

        showModal() {
            this.visible = true
            this.$options.tracker.trackEvent('BUTTON_CLICKED', {
                scope: 'onboarding',
                name: 'open onboarding modal',
            })
        },

        keyUp(event) {
            if (event.keyCode === 27) {
                this.closeModal()
            }
        },

        closeModal() {
            if(this.$route.query.showOnboarding !== undefined) {
                this.$router.replace({ query: {...this.$route.query, showOnboarding: undefined} })
            }
            this.$options.tracker.trackEvent('BUTTON_CLICKED', {scope: 'onboarding', name: 'close onboarding modal'})
            this.visible = false
            this.$emit('closed')
        },

        goToUrl(item) {
            window.open(this.$t(`onboarding_modal.${item.name}.link`), '_blank')
            this.$options.tracker.trackEvent('BUTTON_CLICKED', {scope: 'onboarding', name: 'link visited', link: item.name})
        },

    }
}
</script>

<style lang="scss" scoped>
.item-shadow {
    box-shadow: 0px 1px 2px 0px rgba(16,24,40,0.05);

    &:hover {
        box-shadow: 0px 2px 4px -2px rgba(16,24,40,0.06), 0px 4px 8px -2px rgba(16,24,40,0.1);
    }
}

.icon-shadow {
    box-shadow: 0px 4px 6px -2px rgba(16,24,40,0.03), 0px 12px 16px -4px rgba(16,24,40,0.08);
}
</style>
