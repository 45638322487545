
<div>
    <portal v-if="activated" to="automations-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'automationsReport', params: {automation: automation.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ automation.name }}
                </router-link>
                <div class="hidden lg:block">
                    <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="hidden lg:block txt-18 text-gray-1000">{{ $t('automations.testing.title') }}</span>
            </div>
            <div v-if="!account.isBlocked">
                <ecm-switch :class="{'cursor-not-allowed': sending}" :value="automation.started" :disabled="switching" @input="toggleAutomation">
                    <span class="font-semibold" :class="{'text-green-600': automation.started}">{{ automation.started ? $t('automations.testing.automation_started') : $t('automations.testing.automation_paused') }}</span>
                </ecm-switch>
            </div>
        </div>
    </portal>

    <alert v-if="switchSuccess === true" class="mb-6" type="success" :message="switchMessage" />
    <alert v-if="switchSuccess === false" class="mb-6" type="error" :message="switchMessage" />

    <div class="flex flex-col lg:flex-row lg:space-x-9 space-y-2.5 lg:space-y-0">
        <div class="flex flex-col w-full lg:w-5/12 space-y-1 lg:mt-2">
            <h4 class="text-gray-1000 font-normal" v-html="$t('automations.testing.heading')" />
            <p class="text-gray-900 txt-14" v-html="$t('automations.testing.description')" />
        </div>
        <div class="panel pt-8 px-9 pb-9 flex flex-col w-full lg:w-7/12">
            <alert class="mb-6" type="info" :message="$t('automations.testing.alert')" />
            <alert v-if="success === true" class="mb-6" type="success" :message="message" />
            <alert v-if="success === false" class="mb-6" type="error" :message="message" />
            <ValidationObserver ref="observer" slim>
                <form class="mb-0" @submit.prevent="sendTestingEmail">
                    <ValidationProvider v-slot="{ errors }" name="testingEmail" rules="required" class="flex flex-col mb-4">
                        <div class="flex flex-col">
                            <label for="testingEmail" class="tw-label mb-2.5">{{ $t('automations.testing.email_label') }}</label>
                            <input id="testingEmail" v-model="email" type="email" name="email" class="tw-input" style="max-width: 330px" :placeholder="$t('automations.testing.email_placeholder')">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    
                    <ValidationProvider v-slot="{ errors }" name="trigger" rules="required" class="flex flex-col">
                        <div class="flex flex-col mb-8">
                            <ecm-select
                                    id="trigger"
                                    v-model="trigger"
                                    style="max-width: 330px"
                                    :options="triggers ? Object.values(triggers) : null"
                                    option-value-attribute="trigger_id"
                                    option-display-attribute="displayValue"
                                    :disabled="!triggers"
                                    :label="$t('automations.testing.triggers_label')"
                                    :title="$t('automations.testing.triggers_title')" />
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    <button :disabled="sending" type="submit" class="btn btn-lg btn-primary"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('automations.testing.test_button') }}</button>
                </form>
            </ValidationObserver>
        </div>
    </div>
</div>
