<template>
    <div
            class="flex items-center"
            :class="{ 'sm:mr-2': isThirdChild, 'sm:ml-2': isFourthChild }">
        <input
                ref="input"
                :value="value"
                class="w-10 py-[7px] text-3xl font-medium leading-10 text-auth-gray-900"
                :type="inputType"
                pattern="[0-9]+"
                :class="inputClasses"
                @input="handleOnChange"
                @keydown="handleOnKeyDown"
                @focus="handleOnFocus"
                @blur="handleOnBlur">
        <span v-if="!isLastChild && separator">
            <span v-html="separator" />
        </span>
    </div>
</template>

<script lang="ts">

export default {
    name: 'SingleOtpInput',

    props: {
        inputType: {
            type: String,
            default: 'tel',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        separator: {
            type: String,
            default: '',
        },
        focus: {
            type: Boolean,
        },
        inputClasses: {
            type: String,
            default: '',
        },
        shouldAutoFocus: {
            type: Boolean,
        },
        numInputs: {
            type: Number,
            default: 6,
        },
        isLastChild: {
            type: Boolean,
        },
        isThirdChild: {
            type: Boolean,
        },
        isFourthChild: {
            type: Boolean,
        },
    },

    data() {
        return {
        }
    },

    watch: {
        focus(newFocusValue, oldFocusValue) {
            if (
                oldFocusValue !== newFocusValue &&
                  this.focus
            ) {
                this.$refs.input.focus()
                this.$refs.input.select()
            }
        }
    },

    mounted() {
        if (this.focus && this.shouldAutoFocus) {
            this.$refs.input.focus()
            this.$refs.input.select()
        }
    },

    methods: {
        handleOnChange(event) {
            if(event.target?.value) {
                let localValue = event.target.value.toString()
                if (localValue.length === this.numInputs) {
                    this.$emit('on-paste', localValue)
                }
                else {
                    const firstNumber = localValue.slice(0,1)
                    this.$refs.input.value = firstNumber
                    this.$emit('on-change', firstNumber)
                }
            }
        },
        isCodeNumeric(charCode) {
            return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
        },
        handleOnKeyDown(event) {
            const keyEvent = event || window.event
            const charCode = keyEvent.which ? keyEvent.which : keyEvent.keyCode
            if (
                this.isCodeNumeric(charCode) ||
                [8, 9, 13, 37, 39, 46, 86].includes(charCode)
            ) {
                this.$emit('on-keydown', event)
            } else {
                keyEvent.preventDefault()
            }
        },
        handleOnFocus() {
            this.$refs.input.select()
            this.$emit('on-focus')
        },
        handleOnBlur() {
            this.$emit('on-blur')
        }
    },

}
</script>

<style scoped></style>