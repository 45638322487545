
<div>
    <alert
            v-for="(globalMessage, i) in globalMessages"
            :key="`global-message-${i}`"
            :message="globalMessage.message"
            :label="globalMessage.title"
            :link="linkLeadsElsewhere(globalMessage.link) ? globalMessage.link : null"
            class="mb-4"
            :type="globalMessage.type"
            :closable="globalMessage.type === 'info'"
            :primary-action="globalMessage.primaryAction === 'supportLink' ? supportLink: globalMessage.primaryAction"
            :secondary-action="globalMessage.secondaryAction === 'supportLink' ? supportLink: globalMessage.secondaryAction"
            @confirmPrimary="actionConfirmed({message: globalMessage, isPrimary: true})"
            @confirmSecondary="actionConfirmed({message: globalMessage, isPrimary: false})" />
</div>
