

<div class="mx-9 mt-6 pb-3">
    <h2 class="mb-3.5">
        {{ $t('appforms.subscriber.notes') }}
    </h2>

    <ValidationObserver ref="observer" slim>
        <form novalidate class="mb-6" @submit.prevent="addNote">
            <ValidationProvider v-slot="{ errors }" name="contactNote" rules="required|max:255" slim>
                <div class="flex flex-col mb-4">
                    <label for="contactNote" class="tw-label mb-2.5">{{ $t('appforms.subscriber.add-note') }}</label>
                    <input id="contactNote" v-model="newNote" type="text" class="tw-input" name="contactNote" :placeholder="$t('appforms.subscriber.note-text')" :class="{'tw-err': errors.length > 0 }">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <button class="btn btn-primary" :disabled="sending || !newNote"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" /> {{ $t('appforms.subscriber.save-note') }}</button>
        </form>
    </ValidationObserver>

    <div v-if="notes" class="mb-6">
        <div v-for="(note, index) in notes" :key="`note-${note.time}`" class="card grey subscriber notes mb-2 flex-col card-left no-hover space-y-2.5">
            <div class="w-full flex justify-between items-center">
                <div class="txt-13 font-semibold">
                    <span class="text-gray-1000">{{ getNumberDate(note.time, false) }}</span>
                    <span class="text-gray-800 ml-1">{{ getTime(note.time) }}</span>
                </div>
                <a class="text-gray-900 hover:text-gray-1000" href="#" @click.prevent="deleteNote(index)">
                    <icon icon="trash-solid" />
                </a>
            </div>
            <p class="text-gray-1000">
                {{ note.note }}
            </p>
        </div>
    </div>
</div>

