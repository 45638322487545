
<transition name="long-fadeout">
    <div class="ec-container md:spa-container relative main-container flex flex-wrap mx-auto flex-col w-full md:flex-row">
        <div class="mc-r w-full h-full">
            <div class="main-panel solo spa" :class="{scrolled: scrolled}">
                    
                <h2 class="flex justify-between items-center px-9 py-3 text-gray-1000 txt-22">
                    {{ $t('automations.title') }}
                    <button class="btn btn-primary btn-lg icon-left" @click="showCreateAutomation()"><icon icon="plus-solid" size="small" /> <span>{{ $t('automations.index.new_automation_button') }}</span></button>
                </h2>

                <div v-if="!automationsLoaded" ref="content-layout" class="w-4 h-4 mt-6 ml-9 double-loader" />
                <vue-custom-scrollbar v-else ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">

                    <div v-if="automations && Object.keys(automations).length" class="w-full overflow-x-auto lg:overflow-x-visible">
                        <alert v-if="success === true" class="mb-4" type="success" :message="message" />
                        <alert v-else-if="success === false" class="mb-4" type="error" :message="message" />
                        <global-messages :global-messages="globalMessages" />

                        <div class="table tw-table tw-table-large max-w-full">
                            <div class="table-header-group thin">
                                <div class="table-row text-gray-800 txt-13 semibold">
                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'name' }">
                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline" @click.prevent="orderResults('name')">
                                            <span>{{ $t('automations.index.name') }}</span>
                                            <icon icon="sort-solid" />
                                        </a>
                                    </div>
                                    <div class="table-cell text-right" :class="{ 'active': currentOrderBy === 'started' }">
                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('started')">
                                            <span>{{ $t('automations.index.status') }}</span>
                                            <icon icon="sort-solid" />
                                        </a>
                                    </div>
                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'updated_at' }">
                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap pl-4" @click.prevent="orderResults('updated_at')">
                                            <span>{{ $t('automations.index.updated_at') }}</span>
                                            <icon icon="sort-solid" />
                                        </a>
                                    </div>
                                    <div class="table-cell" :class="{ 'active': currentOrderBy === 'created_at' }">
                                        <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('created_at')">
                                            <span>{{ $t('automations.index.created_at') }}</span>
                                            <icon icon="sort-solid" />
                                        </a>
                                    </div>
                                    <div class="table-cell txt-13 semibold whitespace-nowrap">
                                        {{ $t('automations.index.triggered') }}
                                    </div>
                                    <div class="table-cell txt-13 semibold whitespace-nowrap">
                                        {{ $t('automations.index.finished') }}
                                    </div>
                                    <div class="table-cell" />
                                </div>
                            </div>

                            <div v-for="(automation, index) in automations" :key="`automation-${index}`" class="table-row thin">
                                <div class="table-cell w-full text-ellipsis overflow-hidden" style="max-width: 0; min-width: 300px">
                                    <div class="flex flex-col justify-start space-y-1">
                                        <router-link v-if="automation.started" :to="{name: 'automationsReport', params: {automation: automation.id}}" class="txt-14 link-blue semibold truncate" style="max-width: fit-content;">
                                            {{ automation.name }}
                                        </router-link>
                                        <router-link v-else :to="{name: 'automationsSettings', params: {automation: automation.id}}" class="txt-14 link-blue semibold truncate" style="max-width: fit-content;">
                                            {{ automation.name }}
                                        </router-link>
                                        <div class="flex items-center text-gray-800 font-semibold txt-13">
                                            <icon icon="list-regular" size="small" class="text-gray-900 mr-1.5" />
                                            <span class="truncate">{{ Object.values(lists).find(list => list.id === automation.list_id) ? Object.values(lists).find(list => list.id === automation.list_id).name : 'N/A' }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-cell align-middle text-center whitespace-nowrap">
                                    <div v-if="automation.started" key="automation-started" class="flex items-center rounded-full py-0.5 pl-1 pr-2.5 bg-moss-300">
                                        <icon icon="play-circle-solid" size="small" class="text-moss-500 mr-1" />
                                        <span class="text-moss-500 semibold txt-10.5">{{ $t('automations.index.started') }}</span>
                                    </div>
                                    <div v-else class="flex items-center rounded-full py-0.5 pl-1 pr-2.5 bg-messages-warning-200">
                                        <icon key="automation-stopped" icon="pause-circle-solid" size="small" class="text-messages-warning-400 mr-1" />
                                        <span class="text-messages-warning-400 semibold txt-10.5">{{ $t('automations.index.stopped') }}</span>
                                    </div>
                                </div>
                                <div class="table-cell align-middle whitespace-nowrap text-gray-800 txt-13 semibold">
                                    <span class="pl-4">{{ getNumberDate(automation.updated_at, false) }}</span>
                                </div>
                                <div class="table-cell align-middle whitespace-nowrap text-gray-800 txt-13 semibold">
                                    {{ getNumberDate(automation.created_at, false) }}
                                </div>
                                <div class="table-cell align-middle whitespace-nowrap text-gray-1000 txt-13 semibold">
                                    {{ numberToLocale(automation.users_count) }}
                                </div>
                                <div class="table-cell align-middle whitespace-nowrap text-gray-1000 txt-13 semibold">
                                    {{ numberToLocale(automation.finished) }}
                                </div>
                                <div class="table-cell align-middle text-right" style="min-width: 120px">
                                    <div v-dropdown="'open'" class="tw-dropdown">
                                        <div class="btn-group">
                                            <router-link :to="{name: 'automationsReport', params: {automation: automation.id}}" class="btn btn-tertiary whitespace-nowrap">
                                                {{ $t('automations.index.overview') }}
                                            </router-link><button class="btn btn-tertiary icon-only" dropdown-toggle><icon icon="caret-down-solid" /></button>
                                        </div>
                                        <div class="tw-dropdown-items flex-col">
                                            <router-link :to="{name: 'automationsSettings', params: {automation: automation.id}}">
                                                {{ $t('common.edit') }}
                                            </router-link>
                                            <button @click="showDuplicateAutomation(automation)">{{ $t('automations.index.duplicate') }}</button>
                                            <button v-if="automation.started" @click="stopAutomation(automation)">{{ $t('automations.index.stop_button') }}</button>
                                            <button v-else-if="!account.isBlocked" @click="startAutomation(automation)">{{ $t('automations.index.start_button') }}</button>
                                            <button @click="exportAutomation(automation)">{{ $t('automations.index.export_button') }}</button>
                                            <button class="dd-divided dd-critical" @click="showDeleteAutomation(automation)">{{ $t('automations.index.delete_button') }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="px-16 py-8 bg-white mt-3 md:mx-40 lg:mx-64">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-col">
                                <h2 class="mb-2.5">
                                    {{ $t('automations.index.heading') }}
                                </h2>
                                <div>
                                    <p class="txt-14 text-gray-900" v-html="$t('automations.index.description')" />
                                    <button class="btn btn-primary btn-lg icon-left mt-6" @click="showCreateAutomation()"><icon icon="plus-solid" size="small" /><span>{{ $t('automations.index.new_automation_button') }}</span></button>
                                </div>
                            </div>
                            <img src="https://ecomail-assets.s3.amazonaws.com/old/images/empty-pipelines.png" style="width: 305px" alt="feed-ilustration">
                        </div>
                    </div>
                </vue-custom-scrollbar>                    
            </div>
        </div>

        <new-automation ref="createAutomationModal" :marketer="account.hasAllowedMarketerFunctions" :lists="lists" :scenarios="scenarios" @showTailorMade="showTailorMade" @showScenarioPreview="showScenarioPreview" />
        
        <tw-modal ref="scenarioPreview" :title="$t('appforms.pipelines.scenario_preview.title')" width="800">
            <template #default="slotProps">

                <scenario-preview :modal="slotProps" @selectScenario="selectScenario" />

            </template>
        </tw-modal>

        <tw-modal ref="tailorMadeForm" :title="$t('appforms.pipelines.tailor_made_form.title')" width="572">
            <template #default="slotProps">

                <tailor-made-automation-form :modal="slotProps" />

            </template>
        </tw-modal>

        <tw-modal ref="duplicateAutomationModal" :title="$t('appforms.pipelines.duplicate_automation.title')">

            <template #default="slotProps">

                <duplicate-automation-form :modal="slotProps" :lists="lists" @automationDuplicated="automationDuplicated" />

            </template>

        </tw-modal>

        <tw-confirm ref="deleteAutomationModal" @confirmDelete="deleteAutomation" />

    </div>
</transition>
