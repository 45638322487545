
<template>
    <div class="relative flex">
        <!--    To turn off autocomplete when otp-input is password-->
        <input
                v-if="inputType === 'password'"
                autocomplete="off"
                name="hidden"
                type="text"
                style="display: none">
        <SingleOtpInput
                v-for="(item, i) in this.numInputs"
                :key="`input-${i}`"
                :focus="activeInput === i"
                :value="otp[i]"
                :separator="separator"
                :input-type="inputType"
                :input-classes="inputClasses"
                :num-inputs="numInputs"
                :is-third-child="i === 2"
                :is-fourth-child="i === 3"
                :is-last-child="i === numInputs - 1"
                :should-auto-focus="shouldAutoFocus"
                @on-change="handleOnChange"
                @on-paste="handlePaste"
                @on-keydown="handleOnKeyDown"
                @on-focus="handleOnFocus(i)"
                @on-blur="handleOnBlur" />

        <div
                v-if="error"
                data-testid="input-error-message"
                class="absolute text-sm left-2 -bottom-7 text-topol-red-600">
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts">
import SingleOtpInput from './SingleOtpInput.vue'
// keyCode constants
const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46
export default {
    name: 'Vue3OtpInput',
    props: {
        error: {
            type: String,
            default: '',
        },
        numInputs: {
            type: Number,
            default: 6,
        },
        separator: {
            type: String,
            default: '**',
        },
        inputClasses: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            validator: (value) =>
                ['number', 'tel', 'password'].includes(value),
            default: 'tel',
        },
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        SingleOtpInput,
    },

    data() {
        return {
            activeInput: 0,
            otp: [],
            oldOtp: [],
        }
    },

    methods: {
        handleOnFocus(index) {
            this.activeInput = index
        },
        handleOnBlur() {
            this.activeInput = -1
        },
        handlePaste(value) {
            this.otp = value.split('')
            this.$emit('on-change', this.otp.join(''))
        },
        handleOnChange(value) {
            this.changeCodeAtFocus(value)
            this.focusNextInput()
        },
        handleOnKeyDown(event) {
            switch (event.keyCode) {
            case BACKSPACE:
                event.preventDefault()
                this.changeCodeAtFocus('')
                this.focusPrevInput()
                break
            case DELETE:
                event.preventDefault()
                this.changeCodeAtFocus('')
                break
            case LEFT_ARROW:
                event.preventDefault()
                this.focusPrevInput()
                break
            case RIGHT_ARROW:
                event.preventDefault()
                this.focusNextInput()
                break
            default:
                break
            }
        },
        checkFilledAllInputs() {
            if (this.otp.join('').length === this.numInputs) {
                this.$emit('on-complete', this.otp.join(''))
            }
            return 'Wait until the user enters the required number of characters'
        },
        focusInput(input) {
            this.activeInput = input >= this.numInputs || input < 0 ? 0 : input
            console.log(this.activeInput)
        },
        focusNextInput() {
            console.log('focusNext')
            this.focusInput(this.activeInput + 1)
        },
        focusPrevInput() {
            this.focusInput(this.activeInput - 1)
        },
        changeCodeAtFocus(value) {
            this.oldOtp = Object.assign([], this.otp)
            this.otp[this.activeInput] = value
            if (this.oldOtp.join('') !== this.otp.join('')) {
                this.$emit('on-change', this.otp.join(''))
                this.checkFilledAllInputs()
            }
        },
        clearInput() {
            if(this.otp.length > 0)
                this.$emit('on-change', '')
            this.otp = []
            this.activeInput = 0
        },
    },

    // setup(props, { emit }) {
    //     // Handle cases of backspace, delete, left arrow, right arrow
    //     const handleOnKeyDown = (event: KeyboardEvent) => {
    //         switch (event.keyCode) {
    //             case BACKSPACE:
    //                 event.preventDefault();
    //                 changeCodeAtFocus('');
    //                 focusPrevInput();
    //                 break;
    //             case DELETE:
    //                 event.preventDefault();
    //                 changeCodeAtFocus('');
    //                 break;
    //             case LEFT_ARROW:
    //                 event.preventDefault();
    //                 focusPrevInput();
    //                 break;
    //             case RIGHT_ARROW:
    //                 event.preventDefault();
    //                 focusNextInput();
    //                 break;
    //             default:
    //                 break;
    //         }
    //     };
    //     return {
    //         activeInput,
    //         otp,
    //         oldOtp,
    //         clearInput,
    //         handleOnKeyDown,
    //         handleOnBlur,
    //         changeCodeAtFocus,
    //         focusInput,
    //         focusNextInput,
    //         focusPrevInput,
    //         handleOnFocus,
    //         checkFilledAllInputs,
    //         handleOnChange,
    //         numInputsArray,
    //
    //     };
    // },
}
</script>
