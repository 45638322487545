<template>
    <div ref="tabItem" class="flex cursor-pointer items-center justify-center bottom-border text-color" :class="classObj" @click="triggerSelectLocal">
        <icon v-if="icon" ref="icon" class="icon-color mr-1" :icon="icon.name" :size="icon.size || 'medium'" />
        <span class="font-semibold whitespace-nowrap">{{ title }}</span>
        <span v-if="count !== null" ref="count" class="count-pill ml-3">{{ count }}</span>
        <icon v-if="sortable" ref="dragIcon" icon="grip-vertical-solid" size="big" class="ml-1.5 drag cursor-move tab-handle" />
    </div>
</template>

<script>
export default {
    name: 'TabItem',

    props: {
        // triggers select function in TabRow.vue
        triggerSelect: {
            type: Function,
            required: true
        },
        value: {
            type: [String, Number],
            required: true
        },
        title: {
            type: String,
            required: true
        },
        selected: {
            type: [String, Number],
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        count: {
            type: [Number, String],
            default: null
        },
        icon: {
            type: Object
        },
        options: {
            type: Array,
            default() {
                return []
            }
        }
    },

    computed: {
        sortable() {
            return this.options.includes('sortable')
        },
        classObj() {
            return {
                'flex-grow': this.options.includes('stretch'),
                'normal-size': !this.options.includes('small'),
                'small-size': this.options.includes('small'),
                'selected': this.value === this.selected,
                'disabled': this.disabled,
                'sortable': this.sortable
            }
        },
    },

    methods: {
        triggerSelectLocal() {
            if(!this.disabled) {
                this.triggerSelect(this.value)
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables';

    .disabled {
        cursor: not-allowed;
    }

    .sortable {
      &.sortable-chosen {

        &:not(.draggable-action) {
          margin: 0;
        }
        
        .svg-wrapper {
            color: $grey-80 !important;
        }
      }
    }

    .bottom-border {
        box-shadow: inset 0px -1px 0px #CCCCCC;

        &:hover {
            box-shadow: inset 0px -3px 0px #B0B0B0;
        }

        &:active {
            box-shadow: inset 0px -3px 0px #5A9A00;
        }

        &.selected {
            box-shadow: inset 0px -3px 0px #6CB306;
        }

        &.disabled {
            box-shadow: inset 0px -1px 0px #E6E6E6;
        }
    }

    .normal-size {
        font-size: 14px;
        padding: 8px 8px 11px;
    }

    .small-size {
        font-size: 12px;
        padding: 7px 4px 11px;
    }

    .text-color {
        color: $grey-80;

        .icon-color {
            color: $grey-65;
        }

        &:hover {
            color: $grey-90;

            .icon-color {
                color: $grey-70;
            }
        }

        &:active {
            color: $grey-100;

            .icon-color {
                color: $grey-70;
            }
        }

        &.selected {
            color: $grey-100;

            .icon-color {
                color: $green-40;
            }
        }

        &.disabled {
            color: $grey-60;

            .icon-color {
                color: $grey-60;
            }
        }

        .count-pill {
            font-size: 10.5px;
            font-weight: 600;
            line-height: 20px;
            color: $grey-80;
            background-color: $grey-20;
            padding: 2px 10px;
            border-radius: 12px;
        }
    }

</style>