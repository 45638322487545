

<ValidationProvider ref="autocomplete" v-slot="{ errors }" :name="name" :rules="{'max_length': maxLength}" slim>
    <div v-on-clickaway="hideDropdown" :class="{open: opened}" class="ecomail-select">

        <input v-model="localValue" :name="name" type="hidden">

        <div v-if="label" ref="label" class="ecomail-select-label">
            <span>{{ label }}</span>
        </div>
        <a ref="title" :class="['ecomail-select-title', {'disabled': disabled, 'searching': opened, 'no-items': !options.length}]" @click.prevent="titleAction()">
            <div class="mr-2 flex-grow overflow-hidden">
                <input
                        v-if="opened"
                        ref="search-input"
                        v-model="localValue"
                        :placeholder="title"
                        type="text"
                        class="w-full"
                        @click.stop
                        @input="handleInput()"
                        @keyup.enter="hideDropdown()">
                <span v-else-if="localValue" class="selected flex">
                    {{ localValue }}
                </span>
                <span v-else>{{ title }}</span>
            </div>
            <icon icon="caret-down-solid" />
        </a>
        <div v-show="opened && options.length" ref="items" class="ecomail-select-items" :style="{maxHeight: `${optionsDropdownHeight * optionHeight}px`}">
            <vue-custom-scrollbar ref="scroll" tagname="div" class="scroll" :settings="settings">
                <a v-for="(option, index) in options" :key="`${optionKey}-${index}`" v-tooltip.top="{content: option[optionDisplayAttribute], 'trigger': isTruncated(option[optionDisplayAttribute]) ? 'hover' : 'manual', autoHide: false}" class="ecomail-select-item" @click.prevent="handleSelect(option[optionValueAttribute])">
                    <span class="flex">
                        {{ formatDisplayAttribute(option[optionDisplayAttribute]) }}
                    </span>
                </a>
            </vue-custom-scrollbar>
        </div>
        <div v-if="!opened && errors[0]" class="tw-errors">
            {{ errors[0] }}
        </div>
    </div>
</ValidationProvider>
