
<div>
    <portal v-if="activated" to="automations-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex text-gray-900 items-center">
                <span class="txt-18 regular pr-2.5 br overflow-hidden truncate" style="max-width: 260px">{{ automation.name }}</span>
                <span v-if="mode !== 'chatbot'" class="px-2.5 flex items-center font-semibold txt-12 br"><icon icon="play-circle-solid" size="small" class="mr-1 text-gray-800" /> {{ $t('automations.type.' + typeText) }}</span>
                <template v-if="mode !== 'chatbot'">
                    <div v-if="!editLoaded" class="ml-2 double-loader" />
                    <span v-else class="px-2.5 flex items-center font-semibold flex txt-12"><icon icon="list-regular" size="small" class="mr-1 text-gray-800" /> {{ list ? list.name : 'N/A' }}</span>
                </template> 
            </div>

            <div class="flex items-center">
                <template v-if="mode === 'chatbot'">
                    <div v-if="!account.isBlocked" class="mr-5">
                        <ecm-switch :class="{'cursor-not-allowed': sending}" :value="automation.started" :disabled="switching || !automationValid" @input="toggleAutomation">
                            <span class="font-semibold" :class="{'text-green-600': automation.started}">{{ automation.started ? $t('automations.testing.automation_started') : $t('automations.testing.automation_paused') }}</span>
                        </ecm-switch>
                    </div>
                    <div v-tooltip.bottom="{'content': automation.started ? $t('automations.pause-to-edit') : ''}">
                        <button type="button" :disabled="automation.started" class="btn btn-primary" @click.stop="gotoChatbotEditing">{{ $t('chat.bot.edit-chabot') }}</button>
                    </div>
                </template>
                <template v-else>
                    <div v-if="!account.isBlocked" class="mr-5">
                        <ecm-switch :class="{'cursor-not-allowed': sending}" :value="automation.started" :disabled="switching || !automationValid" @input="toggleAutomation">
                            <span class="font-semibold" :class="{'text-green-600': automation.started}">{{ automation.started ? $t('automations.testing.automation_started') : $t('automations.testing.automation_paused') }}</span>
                        </ecm-switch>
                    </div>
                    <div v-if="automation.started" v-tooltip.bottom="$t('automations.pause-to-edit')">
                        <button disabled class="btn btn-primary icon-left ">
                            <icon icon="pen-solid" size="small" />
                            {{ $t('automations.edit-automation') }}
                        </button>
                    </div>
                    <a v-else :href="`/automation/${automation.id}/edit`" class="btn btn-primary icon-left">
                        <icon icon="pen-solid" size="small" />
                        {{ $t('automations.edit-automation') }}
                    </a>
                </template>
            </div>
        </div>
    </portal>

    <alert v-if="switchSuccess === true" class="mb-2" type="success" :message="switchMessage" />
    <alert v-if="switchSuccess === false" class="mb-2" type="error" :message="switchMessage" />

    <div>
        <div v-if="!editLoaded" class="w-4 h-4 mt-4 double-loader" />

        <template v-else>
            <automation-builder
                    ref="automation-builder"
                    :marketer="account.hasAllowedMarketerFunctions"
                    :can-create-sms-campaign="account.canSendSms"
                    :editable="false"
                    :list-id="list ? list.id : null"
                    :list-name="list ? list.name : null"
                    :fbpages="pages"
                    :initpipeline="automation"
                    :plumbing="plumbing"
                    :integrations="integrations"
                    :lists-with-custom-fields="listsWithCustomFields"
                    :customfields="list ? list.custom_fields : {}"
                    :strategy="strategy"
                    :is-english-account="account.isEnglishAccount" />
        </template>
    </div>
</div>
