
<div @keyup.enter="verifyCode">

    <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
        {{ $t('auth.2fa.header') }}
    </div>

    <div class="text-auth-base text-auth-gray-500 text-center" v-html="$t('auth.2fa.auth.anotation')" />

    <hr class="mt-5 mb-6">

    <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

    <OtpInput
            ref="otpInput"
            input-classes="mt-2 rounded-md text-center border bg-auth-gray-50 border-auth-gray-300 mx-1 sm:mx-2 focus:ring-2 focus:ring-offset-2 focus:ring-auth-green-500 focus:outline-none transition duration-200 ease-in-out"
            separator=""
            :num-inputs="numberOfInputs"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange" />

    <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="verifyCode">
        <span class="text-auth-base">{{ $t('auth.2fa.verify_key_cta') }}</span>
    </FormButton>

    <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
        <span>{{ $t('auth.2fa.auth.cant_login') }}&nbsp;</span>
        <router-link :to="{name: 'twoFactorAuthRecovery'}" class="cursor-pointer hover:no-underline text-auth-green-500">
            {{ $t('auth.2fa.auth.enter_recovery_code') }}
        </router-link>
    </div>
</div>
