<template>
    <div class="relative flex items-start">
        <div class="flex items-center h-5">
            <input
                    :id="label"
                    v-model="checkValue"
                    type="checkbox"
                    class="w-5 h-5 cursor-pointer appearance-none transition ease-in-out duration-150 text-auth-green-600 border border-auth-gray-300 rounded bg-auth-gray-50
                    focus:border-auth-green-600 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-auth-green-300 focus:ring-offset-auth-gray-50
                    checked:bg-auth-green-600 checked:border-auth-green-600">
        </div>
        <label
                :for="label"
                class="font-semibold ml-3 text-auth-sm leading-5 text-auth-gray-700 cursor-pointer">{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        }
    },

    computed: {
        checkValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
input[type='checkbox'] {
    margin: 0;
    display: grid;
    place-content: center;
    outline: 0 none;
}
input[type='checkbox']::before {
    @apply bg-white;

    content: '';
    width: 12px;
    height: 12px;
    transform: scale(0);
    transform-origin: bottom left;
    mask-image: url('/images/icons/check-solid.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}
input[type='checkbox']:checked::before {
    transform: scale(1);
}

input[type='checkbox']:hover:not(:checked) {
    @apply bg-white;
}

input[type='checkbox']:hover:focus {
    @apply ring-offset-white;
}
</style>
