<template>

    <div class="notification" @mouseenter="clearTimer()" @mouseleave="startTimer()">
        <div class="notification-content">
            <div class="notification-icon" :class="classObj">
                <icon :icon="selectedIcon.name" />
            </div>
            <p class="notification-message">
                {{ $t('notifications.' + message) }}
            </p>
        </div>
        <div class="notification-close" @click.stop.prevent="onClose">
            <icon icon="times-solid" />
        </div>
    </div>

</template>

<script>

export default {
    name: 'Notification',

    props: {
        id: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            validator: (prop) => [
                'info',
                'warning',
                'error',
                'success'
            ].includes(prop),
            required: true
        },
        duration: {
            type: Number,
            default: 4500
        }
    },

    data() {
        return {
            visible: true,
            timer: null,
            selectedIcon: null,
            icons: [
                {
                    type: 'info',
                    name: 'info-circle-solid'
                },
                {
                    type: 'warning',
                    name: 'exclamation-triangle-solid'
                },
                {
                    type: 'error',
                    name: 'times-octagon-solid'
                },
                {
                    type: 'success',
                    name: 'check-circle-solid'
                }
            ]
        }
    },

    computed: {
        classObj() {
            return {
                'info-color': this.selectedIcon.type === 'info',
                'warning-color': this.selectedIcon.type === 'warning',
                'error-color': this.selectedIcon.type === 'error',
                'success-color': this.selectedIcon.type === 'success'
            }
        },
    },

    watch: {},

    created() {
        this.selectedIcon = this.icons.find((x) => x.type === this.type) ?? this.icons[0]
    },

    mounted() {
        if (this.duration > 0) {
            this.timer = setTimeout(() => {
                if (this.type !== 'error') {
                    this.onClose()
                }
            }, this.duration)
        }
    },

    methods: {
        onClose() {
            this.$store.dispatch('removeActiveNotification', this.id)
        },
        clearTimer() {
            clearTimeout(this.timer)
        },
        startTimer() {
            if (this.duration > 0) {
                this.timer = setTimeout(() => {
                    if (this.type !== 'error') {
                        this.onClose()
                    }
                }, this.duration)
            }
        }
    }

}
</script>
