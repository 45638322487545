<template>

    <ValidationProvider ref="autocomplete" v-slot="{ errors }" :name="name" :rules="{'max_length': maxLength}" slim>
        <div v-on-clickaway="hideDropdown" :class="{open: opened}" class="ecomail-select">

            <input v-model="localValue" :name="name" type="hidden">

            <div v-if="label" ref="label" class="ecomail-select-label">
                <span>{{ label }}</span>
            </div>
            <a ref="title" :class="['ecomail-select-title', {'disabled': disabled, 'searching': opened, 'no-items': !options.length}]" @click.prevent="titleAction()">
                <div class="mr-2 flex-grow overflow-hidden">
                    <input
                            v-if="opened"
                            ref="search-input"
                            v-model="localValue"
                            :placeholder="title"
                            type="text"
                            class="w-full"
                            @click.stop
                            @input="handleInput()"
                            @keyup.enter="hideDropdown()">
                    <span v-else-if="localValue" class="selected flex">
                        {{ localValue }}
                    </span>
                    <span v-else>{{ title }}</span>
                </div>
                <icon icon="caret-down-solid" />
            </a>
            <div v-show="opened && options.length" ref="items" class="ecomail-select-items" :style="{maxHeight: `${optionsDropdownHeight * optionHeight}px`}">
                <vue-custom-scrollbar ref="scroll" tagname="div" class="scroll" :settings="settings">
                    <a v-for="(option, index) in options" :key="`${optionKey}-${index}`" v-tooltip.top="{content: option[optionDisplayAttribute], 'trigger': isTruncated(option[optionDisplayAttribute]) ? 'hover' : 'manual', autoHide: false}" class="ecomail-select-item" @click.prevent="handleSelect(option[optionValueAttribute])">
                        <span class="flex">
                            {{ formatDisplayAttribute(option[optionDisplayAttribute]) }}
                        </span>
                    </a>
                </vue-custom-scrollbar>
            </div>
            <div v-if="!opened && errors[0]" class="tw-errors">
                {{ errors[0] }}
            </div>
        </div>
    </ValidationProvider>
</template>

<script>
import { extend } from 'vee-validate'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import { mixin as clickaway } from 'vue-clickaway'

export default {

    name: 'EcmAutocomplete',

    mixins: [clickaway],

    props: {
        label: {
            type: String,
        },
        title: {
            type: String,
            default() {
                return this.$t('forms.select_please')
            }
        },
        optionKey: {
            type: String,
            default: 'select-choice'
        },
        options: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Number, String]
        },
        optionDisplayAttribute: {
            type: String,
            default: 'name'
        },
        optionValueAttribute: {
            type: String,
            default: 'id'
        },
        name: {
            type: String,
            default: 'ecm-select',
        },
        optionsDropdownHeight: {
            type: Number,
            default: 5
        },
        maxOptionLength: {
            type: Number,
            default: 0
        },
        allowMultiple: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: null,
        }
    },

    components: {
        vueCustomScrollbar,
    },

    data () {
        return {
            opened: false,
            localValue: null,
            settings: {
                suppressScrollX: true
            },
            optionHeight: 35,
        }
    },

    computed: {
        selectedOption() {
            for(let index in this.options) {
                let option = this.options[index]
                if(option[this.optionValueAttribute] === this.localValue) {
                    return option
                }
            }

            return null
        },
    },

    watch: {
        value(val) {
            this.localValue = val
        }
    },

    mounted() {
        this.localValue = this.value
    },

    created() {
        extend('max_length', {
            validate: (value, {maxLength}) => {
                for(let valuePart of value.split(',')) {
                    if(maxLength && valuePart.length > maxLength) {
                        return false
                    }
                }
                return true
            },
            params: ['maxLength'],
            message: this.$t('validation.max_length', {length: this.maxLength})
        })
    },

    methods: {
        titleAction() {
            this.toggleDropdown()
        },
        toggleDropdown() {
            if(this.disabled) {
                this.opened = false
                return
            }
            this.opened = !this.opened
            this.$refs['scroll'].$el.scrollTop = 0
            if(this.opened) {
                this.$nextTick(() => {
                    let width = Math.max(this.$refs['items'].offsetWidth, this.$refs['title'].offsetWidth)
                    this.$refs['items'].style.width = `${width}px`
                    this.$refs['title'].style.width = `${width}px`
                    if(this.$refs['search-input']) {
                        this.$refs['search-input'].focus()
                    }
                })
            } else {
                this.$refs['title'].style.width = 'auto'
            }
        },
        hideDropdown() {
            this.opened = false
            this.$refs['scroll'].$el.scrollTop = 0
            this.$refs['title'].style.width = 'auto'
        },
        handleInput() {
            this.$emit('input', this.localValue)
        },
        handleSelect(key) {
            this.hideDropdown()
            if(this.allowMultiple) {
                if(this.localValue === key) {
                    this.localValue += ','
                } else {
                    const commaIndex = this.localValue.lastIndexOf(',')
                    this.localValue = `${this.localValue.substring(0, commaIndex + 1)}${key.trim()},`
                }
            } else {
                this.localValue = key
            }
            this.$emit('input', this.localValue)
        },
        formatDisplayAttribute(optionDisplayAttribute) {
            return this.isTruncated(optionDisplayAttribute) ? optionDisplayAttribute.substr(0, this.maxOptionLength-1) + '...' : optionDisplayAttribute
        },
        isTruncated(option) {
            return option && option.length > this.maxOptionLength && this.maxOptionLength !== 0
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '~sass/variables';

    .ecomail-select {
        position: relative;
        display: flex;
        flex-direction: column;

        .ecomail-select-title {
            overflow: hidden;
            white-space: nowrap;
            border: 1px solid;
            color: $grey-80;
            background-color: $grey-20;
            border-color: $grey-60;
            border-radius: 3px;
            display: flex;
            justify-content: space-between;
            padding: 6px 8px 6px 12px;
            font-size: 14px;
            line-height: 20px;
            align-items: center;

            &.searching {
                padding: 0 7px 0 0;
            }

            input {
                padding: 7px 12px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                background-color: $grey-10;
                border-color: $grey-60;
                color: $grey-100;

                &::placeholder, &::-ms-input-placeholder, &::-webkit-input-placeholder  {
                    color: $grey-90;
                    opacity: 1;
                }

                &:focus {
                    outline: none;
                    -moz-outline-color: transparent;
                }
            }

            &:disabled {
                cursor: not-allowed;
            }

            .selected {
                color: $grey-100;

                .icon-selected {
                    color: $grey-80;
                } 
            }

            &:hover, &:focus {
                background-color: $grey-20;
                border-color: $grey-65;
                cursor: pointer;
                text-decoration: none;
            }

            &:active {
                box-shadow: $inner-shadow;
            }

            &:disabled {
                color: $grey-70;
                background-color: $grey-10;
                border-color: $grey-50;
            }
        }

        &.open {
            .ecomail-select-title {
                background-color: $grey-10;
                border-color: $grey-65;
                font-weight: 400;

                &:not(.no-items) {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-bottom-color: $grey-45;
                }
            }
        }

        .ecomail-select-label {
            font-weight: 600;
            font-size: 14px;
            text-align: left;
            margin-bottom: 10px;
        }

        .ecomail-select-items {
            display: flex;
            flex-direction: column;
            overflow: auto;
            position: absolute;
            top: 100%;
            left: 0;
            border-bottom: 1px solid $grey-65;
            border-left: 1px solid $grey-65;
            border-right: 1px solid $grey-65;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
            background-color: $grey-10;
            min-width: 150px;
            text-align: left;
            z-index: 50;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            list-style-type: none;
            width: auto;

            a.ecomail-select-item {
                display: flex;
                padding: 8px 16px 8px 12px;
                line-height: 19px;
                color: $grey-100;
                font-size: 14px;
                white-space: nowrap;
                text-align: left;
                justify-content: space-between;
                align-items: center;

                .ecomail-select-item-meta {
                    font-size: 13px;
                    color: $grey-80
                }

                &:focus {
                    outline: none;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $grey-40;
                    cursor: pointer;
                }
            }

        }
    }

</style>
