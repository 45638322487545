
<div>
    <portal v-if="activated" to="automations-section-header">
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center space-x-5">
                <router-link :to="{name: 'automationsReport', params: {automation: automation.id}}" class="text-gray-800 txt-18 hover:text-gray-1000 hover:no-underline">
                    {{ automation.name }}
                </router-link>
                <div class="hidden lg:block">
                    <icon class="text-gray-700" icon="long-arrow-alt-right-solid" />
                </div>
                <span class="txt-18 text-gray-1000">{{ $t('automations.activities.title') }}</span>
            </div>
            <router-link :to="{name: 'automationsIndex'}" class="btn btn-tertiary items-center">
                <icon class="mr-2 -ml-1" icon="caret-left-solid" />
                <span class="-mr-1">{{ $t('common.back') }}</span>
            </router-link>
        </div>
    </portal>

    <div>
        <div class="w-full mb-4">
            <tab-row :value="currentActivity" :options="['stretch', 'small']" @input="setRoute">
                <template #tab="slotProps">
                    <tab-item v-for="(activityOption, index) in tabOptions" :key="`tab-choice-${index}`" :value="activityOption" :title="$t(`automations.activities.${activityOption}`)" :count="!activitiesStats ? '?' : numberToLocale(activitiesStats[activityOption])" :selected="currentActivity" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                </template>
            </tab-row>
        </div>

        <div v-if="!activitiesLoaded" class="w-4 h-4 mt-4 double-loader" />

        <div v-else-if="activitiesLoaded && activities.length">
            <div class="flex mb-4 justify-between items-center">
                <p v-if="currentActivity === 'send'" class="txt-14 text-gray-1000">
                    {{ $t('automations.activities.send') }} <span class="text-gray-900">({{ $t('automations.activities.total') }} {{ numberToLocale(activitiesStats['send']) }})</span>
                </p>
                <p v-else-if="currentActivity === 'open' || currentActivity === 'click'" class="txt-14 text-gray-1000">
                    {{ $t(`automations.activities.${currentActivity}`) }} <span class="text-gray-900">({{ numberToLocale(activitiesStats[`total_${currentActivity}`]) }} {{ $t('automations.activities.total') }} / {{ numberToLocale(activitiesStats[currentActivity]) }} {{ $t('automations.activities.unique') }})</span>
                </p>
                <p v-else class="txt-14 text-gray-1000">
                    {{ $t(`automations.activities.${currentActivity}`) }} <span class="text-gray-900">({{ $t('automations.activities.total') }} {{ numberToLocale(activitiesStats[currentActivity]) }})</span>
                </p>
                <form method="get" @submit.prevent="handleSearch">
                    <div class="tw-input-group flex" style="width: 330px; max-width: 100%">
                        <div class="tw-input-with-icon flex-grow">
                            <label for="contactSearch" class="flex-none"><icon size="small" icon="search-solid" /></label>
                            <input id="contactSearch" v-model="query" type="text" :placeholder="$t('automations.activities.search_placeholder')" name="search" class="tw-input w-full">
                        </div>
                        <button type="submit" :disabled="searching" class="btn btn-tertiary"><div v-if="searching" class="double-loader loader-sm loader-grey mr-2" />{{ $t('common.search') }}</button>
                    </div>
                </form>
            </div>

            <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mb-6">
                <span>{{ $t('campaigns.query_results') }}</span>
                <h2 class="ml-2">
                    {{ currentQuery }}
                </h2>
                <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshActivities()"><icon icon="times-solid" />{{ $t('common.cancel') }}</a>
            </div>
            <div v-else-if="currentActionId && currentMailName" class="flex txt-14 text-gray-1000 items-center mb-6">
                <span>{{ $t('automations.activities.selected_email') }}</span>
                <h2 class="ml-2">
                    {{ currentMailName }}
                </h2>
                <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshActivities()"><icon icon="times-solid" />{{ $t('common.cancel') }}</a>
            </div>

            <div class="w-full overflow-x-auto md:overflow-x-visible">
                <div class="table tw-table tw-table-large max-w-full">
                    <div class="table-header-group">
                        <div class="table-row">
                            <div class="table-cell" :class="{ 'active': currentOrderBy === 'email' }">
                                <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('email')">
                                    <span>{{ $t('automations.activities.email') }}</span>
                                    <icon icon="sort-solid" />
                                </a>
                            </div>

                            <div class="table-cell" :class="{ 'active': currentOrderBy === 'action_id' }">
                                <span class="inline-flex text-gray-800 txt-13 semibold whitespace-nowrap">
                                    {{ $t('automations.activities.email_name') }}
                                </span>
                            </div>

                            <div v-if="currentActivity === 'unsub'" class="table-cell">
                                <span class="inline-flex text-gray-800 txt-13 semibold whitespace-nowrap">
                                    {{ $t('automations.activities.reason') }}
                                </span>
                            </div>
                            <div v-else-if="currentActivity === 'bounce'" class="table-cell">
                                <span class="inline-flex text-gray-800 txt-13 semibold whitespace-nowrap">
                                    {{ $t('automations.activities.reason') }}
                                </span>
                            </div>
                            <div v-else-if="currentActivity === 'click'" class="table-cell">
                                <span class="inline-flex text-gray-800 txt-13 semibold whitespace-nowrap">
                                    {{ $t('automations.activities.link') }}
                                </span>
                            </div>

                            <div class="table-cell text-right" :class="{ 'active': currentOrderBy === 'id' }">
                                <a href="#" class="inline-flex space-x-1.5 items-center txt-13 semibold hover:no-underline whitespace-nowrap" @click.prevent="orderResults('id')">
                                    <span>{{ $t('automations.activities.date_time') }}</span>
                                    <icon icon="sort-solid" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-for="(user, index) in activities" :key="`user-${index}`" class="table-row text-gray-1000 txt-14">
                        <div class="table-cell w-max">
                            <a class="link-blue semibold" href="#" @click.prevent="showSubscriberModal(user.email)">{{ user.email }}</a>
                        </div>

                        <div class="table-cell w-max">
                            <a v-if="user.mail_name" class="link-blue semibold" href="#" @click.prevent="filterByAction(user.action_id.replace(/[\u0000-\u0008,\u000A-\u001F,\u007F-\u00A0]+/g, ''))">{{ user.mail_name }}</a>
                        </div>

                        <div v-if="currentActivity === 'unsub'" class="table-cell" style="max-width: 300px">
                            <span v-if="user.msg && user.msg.reason" class="tw-lbl" style="max-width: 100%">
                                <span v-tooltip="user.msg.reason" class="overflow-hidden overflow-ellipsis">{{ user.msg.reason }}</span>
                            </span>
                            <span v-else-if="user.feedback">{{ user.feedback }}</span>
                        </div>
                        <div v-else-if="currentActivity === 'bounce'" class="table-cell" style="max-width: 300px">
                            <span class="tw-lbl warning" style="max-width: 100%">
                                <span v-tooltip="`${$t(`automations.activities.${user.event}`)} - ${user.bounce_message}`" class="overflow-hidden overflow-ellipsis">{{ $t(`automations.activities.${user.event}`) }} - {{ user.bounce_message }}</span>
                            </span>
                        </div>
                        <div v-else-if="currentActivity === 'click'" class="table-cell" style="max-width: 300px">
                            <a class="break-words" :href="user.url" target="_blank">{{ user.url }}</a>
                        </div>

                        <div class="table-cell text-right w-0 whitespace-nowrap">
                            <span class="whitespace-nowrap">{{ getNumberDate(user.occured_at) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-between items-center mt-10">
                <simple-pagination :prev-page="paginationActivities.prev_page_url" :next-page="paginationActivities.next_page_url" :current-page="paginationActivities.current_page" @handlePageChange="handlePageChange" />

                <button :disabled="exportSending" class="btn btn-tertiary items-center ml-auto" @click="getExport">
                    <div v-if="exportSending" class="double-loader loader-sm loader-grey mr-2" />
                    <icon v-else class="mr-2 -ml-1" icon="download-solid" />
                    <span class="-mr-1">{{ $t('automations.activities.export') }}</span>
                </button>
            </div>
        </div>


        <div v-else-if="activitiesLoaded">
            <div v-if="currentQuery" class="flex txt-14 text-gray-1000 items-center mb-6">
                <span>{{ $t('campaigns.query_results') }}</span>
                <h2 class="ml-2">
                    {{ currentQuery }}
                </h2>
                <a href="#" class="btn btn-tertiary ml-3 icon-left" @click.prevent="refreshActivities()"><icon icon="times-solid" />{{ $t('common.cancel') }}</a>
            </div>
            <div class="panel px-16 py-8 bg-white mt-3 mb-20">
                <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-col">
                        <h2 class="mb-2">
                            {{ $t('automations.activities.empty_title') }}
                        </h2>
                        <span class="mb-6 text-sm text-gray-900">{{ $t('automations.activities.empty_message') }}</span>
                    </div>
                    <img src="/images/empty-file.png" style="width: 164px" alt="feed-ilustration" class="px-4">
                </div>
            </div>
        </div>

        <tw-modal ref="subscriberDetail" :title="$t('appforms.subscriber.title')" close-button="yes" classname="subscriberDetail" :visible-on-load="$route.query['contact-modal']">
            <template #default="slotProps">

                <subscriber-form :modal="slotProps" @contactDeleted="contactDeleted" />

            </template>
        </tw-modal>
    </div>
</div>
