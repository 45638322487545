

<div :id="id" ref="dropzone" class="upload-cmp" :class="{'mt-4': !title}">
    <div v-if="title" class="font-semibold txt-14 mb-1">
        {{ title }}
    </div>
    <div class="tw-droparea flex flex-col items-center p-10 dropzone-clickable" :class="{ dzfinished: success }">
        
        <div class="w-16 mb-4">
            <svg-vue icon="upload" />
        </div>
        <span class="txt-14 semibold green-60" style="padding-top: 14px">{{ $t('upload.drop-or-click') }}</span>
        <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
        <input :name="inputname" :value="file" type="hidden">
    </div>
</div>

