<template>

    <div class="mx-9 mt-6 mb-9">
        <h2 class="mb-5">
            {{ $t('appforms.subscriber.activities.title') }}
        </h2>

        <div v-if="loadingActivities" class="w-full my-16 flex items-center justify-center">
            <div class="double-loader loader-sm loader-grey" />
        </div>

        <div v-else>
            <div class="flex items-start justify-between txt-10.5 text-gray-900 font-semibold gap-5">
                <div class="flex flex-wrap gap-3">
                    <button
                            v-for="(count, event) in showAllActivities ? events : Object.fromEntries(Object.entries(events).slice(0,5))"
                            :key="`event-${event}`"
                            class="event"
                            :class="{'active-event': event === currentEvent}"
                            @click="loadActivity(event)">
                        <span>{{ $t('appforms.subscriber.activities.' + event) }}</span>
                        <span v-if="event !== 'all'" class="count">{{ count }}</span>
                    </button>
                </div>
                <button class="event more-activities flex items-center whitespace-nowrap" @click="showAllActivities = !showAllActivities">
                    <span>{{ $t('appforms.subscriber.activities.more_activities') }}</span>
                    <icon v-if="showAllActivities" key="events-caret-up" icon="caret-up-solid" size="small" />
                    <icon v-else key="events-caret-down" icon="caret-down-solid" size="small" />
                </button>
            </div>

            <sub-activity class="mt-8" :log="activitiesEvents" :pagination="pagination" :application-currency-code="applicationCurrencyCode" @handlePageChange="handlePageChange" />

        </div>
    </div>

</template>

<script>
import subActivity from './SubActivity.vue'

export default {

    name: 'Activities',

    props: ['email', 'applicationCurrencyCode'],

    components: {
        subActivity
    },

    data () {
        return {
            events: {
                // main
                all: 0,
                send: 0,
                open: 0,
                sms_sent: 0,
                pv: 0,
                custom: 0,

                // other
                purchase: 0,
                click: 0,
                spam: 0,
                unsub: 0,
                hard_bounce: 0,
                soft_bounce: 0,
                sms_inv_nr: 0,
            },
            loadingActivities: true,
            activitiesEvents: null,
            currentEvent: 'all',
            showAllActivities: false,
            pagination: null,
        }
    },

    mounted() {
        this.loadActivity('all')
    },
    
    methods: {
        async loadActivity(event) {
            this.loadingActivities = true

            try {
                const response = await this.$http.get(`/contacts/show/${this.email}/activity`, {
                    params: {
                        events: event === 'all' ? null : [event]
                    }
                })
                for (const [event, count] of Object.entries(response.data.counts)) {
                    this.events[event] = count
                }
                this.activitiesEvents = response.data.events.data
                this.pagination = (({ data, ...i}) => i)(response.data.events)
                this.currentEvent = event
            }
            catch(error) {
                this.success = false
                this.message = error.message
            }

            this.loadingActivities = false
        },
        async handlePageChange(page) {
            this.loadingActivities = true
            try {
                const response = await this.$http.get(`/contacts/show/${this.email}/activity`, {
                    params: {
                        page: page,
                        events: this.currentEvent === 'all' ? null : [this.currentEvent]
                    }
                })  
                for (const [event, count] of Object.entries(response.data.counts)) {
                    this.events[event] = count
                }
                this.activitiesEvents = response.data.events.data
                this.pagination = (({ data, ...i}) => i)(response.data.events)
                this.$emit('scrollTop')

            }
            catch(error) {
                this.success = false
                this.message = error.message
            }

            this.loadingActivities = false
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables';

    .event {
        padding: 2px 7px;
        border: 1px solid $grey-60;
        box-sizing: border-box;
        border-radius: 2px;
        font-weight: 600;

        .count {
            color: $grey-70;
            margin-left: 4px;
        }

        &.active-event {
            background-color: $grey-30;
            color: $grey-95;

            .count {
                color: $grey-90;
                margin-left: 4px;
            }
        }

        &.more-activities {
            color: $green-60;
            padding: 0 0 0 7px;
        }
    }
</style>