
<ValidationProvider v-slot="{ errors }" :name="name" :rules="rules" slim>
    <div class="flex flex-col">
        <label v-if="labelText" :for="name" class="label" v-html="labelText"><a v-if="action" :href="action.url" class="float-right text-sail-500 txt-12 font-semibold hover:text-sail-700" target="_blank" @click="confirmAction" v-html="action.text" /></label>
        <div class="input-container" :class="{'error': errors.length > 0, 'disabled': disabled}">
            <icon v-if="icon" class="input-icon" size="small" :icon="icon" />
            <span v-if="prefix" class="prefix">{{ prefix }}</span>
            <input
                    :id="name"
                    v-model="localValue"
                    :name="name"
                    :type="inputType"
                    :placeholder="placeholderText"
                    :disabled="disabled"
                    :readonly="readonly"
                    @input="$emit('input', $event.target.value)"
                    @keyup.enter="button.text ? $emit('buttonAction') : null">
            <span v-if="suffix" class="suffix">{{ suffix }}</span>
            <div v-if="inputType === 'number'" class="flex flex-col">
                <button class="counter up" @click="localValue++"><svg-vue icon="caret-up-solid" /></button>
                <button class="counter down" @click="localValue--"><svg-vue icon="caret-down-solid" /></button>
            </div>
            <button v-if="button.text" class="input-button" :disabled="button.sending" @click="$emit('buttonAction')"><div v-if="button.sending" class="double-loader loader-sm loader-grey mr-2" />{{ button.text }}</button>
        </div>
        <div v-if="errors[0]" class="flex items-center space-x-1 mt-1">
            <icon icon="exclamation-circle-solid" class="text-messages-error-300" />
            <span class="text-messages-error-400 txt-12 font-semibold" v-html="errors[0]" />
        </div>
        <span v-if="explanationText" class="text-gray-700 txt-12 font-semibold mt-1" v-html="explanationText" />
    </div>
</ValidationProvider>
