<template>
    <v-date-picker
            v-model="date"
            mode="date"
            :rows="rows"
            :locale="locale"
            :columns="cols"
            :step="1"
            :min-date="minDate"
            :max-date="maxDate"
            data="yyyy-MM-dd"
            :is-range="mode === 'range'">
        <template v-if="mode === 'single'" #default="{ togglePopover }">
            <div class="relative cursor-pointer" @click="togglePopover">
                <input
                        :value="displayDate"
                        class="w-full tw-input"
                        style="cursor: pointer; opacity: 1"
                        :name="name"
                        :placeholder="placeholder"
                        readonly>
                <div class="svg-wrapper large-icon absolute text-gray-900" style="right: 5px; top: 7px">
                    <svg-vue icon="calendar-alt-solid" />
                </div>
            </div>
        </template>
        <template v-else-if="mode === 'range'" #default="{ togglePopover }">
            <div class="relative w-full pl-2 pr-8 border-gray-600 border shadow-inner flex justify-around text-gray-900 text-sm bg-white ecm-datepicker-range cursor-pointer" @click="togglePopover">
                <span>
                    {{ displayDate !== null && displayDate.start ? displayDate.start: $t('common.datepicker.from_date') }}
                </span>
                <span>
                    -
                </span>
                <span>
                    {{ displayDate !== null && displayDate.end ? displayDate.end: $t('common.datepicker.to_date') }}
                </span>
                <div class="svg-wrapper large-icon absolute text-gray-900" style="right: 7px; top: 7px">
                    <svg-vue icon="calendar-alt-solid" />
                </div>
            </div>
        </template>
    </v-date-picker>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
    name: 'DatePicker',

    props: {
        mode: {
            type: String,
            default: 'single'
        },
        rows: {
            type: Number,
            default: 1
        },
        cols: {
            type: Number,
            default: 1
        },
        value: {
            type: [Date, Object],
        },
        name: {
            type: String,
            default: 'date'
        },
        placeholder: {
            type: String,
            default: ''
        },
        onlyPast: {
            type: Boolean
        },
        onlyFuture: {
            type: Boolean
        },
        options: {
            type: Object,
            default() {
                return {
                    weekday: undefined,
                    year: 'numeric',
                    month: 'long',
                    day:'numeric'
                }
            }
        },
        onlyFutureModifier: {
            type: Number,
            default: 0
        },
    },

    components: {
        'v-date-picker': DatePicker
    },

    data() {
        return {
            date: null,
            minDate: null,
            maxDate: null
        }
    },

    computed: {
        locale() {
            return window.vueLocale
        },
        displayDate() {
            const options = this.options
            if(this.value == null){
                return null
            }
            if(this.mode === 'single') {
                return this.value.toLocaleDateString(this.locale, options)
            } else if (this.mode === 'range') {
                let dateRange = {}

                if(this.value != null && this.value.start != null && this.value.start instanceof Date) {
                    dateRange.start = this.value.start.toLocaleDateString(this.locale, options)
                } else {
                    dateRange.start = null
                }

                if(this.value != null && this.value.end != null && this.value.end instanceof Date) {
                    dateRange.end = this.value.end.toLocaleDateString(this.locale, options)
                } else {
                    dateRange.end = null
                }

                return dateRange
            }

            return null
        }
    },

    watch: {
        date() {
            this.$emit('input', this.date)
            this.$emit('formatted', this.formatDate())
        },
        value() {
            this.date = this.value
        }
    },

    mounted() {
        this.date = this.value
        if(this.onlyPast) {
            this.maxDate = new Date()
        } else if(this.onlyFuture) {
            this.minDate = new Date(Date.now() + this.onlyFutureModifier)
        }
    },

    methods: {
        formatDate() {
            try {
                if(this.mode === 'single' && this.date != null) {
                    return `${this.date.toISOString().split('T')[0]}`
                } else if (this.mode === 'range' && this.date != null && this.date.start && this.date.end) {
                    let start = new Date(this.date.start.getTime() - (this.date.start.getTimezoneOffset() * 60000)).toISOString()
                    let end = new Date(this.date.end.getTime() - (this.date.end.getTimezoneOffset() * 60000)).toISOString()

                    if (start === end){
                        end = new Date(end)
                        end.setDate(end.getDate() + 1)
                        end = end.toISOString()
                    }

                    return `${start.replace('T', ' ').slice(0, -1)},${end.replace('T', ' ').slice(0, -1)}`
                }
            } catch (err) {
                if(this.mode === 'single') {
                    let date = new Date()
                    return `${date.toISOString().split('T')[0]}`
                }
            }
        }
    }
}
</script>

<style  scoped>
    .ecm-datepicker-range {
        height: 34px;
        line-height: 34px;
        border-radius: 3px;
    }
</style>
