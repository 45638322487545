<template>

    <div>
        <div v-if="loading" class="w-full h-64 flex items-center justify-center">
            <div class="double-loader loader-sm loader-grey mr-2" />
        </div>

        <div v-else-if="errorMessage" class="p-4">
            <alert type="error" :message="errorMessage" />
        </div>

        <div v-else>

            <div class="flex flex-col md:flex-row bb justify-between bg-gray-100 pb-5 px-9" style="padding-top: 26px;">
                <div class="flex">
                    <div class="icon-portrait mr-8 hidden md:flex flex-shrink-0 items-center justify-center" :style="{backgroundColor: shortLetterColor}">
                        {{ shortLetter }}
                    </div>
                    <div>
                        <h3 v-if="subscriber.name && subscriber.surname" class="text-gray-900 txt-16">
                            {{ subscriber.name }}&nbsp;{{ subscriber.surname }}
                        </h3>
                        <h4 class="text-gray-1000 txt-18 semibold">
                            {{ subscriber.email }}
                        </h4>
                        <div class="flex items-center mt-1">
                            <div class="tag pill-gray pl-5 mr-2">
                                <icon icon="star-solid" size="tiny" class="text-orange-500 -ml-1.5" />
                                <span class="text-gray-800 semibold">{{ subscriber.rating }}</span>
                            </div>
                            <div v-if="list" class="tag mr-4" :class="getStatusClass(listStatus)">
                                <span>{{ $t(`appforms.subscriber.sub_status.${listStatus}`) }}</span>
                            </div>
                            <div class="txt-13 semibold text-gray-700">
                                <span v-if="subscriber && subscriber.inserted_at && subscriber.inserted_at !== '0000-00-00 00:00:00'" v-html="$t('appforms.subscriber.inserted.present', {value: getNumberDate(getIsoDate(subscriber.inserted_at))})" />
                                <span v-else v-html="$t('appforms.subscriber.inserted.not_present')" />
                            </div>
                        </div>
                        <subscriber-tags :email="email" :tags="subscriber.tags" @tagsUpdated="contactUpdated" />
                    </div>
                </div>
                <div v-if="checkRole(user, 'manage-contacts-unsub')" class="flex flex-row md:flex-col mt-5 md:mt-0 space-y-2.5">
                    <button v-if="listStatus === 1" class="btn btn-tertiary justify-center" @click="mode = 'unsub'">{{ $t('appforms.subscriber.unsubscribe') }}</button>
                    <button class="btn btn-secondary btn-danger icon-left justify-center" @click="mode = 'delete'">
                        <icon icon="trash-solid" size="small" />
                        {{ $t('appforms.subscriber.delete') }}
                    </button>
                </div>
            </div>

            <template v-if="mode === null">
                <div class="shadow-under" :class="{'scrolled': scrolled}">
                    <tab-row v-model="active" class="mt-3" :options="['stretch', 'small']">
                        <template #fillBefore>
                            <div key="fill-before" class="flex flex-grow self-end fill" style="height: 1px;" />
                        </template>
                        <template #tab="slotProps">
                            <tab-item v-for="(tab, index) in tabs" :key="`tab-option-${index}`" :value="tab" :title="$t('appforms.subscriber.' + tab)" :selected="active" :count="tab === 'notes' ? notesCount : null" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                        </template>
                        <template #fillAfter>
                            <div key="fill-after" class="flex flex-grow self-end fill" style="height: 1px;" />
                        </template>
                    </tab-row>
                </div>

                <vue-custom-scrollbar ref="content-layout" class="scroll-container-height" :settings="settings" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">
                    <div v-if="active === 'overview'" class="mt-6 mb-3">
                        <div class="px-9">
                            <h5 class="pb-4 font-normal">
                                {{ $t('appforms.subscriber.last-activity') }}
                            </h5>

                            <sub-activity class="pb-6" :log="log" :application-currency-code="applicationCurrencyCode" />
                        </div>

                        <div v-if="!Array.isArray(lists)" class="bg-gray-500" style="height: 1px" />

                        <div v-if="!Array.isArray(lists)" class="px-9 pt-4 pb-6">
                            <h5 class="mb-4 font-normal">
                                {{ $t('appforms.subscriber.lists') }}
                            </h5>

                            <sub-lists :email="email" :lists="lists" :can-unsubscribe="checkRole(user, 'manage-contacts-unsub')" :doi-dates="doiDates" />
                        </div>
                    </div>

                    <props v-else-if="active === 'props'" :subscriber="subscriber" :lists="lists" :custom-fields="customFields" @reloadData="loadData" @updateSubscriberLocally="updateSubscriberLocally" @scrollTop="scrollTop" />

                    <activities v-else-if="active === 'activity'" :email="email" :application-currency-code="applicationCurrencyCode" @scrollTop="scrollTop" />

                    <orders v-else-if="active === 'orders'" :email="email" :application-currency-code="applicationCurrencyCode" />

                    <ml v-else-if="active === 'ml'" :email="email" />

                    <notes v-else-if="active === 'notes'" :email="email" :notes="subscriber.notes" @reloadData="loadData" />
                </vue-custom-scrollbar>
            </template>

            <vue-custom-scrollbar ref="content-delete-layout" class="scroll-delete-height" :settings="settings">
                <div v-if="mode === 'delete'" class="px-9 pt-6 pb-9">
                    <h2 class="mb-5">
                        {{ $t('appforms.subscriber.delete-confirm') }}
                    </h2>

                    <div class="panel p-4 mb-9 space-y-4" :class="{'panel-danger': confirmDelete}">
                        <div class="tw-checkbox">
                            <input id="deleteContact" v-model="confirmDelete" type="checkbox" value="yes">
                            <label for="deleteContact" class="txt-14 text-black">{{ $t('appforms.subscriber.delete-confirm2') }}</label>
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <button class="btn btn-lg btn-tertiary" @click="mode = null">{{ $t('appforms.subscriber.cancel') }}</button>
                        <button class="btn btn-lg btn-danger" :disabled="!confirmDelete" @click="deleteContact">{{ $t('appforms.subscriber.delete-contact') }}</button>
                    </div>
                </div>

                <div v-if="mode === 'unsub'" class="px-9 pt-6 pb-9">
                    <h2 class="mb-5">
                        {{ $t('appforms.subscriber.unsubscribe-confirm') }}
                    </h2>
                    <div class="panel p-4 mb-9 space-y-4">
                        <div v-for="(subscribedList, index) in subscribedLists" :key="`subscribed-list-${index}`" class="tw-checkbox">
                            <input :id="`subscribed-list-${subscribedList.list_id}`" type="checkbox" :name="subscribedList.list_id" :value="subscribedList.list_id" :checked="listsToUnsub.includes(subscribedList.list_id.toString())" @change="onCheck">
                            <label :for="`subscribed-list-${subscribedList.list_id}`" class="txt-14 text-black">{{ subscribedList.owner.name }}</label>
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <button class="btn btn-lg btn-tertiary" @click="mode = null">{{ $t('appforms.subscriber.cancel') }}</button>
                        <button class="btn btn-lg btn-primary" :disabled="!listsToUnsub.length" @click="unsubContact()">{{ $t('appforms.subscriber.unsubscribe-contact') }}</button>
                    </div>
                </div>
            </vue-custom-scrollbar>

        </div>
    </div>

</template>

<script>
import ColorHash from 'color-hash'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import toTemplate from '@mixins/toTemplate'
import permissions from '@mixins/permissions'

import subLists from './SubscriberTabs/SubLists.vue'
import subActivity from './SubscriberTabs/SubActivity.vue'
import activities from './SubscriberTabs/Activities.vue'
import orders from './SubscriberTabs/Orders.vue'
import ml from './SubscriberTabs/Ml.vue'
import notes from './SubscriberTabs/Notes.vue'
import props from './SubscriberTabs/Props.vue'
import subscriberTags from './SubscriberTags.vue'
import TabRow from '@component/Tabs/TabRow.vue'
import TabItem from '@component/Tabs/TabItem.vue'
import store from '@stores/index'
import {mapState} from 'vuex'

export default {

    name: 'SubscriberForm',

    mixins: [toTemplate, permissions],

    store,
    
    props: ['modal'],

    components: {
        vueCustomScrollbar,
        subLists,
        subActivity,
        activities,
        orders,
        ml,
        notes,
        props,
        subscriberTags,
        TabRow,
        TabItem
    },

    data () {
        return {
            settings: {
                suppressScrollX: true,
                tag: 'div',
            },
            scrolled: false,
            loading: true,
            errorMessage: null,
            tabs: ['overview', 'props', 'activity', 'orders', 'ml', 'notes'],
            active: 'overview',
            mode: null,
            subscriber: null,
            lists: null,
            log: null,
            customFields: null,
            doiDates: null,
            confirmDelete: false,
            listsToUnsub: [],
            email: null,
            chat_contact_id: null,
            list: null
        }
    },

    computed: {
        ...mapState(['user', 'applicationCurrencyCode']),
        shortLetter() {
            if(this.subscriber.name) {
                if(this.subscriber.surname) {
                    return this.subscriber.name.charAt(0) + this.subscriber.surname.charAt(0)
                }

                return this.subscriber.name.charAt(0)
            }

            return this.subscriber.email.charAt(0)
        },
        shortLetterColor() {
            var colorHash = new ColorHash()

            return colorHash.hex(this.shortLetter)
        },
        listStatus() {
            return this.list ? Object.values(this.lists).find(obj => obj.list_id == this.list).status : null
        },
        notesCount() {
            return Array.isArray(this.subscriber.notes) ? this.subscriber.notes.length : 0
        },
        subscribedLists() {
            return Object.values(this.lists).filter(list => list.status === 1 || list.status === 3)
        }
    },

    watch: {
        mode() {
            this.listsToUnsub = []
            this.confirmDelete = false
        }
    },

    mounted() { 
        let url = new URL(window.location.href)
        const emailParam = url.searchParams.get('contact-modal')
        const listParam = url.searchParams.get('lid')

        if(emailParam)
            this.email = emailParam
        if(listParam)
            this.list = listParam

        this.email = this.modal.mdata ? this.modal.mdata.email : this.email
        this.chat_contact_id = this.modal.mdata ? this.modal.mdata.chat_contact_id : this.chat_contact_id
        this.list = this.modal.mdata ? this.modal.mdata.list :this.list

        if(!this.email && !this.chat_contact_id)
            this.modal.mclose()

        url.searchParams.set('contact-modal', this.email)
        if(this.list)
            url.searchParams.set('lid', this.list)
        window.history.replaceState({}, document.title, url.toString())

        this.loadData()
    },

    beforeDestroy() {
        let url = new URL(window.location.href)
        url.searchParams.delete('contact-modal')
        url.searchParams.delete('lid')
        window.history.replaceState({}, document.title, url.toString())
    },

    methods: {
        async loadData() {
            if(this.chat_contact_id) {
                let name = this.chat_contact_id.split(' ')
                this.subscriber = {}
                this.subscriber.name = name[0]
                this.subscriber.surname = name[1]
                this.loading = false
                return
            }
            this.loading = true
            try {
                const response = await this.$http.get(`/contacts/show/${this.email}`, {
                    params: {
                        lid: this.list
                    }
                })
                this.subscriber = response.data.subscriber
                this.subscriber.birthday = this.subscriber.birthday && this.subscriber.birthday !== '0000-00-00' ? this.parseISOString(this.subscriber.birthday) : null
                this.subscriber.nameday = this.subscriber.nameday && this.subscriber.nameday !== '0000-00-00' ? this.parseISOString(this.subscriber.nameday) : null
                this.lists = response.data.lists
                this.log = response.data.log.data
                this.customFields = response.data.customFields
                this.doiDates = response.data.doiDates
            }
            catch(error) {
                this.errorMessage = error.message
            }

            this.loading = false
        },
        updateSubscriberLocally(sub) {
            this.subscriber.name = sub.name,
            this.subscriber.surname = sub.surname,
            this.subscriber.pretitle = sub.pretitle,
            this.subscriber.surtitle = sub.surtitle,
            this.subscriber.gender = sub.gender,
            this.subscriber.vokativ = sub.vokativ,
            this.subscriber.vokativ_s = sub.vokativ_s,
            this.subscriber.nameday = sub.nameday,
            this.subscriber.birthday = sub.birthday,
            this.subscriber.street = sub.street,
            this.subscriber.city = sub.city,
            this.subscriber.zip = sub.zip,
            this.subscriber.country = sub.country,
            this.subscriber.company = sub.company,
            this.subscriber.phone = sub.phone
        },
        getStatusClass(status) {
            switch(status) {
            case 1:
                return 'tag-leaf'
            case 2:
                return 'pill-gray'
            case 3:
                return 'tag-warning'
            default:
                return 'tag-danger'
            }
        },
        async deleteContact() {
            if(this.confirmDelete) {
                this.success = null
                this.message = null

                try {
                    await this.$http.delete(`/contacts/delete/${this.email}`, {params: {lid: this.list}})
                    
                    this.modal.mclose()
                    if(this.$listeners && this.$listeners.contactDeleted)
                        this.$emit('contactDeleted', this.email)
                    else {
                        let url = new URL(window.location.href)
                        url.searchParams.delete('contact-modal')
                        url.searchParams.delete('lid')
                        window.location.href =  url.toString()
                    }
                }
                catch(error) {
                    this.success = false
                    this.message = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Error'
                }
            }   
        },
        onCheck(e) {
            if(e.target.checked) {
                this.listsToUnsub.push(e.target.value)
            }
            else {
                let index = this.listsToUnsub.indexOf(e.target.value)
                if (index !== -1) this.listsToUnsub.splice(index, 1)
            }
        },
        async unsubContact() {
            try {
                await this.$http(`/contacts/unsub/${this.email}`, {params: {lists: this.listsToUnsub}})
                this.mode = null
                this.loadData()
            }
            catch(error) {
                this.$message.error(error.message)
            }
        },
        handleScroll() {
            this.scrolled = this.$refs['content-layout'].$el.scrollTop !== 0
        },
        parseISOString(s) {
            let b = s.split(/\D+/)
            if(b[2])
                return new Date(Date.UTC(b[0], --b[1], b[2]))
            return new Date(Date.UTC(new Date().getUTCFullYear(), --b[0], b[1]))
        },
        scrollTop() {
            this.$refs['content-layout'].$el.scrollTop = 0
        },
        contactUpdated() {
            this.$emit('contactUpdated')
        }
    }

}
</script>

<style scoped>
    .scroll-container-height {
        max-height: calc(90vh - 324px);
    }

    .scroll-delete-height {
        max-height: calc(100vh - 270px);
    }

    .shadow-under.scrolled:after {
        z-index: 10;
        background: linear-gradient(180deg, rgba(206, 206, 206, 0.5) 0%, rgba(239, 239, 239, 0) 100%);
        width: 100%;
        height: 10px;
        position: absolute;
        content: "";
    }

    .fill {
        box-shadow: inset 0px -1px 0px #CCCCCC;
    }
</style>
