<template>

    <div :id="id" ref="dropzone" class="upload-cmp" :class="{'mt-4': !title}">
        <div v-if="title" class="font-semibold txt-14 mb-1">
            {{ title }}
        </div>
        <div class="tw-droparea flex flex-col items-center p-10 dropzone-clickable" :class="{ dzfinished: success }">
            
            <div class="w-16 mb-4">
                <svg-vue icon="upload" />
            </div>
            <span class="txt-14 semibold green-60" style="padding-top: 14px">{{ $t('upload.drop-or-click') }}</span>
            <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
            <input :name="inputname" :value="file" type="hidden">
        </div>
    </div>

</template>

<script>

import toTemplate from '@mixins/toTemplate'
import Compressor from 'compressorjs'
import Dropzone from 'dropzone'

export default {

    name: 'UploadNew',

    mixins: [toTemplate],

    props: ['title', 'bucket', 'compressImage', 'accepted', 'id', 'mode', 'name', 'default', 'responsemode', 'btntext', 'deletebtntext', 'uploadbtnstyleobj', 'deletebtnstyle', 'resizeheight', 'resizewidth'],

    components: {},

    data () {
        return {
            file: '',
            oldFile: null,
            uploading: false,
            success: false,
            errorMessage: null
        }
    },

    computed: {
        inputname () {
            return this.name ? this.name : 'file_url'
        },
    },

    created: function () {

    },

    mounted: async function () {

        if(this.default) {
            this.file = this.default
        }

        Dropzone.autoDiscover = false

        let previewTemplate = '<div class="upload-row"><div class="upload-icon"></div><div class="details"><a class="cancel" data-dz-remove></a><div class="uploaded-date">' + this.getNumberDate(this.getCurrentDate(), false) + '</div><div class="progress"><div class="progress-bar" role="progressbar" data-dz-uploadprogress></div></div><strong class="title" data-dz-name>Title</strong><span class="size" data-dz-size>Size</span></div></div>'

        if(this.mode=='singleimage') {
            previewTemplate = '<div class="upload-row"><div class="upload-icon"></div><div class="details single-image"><div class="progress single-image"><div class="progress-bar" role="progressbar" data-dz-uploadprogress></div></div></div></div>'
        }

        if(this.mode=='button') {
            previewTemplate = `<div class="upload-row"><div class="details"><button class="btn btn-default btn-xs" style="${this.deletebtnstyle}" data-dz-remove>${this.deletebtntext || this.btntext}</button><div class="progress"><div class="progress-bar" role="progressbar" data-dz-uploadprogress></div></div><strong class="title" data-dz-name>Title</strong><span class="size" data-dz-size>Size</span></div></div>`
        }

        var dzclass = 'dropzone-clickable'

        this.dropzone = new Dropzone(this.$refs.dropzone, {
            url: '#',
            method: 'post',
            autoQueue: false,
            acceptedFiles: (this.accepted ? this.accepted : null),
            maxFiles: 1,
            clickable: '#' + this.id + ' .' + dzclass,
            previewTemplate: previewTemplate,
            previewsContainer: '#' + this.id + ' .' + dzclass,
            renameFile: (filename) => {
                return new Date().getTime() + '_' + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) + '.' + filename.name.split('.').pop()
            },
            autoProcessQueue: false,
            resizeWidth: this.resizewidth,
            resizeHeight: this.resizeheight
        })

        this.dropzone.on('addedfile', async (file) => {
            this.uploading = true
            if(this.compressImage !== undefined) {
                console.log('compressing')
                file = await this.compress(file)
            }
            this.errorMessage = null
            fetch('/upload/signed?type='+file.type+'&bucket=' + this.bucket, {
                method: 'get'
            }).then((response) => {
                return response.json()
            }).then((json) => {
                this.dropzone.options.url = json.attributes.action
                file.additionalData = json.additionalData
                this.dropzone.processFile(file)
            })
        })

        this.dropzone.on('sending', (file, xhr, formData) => {
            xhr.timeout = 99999999
            // Add the additional form data from the AWS SDK to the HTTP request.
            for (var field in file.additionalData) {
                formData.append(field, file.additionalData[field])
            }
        })


        this.dropzone.on('success', (file) => {
            if(this.responsemode && this.responsemode=='full'){
                this.file = file.xhr.responseURL + file.upload.filename
            } else {
                this.file = file.upload.filename
            }
            setTimeout(() => {
                this.success = true
                this.$emit('uploaded', this.file)
                this.$emit('changed', this.file)
            }, 1000)
        })

        this.dropzone.on('removedfile', (file) => {
            this.oldFile = file
            this.file = ''
            this.uploading = false
            this.success = false
            this.$emit('removed')
            this.$emit('changed', this.file)
        })

        this.dropzone.on('error', (file, errorMessage) => {
            this.oldFile = file
            this.file = ''
            this.uploading = false
            this.errorMessage = errorMessage
        })

        this.dropzone.on('canceled', (file) => {
            this.oldFile = file
            this.file = ''
            this.uploading = false
        })

    },

    updated: function () {},

    methods: {
        removeFile: function () {
            this.file = null
            return true
        },
        async compress(file) {
            let compressedFile = new File([await new Promise((resolve, error) => {
                new Compressor(file, {
                    maxWidth: 600,
                    quality: 0.9,
                    convertSize: 500000,
                    success(result) {
                        resolve(result)
                    }
                })
            })], file.name)
            compressedFile.upload = file.upload
            return compressedFile
        }
    },

}
</script>


<style lang="scss">
    @import '~sass/variables';
    @import '~sass/mixins';
    @import '~sass/components/upload';

</style>
