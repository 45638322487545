<template>
    <!-- <div>
      <div class="input input--complete" :class="{'has-errors': errors.length > 0}">
          <div class="input__item">
          <input
              :value="value"
              @input="onChange"
              :type="type"
              :required="required"
              :class="{ 'not-empty': value }"
          />
          <div class="input__placeholder">
              <p>{{ label }} {{ required ? ' *' : '' }}</p>
              <div class="input__placeholder-auth-helper">{{ label }}{{ required ? ' *' : '' }}</div>
          </div>
          </div>
      </div>
      <div v-if="errors.length > 0">
          <p v-for="(error, i) in errors" :key="i" class="ml-6 text-left text-red-500">{{ error }}</p>
      </div>
    </div> -->

    <div>
        <div>
            <label
                    v-if="label !== ''"
                    class="flex text-auth-sm leading-6 pb-1 font-semibold text-left"
                    :class="{ 'text-auth-gray-700': !hasError, 'text-auth-red-800': hasError }">
                {{ label }}
                <svg
                        v-if="required && showRequireIndicatior"
                        :class="{ 'text-auth-green-500': !hasError, 'text-auth-red-800': hasError }"
                        class="w-3 h-3 mt-1 ml-1"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                    <path
                            d="M6.00003 1.75781V6.00045M6.00003 6.00045L6.00003 10.2431M6.00003 6.00045L10.2427 6.00045M6.00003 6.00045L1.75739 6.00045"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    <path
                            d="M9 3L6 6M6 6L3 9M6 6L9 9M6 6L3 3"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                </svg>
            </label>
            <div class="relative flex items-center rounded-md shadow-auth-sm">
                <input
                        :value="value"
                        :min="min"
                        :max="max"
                        :type="proxiedType"
                        :required="required"
                        :name="name"
                        :placeholder="placeholder"
                        :maxlength="maxlength"
                        :inputmode="inputmode"
                        class="block px-[14px] w-full focus:ring-4 h-[44px] focus:outline-none transition duration-150 ease-in-out border rounded-[4px] border-auth-gray-300 text-auth-sm sm:leading-5"
                        :class="{
                            'text-auth-red-800 bg-red-50 hover:bg-white focus:bg-white  focus-visible:border-auth-red-800 border-auth-red-300 placeholder-auth-red-500 ring-red-500/25 focus:border-red-800': hasError,
                            'ring-auth-green-500/25 hover:bg-auth-white focus:border-auth-green-600 focus:bg-auth-white bg-auth-gray-50 placeholder-auth-gray-500': !hasError,
                            'rounded-r-none z-10': appendEnd !== '',
                        }"
                        @input="onChange">

                <div
                        v-if="type === 'password'"
                        class="absolute right-[16px] top-[10px]">
                    <button
                            class="focus:outline-none"
                            @click.prevent="togglePasswordHidden">
                        <svg
                                v-if="passwordHidden"
                                class="w-6 h-6 text-auth-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <svg
                                v-else
                                class="w-6 h-6 text-auth-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                        </svg>
                    </button>
                </div>

                <span
                        v-if="appendEnd !== ''"
                        :class="{
                            'pr-8': hasIcon,
                            'border-red-300 focus:border-red-300 focus:shadow-outline-red': hasError,
                            'focus:border-green-600': !hasError,
                        }"
                        class="inline-flex items-center h-[44px] px-3 text-auth-gray-500 bg-[#f3f3f3] border border-l-0 border-form-gray rounded-r-md text-auth-sm">
                    {{ appendEnd }}
                </span>
                <div
                        v-if="hasError && appendEnd === ''"
                        class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
                    <svg
                            class="w-5 h-5 text-auth-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20">
                        <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                    </svg>
                </div>
                <div
                        v-if="infoText !== ''"
                        class="absolute inset-y-0 right-0 flex items-center pr-3 mt-1 pointer-events-none">
                    <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM9 4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4ZM7 7C6.44772 7 6 7.44772 6 8C6 8.55229 6.44772 9 7 9V12C7 12.5523 7.44772 13 8 13H9C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11V8C9 7.44772 8.55228 7 8 7H7Z"
                                fill="#A8A8A8" />
                    </svg>
                </div>
            </div>
        </div>

        <div v-if="hasError">
            <p
                    v-for="(error, i) in errors"
                    :key="i"
                    class="text-auth-sm text-left text-auth-red-600">
                {{ error }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text',
        },
        maxlength: {
            type: Number,
            default: null,
        },
        inputmode: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        showRequireIndicatior: {
            type: Boolean,
            default: true,
        },
        errorVuelidate: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        appendEnd: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        infoText: {
            type: String,
            default: '',
        },
        min: {
            type: String,
            default: undefined,
        },
        max: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: 'lg',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hovered: false,
            passwordHidden: true,
        }
    },

    computed: {
        hasError() {
            if (this.errorVuelidate || this.errors.length > 0) {
                return true
            } else return false
        },

        hasIcon() {
            return this.hasError || this.infoText !== '' || this.errorVuelidate
        },

        proxiedType() {
            if (this.passwordHidden === false && this.type === 'password') {
                return 'text'
            }

            return this.type
        },
    },

    mounted() {
        if (this.autofocus) {
            this.$el.querySelector('input').focus()
        }
    },

    methods: {
        onChange(evt) {
            this.$emit('input', evt.target.value)
        },

        togglePasswordHidden() {
            this.passwordHidden = !this.passwordHidden
        },
    },
}
</script>

<style></style>
