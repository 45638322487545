
<div class="mc-r w-full">
    <div class="main-panel solo">
        <vue-custom-scrollbar ref="content-layout" :settings="settings" class="spa-container" @ps-y-reach-start="scrolled = false">
            <template v-if="hmacFail">
                <div class="flex items-end overview">
                    <div class="flex flex-col w-full">
                        <h2 class="font-semibold mb-2.5">
                            {{ $t('integrations.shopify_connected.error_title') }}
                        </h2>
                        <div class="mb-7 mt-5 alert alert-danger">
                            {{ $t('integrations.shopify_connected.hmac_fail') }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex items-end overview">
                    <div class="flex flex-col">
                        <h2 class="font-semibold mb-2.5">
                            {{ $t('integrations.shopify_connected.title') }}
                        </h2>
                        <p class="txt-14 text-gray-900 font-semibold mb-7">
                            {{ $t('integrations.shopify_connected.subtitle') }}
                        </p>
                        <p class="txt-14 text-gray-900 mt-0.5 mb-4" v-html="$t('integrations.shopify_connected.description')" />
                    </div>
                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/shopify-connected.png" alt="Shopify integration illustration">
                </div>

                <div class="grid grid-cols-3 options">
                    <div class="flex flex-col">
                        <div class="option-line" />
                        <p class="txt-14 text-gray-900" v-html="$t('integrations.shopify_connected.option_1')" />
                    </div>
                    <div class="flex flex-col">
                        <div class="option-line" />
                        <p class="txt-14 text-gray-900" v-html="$t('integrations.shopify_connected.option_2')" />
                    </div>
                    <div class="flex flex-col">
                        <div class="option-line" />
                        <p class="txt-14 text-gray-900" v-html="$t('integrations.shopify_connected.option_3')" />
                    </div>
                </div>
                <div>
                    <a href="/" class="btn btn-primary btn-lg">{{ $t('integrations.shopify_connected.button') }}</a>
                </div>
            </template>
        </vue-custom-scrollbar>
    </div>
</div>
