
<div class="mb-5" @keyup.enter="signIn">
    <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
        {{ $t('auth.join.create_account') }}
    </div>

    <div class="text-auth-base text-auth-gray-500 text-center">
        {{ $t('auth.join.your_email') }}<br><span class="semibold text-auth-gray-900">{{ email }}</span>
    </div>

    <hr class="mt-5 mb-6">

    <FormInput v-model="password" :errors="passwordErrors" :autofocus="true" class="w-full mt-5" type="password" :label="$t('auth.login.password.label')" required :placeholder="$t('auth.join.choose_password')" />
    <FormCheckbox v-model="constentGiven" class="mt-6" :label="$t('auth.join.consent_label')" />
    <div class="text-auth-xs ml-[32px] mt-1 text-auth-gray-700" v-html="$t('auth.join.consent_description')" />

    <FormButton type="button" :loading="loading" :disabled="!constentGiven" xlarge primary square class="mt-8 w-full" @click="signIn">
        <span class="text-auth-base">{{ $t('auth.login.sign_in_cta') }}</span>
    </FormButton>

</div>
