<template>    
    <ValidationObserver ref="observer" slim>
        <form novalidate method="post" class="flex flex-col mb-0" @submit.prevent="updateAutomation">

            <alert v-if="success === false" class="mb-5" type="success" :message="message.error" />

            <h2>
                {{ $t('appforms.pipelines.edit_automation.heading') }}
            </h2>

            <div class="txt-14 text-gray-900 mt-0.5 mb-9">
                {{ $t('appforms.pipelines.edit_automation.description') }}
            </div>

            <ValidationProvider v-slot="{ errors }" name="pipelineName" rules="required|max:255" slim>
                <div class="input flex flex-col mb-8">
                    <label for="pipelineName" class="tw-label mb-2.5">{{ $t('appforms.pipelines.edit_automation.name_label') }}</label>
                    <input id="pipelineName" v-model="name" name="pipelineName" type="text" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.pipelines.edit_automation.name_placeholder')">
                    <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="txt-14 uppercase mb-5 font-semibold">
                {{ $t('appforms.pipelines.edit_automation.parameters_label') }}
            </div>

            <ecm-select v-model="multicast" :options="frequencyOptions" :label="$t('appforms.pipelines.edit_automation.frequency_label')" />

            <div class="tw-modal-buttons">
                <button type="button" class="btn btn-tertiary btn-lg" @click.stop.prevent="closeModal()">{{ $t('common.close') }}</button>
                <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.edit_automation.confirm_button') }}</button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import {ValidationObserver} from 'vee-validate'

export default {

    name: 'EditAutomation',

    props: ['modal'],

    components: {
        ValidationObserver
    },

    data () {
        return {
            sending: false,
            success: null,
            message: null,
            id: null,
            name: null,
            multicast: '0',
            frequencyOptions: [
                {
                    name: this.$t('appforms.pipelines.edit_automation.frequency_single'),
                    id: '0'
                },
                {
                    name: this.$t('appforms.pipelines.edit_automation.frequency_multiple'),
                    id: '1'
                },
                {
                    name: this.$t('appforms.pipelines.edit_automation.frequency_parallel'),
                    id: '2'
                }
            ]
        }
    },

    created() {
        if(this.modal.mdata) {
            this.id = this.modal.mdata.id
            this.name = this.modal.mdata.name
            this.multicast = this.modal.mdata.type
        }
    },

    methods: {
        closeModal() {
            this.modal.mclose()
        },

        async updateAutomation() {
            const isValid = await this.$refs.observer.validate()
            if(isValid && !this.sending) {
                this.sending = true
                try {
                    await this.$http.post(`/automation/update/${this.id}`, {
                        name: this.name,
                        type: this.multicast
                    })

                    this.$emit('automationEdited', {name: this.name, frequency: this.multicast})
                    this.closeModal()
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        }
    }
}
</script>
