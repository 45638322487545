<template>
    <div class="flex">
        <portal to="overlay-title">
            <div class="flex items-center text-gray-1000" style="font-size: 18px">
                <a class="cursor-pointer hover:text-gray-1000" :class="headerClassObj" @click="goToMainStep">{{ $t('templates.new_template') }}</a>
                <template v-if="isSubstepActive">
                    <div class="svg-wrapper mx-2 text-gray-700">
                        <svg-vue icon="long-arrow-alt-right-solid" />
                    </div>
                    <span>{{ previewTemplateIndex != null ? $t('templates.template_preview'): $t('templates.tailor-made_templates') }}</span>
                </template>
            </div>
        </portal>
        <div class="mc-l mr-3 xl:mr-9" style="width: 236px; min-width: 236px">
            <ul style="margin: 0" class="w-full">
                <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                <li v-for="(category, i) in categories" v-if="!category.hide" :key="`category-${i}`" class="cursor-pointer" @click="activeCategory = category.filter, previewTemplateIndex = null">
                    <a :class="{active: category.filter === activeCategory}" style="display:flex" class="justify-between items-center">
                        <div>{{ $t(`templates.filter.${category.filter}`) }}</div>
                        <div v-if="category.seasonal" class="tag tag-sail">{{ $t('templates.filter.seasonal') }}</div>
                    </a>
                </li>
                <li class="cursor-pointer" @click="showTailorMadeTemplates('Left menu')">
                    <a :class="{active: activeCategory === 'tailor_made_templates'}" style="display:flex" class="justify-between items-center">
                        <div>{{ $t('templates.tailor-made_templates') }}</div>
                        <div class="svg-wrapper text-green-400"><svg-vue icon="star-solid" /></div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="w-full ml-1 xl:ml-4">
            <div v-if="previewTemplateIndex != null" class="px-4 flex w-full border border-gray-500 justify-center bg-gray-100 rounded py-9 px-18">
                <img :src="templates[previewTemplateIndex].screenshot" style="max-width: 600px; min-width: 520px; object-fit: cover;" class="shadow-2xl" alt="">
                <div class="flex flex-col items-start ml-2 xl:ml-9" style="max-width: 290px">
                    <div class="text-gray-900">
                        {{ $t('templates.choose_template_or_own') }}
                    </div>
                    <div class="flex flex-col xxl:flex-row items-center justify-between mt-5 w-full">
                        <button class="btn btn-tertiary btn-lg" @click="previewTemplateIndex = null">{{ $t('templates.close_preview') }}</button>
                        <button v-tooltip="{'content': $t('templates.new_editor_only')}" class="btn btn-primary btn-lg mt-4 xxl:mt-0" @click="createTemplate(templates[previewTemplateIndex].id)">{{ $t('templates.use_template') }}</button>
                    </div>
                </div>
            </div>
            <div v-else-if="activeCategory === 'tailor_made_templates'" class="panel bg-white p-10 flex justify-between">
                <div v-if="sent === false" class="flex-grow pr-18">
                    <div class="text-lg">
                        {{ $t('templates.tailor-made_templates') }}
                    </div>
                    <div class="mt-2 text-sm text-gray-900 pr-10">
                        {{ $t('templates.tailor-made_templates_paragraph_1') }}
                    </div>
                    <div class="text-gray-900 text-sm mt-2 pr-10">
                        {{ $t('templates.tailor-made_templates_paragraph_2') }}
                    </div>
                    <alert v-if="error" class="mt-4" type="error" :message="error" />
                    <div class="mt-6 tw-label text-sm">
                        {{ $t('templates.tailor-made_templates_type_label') }}
                    </div>

                    <div class="mt-4">
                        <div v-for="(type, index) in serviceTypes" :key="`service-type_${index}`" style="max-width: 400px" class="p-4 cursor-pointer panel has-green-hover border border-gray-500 rounded mb-2" :class="{'border-green-400': type == chosenServiceType, 'shadow-md': type == chosenServiceType, 'bg-white': type == chosenServiceType }" @click="chosenServiceType = type">
                            <div class="tw-radio">
                                <label :for="`template_type-${index}`">
                                    <input :id="`template_type-${index}`" v-model="chosenServiceType" type="radio" name="type" :value="type">
                                    <span class="radiomark mr-3" /> <span>{{ $t(`templates.service_types.${type}.text`) }} </span>
                                </label>
                            </div>

                            <div v-if="type == chosenServiceType && $te(`templates.service_types.${type}.hint`)" class="pt-1 ml-8 text-gray-800" style="font-size: 13px" v-html="$t(`templates.service_types.${type}.hint`)" />
                        </div>
                    </div>

                    <a v-if="!showNote" class="my-6 flex items-center semibold text-sm cursor-pointer" @click="showNote = true">
                        <div class="svg-wrapper small-icon mr-2">
                            <svg-vue icon="plus-circle-solid" />
                        </div>
                        {{ $t('templates.add_note') }}
                    </a>
                    <div v-else class="my-6">
                        <div class="mb-4 tw-label text-sm">
                            {{ $t('templates.note_label') }}
                        </div>
                        <textarea id="importNotes" v-model="note" name="note" rows="3" class="tw-input w-full" :placeholder="$t('templates.note_placeholder')" />
                    </div>

                    <div class="mt-6">
                        <button class="btn btn-primary btn-lg" :disabled="sending" @click="requestTailorMadeTemplate"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('templates.tailor-made_cta') }}</button>
                    </div>
                </div>
                <div v-else>
                    <div class="text-lg">
                        {{ $t('common.thanks_for_interest') }}
                    </div>
                    <div class="mt-2 text-sm text-gray-900">
                        {{ $t('templates.thanks_paragraph') }}
                    </div>
                </div>
                <img src="https://ecomail-assets.s3.amazonaws.com/old/images/tailor-made-template-big.png" style="height: 179px" class="hidden xl:block xl:ml-10 xxl:ml-20" alt="">
            </div>
            <template v-else>
                <div class="flex mb-6">
                    <a class="panel mr-3 w-1/2 has-green-hover p-4 flex items-center cursor-pointer" @click="createTemplate(0, true)">
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/blank_template.png" style="height: 80px" alt="">
                        <div class="flex flex-col ml-2 text-sm">
                            <a class="link-text link-blue">
                                {{ $t('templates.own_design') }}
                            </a>
                            <div class="text-gray-900 mt-1">
                                {{ $t('templates.own_design_hint') }}
                            </div>
                        </div>
                    </a>
                    <a class="panel ml-3 w-1/2 has-green-hover p-4 flex items-center cursor-pointer" @click="showTailorMadeTemplates('top right panel')">
                        <img src="https://ecomail-assets.s3.amazonaws.com/old/images/tailor-made-template.png" style="height: 80px" alt="">
                        <div class="flex flex-col ml-2 text-sm">
                            <a class="link-text link-blue">
                                {{ $t('templates.tailor-made_templates') }}
                            </a>
                            <div class="text-gray-900 mt-1">
                                {{ $t('templates.tailor-made_templates_hint') }}
                            </div>
                        </div>
                    </a>
                </div>
                <div class="flex flex-wrap -mx-3 mb-3">
                    <div v-for="(template, index) in templates" v-show="shouldShowTemplate(template)" :key="`template_${index}`" class="px-3 pb-6 w-1/2 xxl:w-1/3" data-cy="template-mjml-preview">
                        <div class="w-full bg-cover border border-gray-500 rounded hover:border-green-200 relative cursor-pointer" :style="{ backgroundImage: `url(${template.categories ? template.screenshot: template.screen})`, height: '270px' }" @click.self="showPreview(index)" @mouseover="hoverIndex = index" @mouseleave="hoverIndex=null">
                            <div v-if="template.tags.is_new" class="absolute px-4 py-1 bg-red-700 rounded-l border-2 border-white bold txt-13 text-white border-r-0 uppercase" style="top: 15px; right: -4px;">
                                {{ $t('templates.new') }}
                            </div>
                            <div v-if="template.locale === 'pl'" class="absolute px-4 py-1 bg-yellow-500 rounded-l border-2 border-white bold txt-13 text-white border-r-0 uppercase" :style="{top: template.tags.is_new ? '50px': '15px', right: '-4px'}">
                                PO POLSKU
                            </div>
                            <transition name="slideout">
                                <div v-show="hoverIndex === index" class="absolute border-t w-full bg-white flex-col bottom-0 rounded-b cursor-normal px-15 py-5" @click.prevent="">
                                    <button class="btn btn-fluid btn-tertiary mb-4" @click.prevent="showPreview(index)">{{ $t('templates.template_preview') }}</button>
                                    <button class="btn btn-fluid btn-primary" data-cy="template-mjml-preview-create-button" @click.prevent="createTemplate(template.id)">{{ $t('templates.use_template') }}</button>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script>

import store from './store'
import Tracker from '../../tracker'
import seasonal from '@mixins/seasonal'

export default {

    name: 'ChooseMjml',

    mixins: [seasonal],

    props: ['templates', 'isInCampaign', 'hostname'],

    tracker: null,

    data() {
        return {
            categories: [
                {
                    filter: 'featured',
                },
                ...this.getSeasonalTemplateCategories({date: new Date()}),
                {
                    filter: 'ecommerce',
                },
                {
                    filter: 'newsletters',
                },
                {
                    filter: 'discounts',
                },
                {
                    filter: 'welcome',
                },
                {
                    filter: 'anniversaries',
                },
                {
                    filter: 'wishes',
                },
                {
                    filter: 'services',
                },
                {
                    filter: 'infommerce',
                },
                {
                    filter: 'invitations',
                },
                {
                    filter: 'dark-mode',
                },
                {
                    filter: 'all',
                },
            ],
            activeCategory: 'featured',
            hoverIndex: null,
            blankTemplateIndex: null,
            previewTemplateIndex: null,
            serviceTypes: ['whole_template', 'import_template', 'edit_template'],
            chosenServiceType: null,
            note: null,
            sending: false,
            sent: false,
            showNote: false,
            error: null
        }
    },

    store,

    computed: {
        name(){
            return this.$store.getters.newTemplateName
        },
        headerClassObj() {
            return {
                'text-gray-800': this.isSubstepActive,
                'text-gray-1000': !this.isSubstepActive
            }
        },
        isSubstepActive() {
            return this.previewTemplateIndex != null || this.activeCategory === 'tailor_made_templates'
        }
    },

    mounted() {
        this.$options.tracker = new Tracker()
    },

    methods: {
        async createTemplate(templateId, blank = false) {
            if(this.isInCampaign !== true){
                try {
                    const response = await this.$http.post('/templates/mjml', {
                        templateId,
                        name: this.name,
                        blank: blank,
                        folder: typeof this.$route.query.folder !== 'undefined' ? this.$route.query.folder : false,
                    })
                    window.location.href = `/templates/${response.data.template_id}/${response.data.editor_type}`
                }
                catch(error) {
                    console.log(error)
                }
            } else {
                if(blank === true) {
                    this.$emit('templateSelected', 'blank')
                    new Tracker().trackEvent('BUTTON_CLICKED', {
                        scope: 'templates',
                        name: 'Create custom template',
                        template_type: 'blank'
                    })
                } else {
                    this.$emit('templateSelected', templateId)
                    new Tracker().trackEvent('BUTTON_CLICKED', {
                        scope: 'templates',
                        templateId,
                        name: 'Use template',
                    })
                }
            }
        },
        showPreview(templateId) {
            this.previewTemplateIndex = templateId
            new Tracker().trackEvent('BUTTON_CLICKED', {
                scope: 'templates',
                name: 'Show template preview',
                templateId: templateId
            })
        },
        goToMainStep() {
            this.previewTemplateIndex = null
            if(this.activeCategory === 'tailor_made_templates') {
                this.activeCategory = 'featured'
            }
        },
        async requestTailorMadeTemplate() {

            this.$options.tracker.trackEvent('Requested tailor-made template', {
                service_type: this.chosenServiceType ? this.$t(`templates.service_types.${this.chosenServiceType}.text`) : 'Nezadal/ a',
                note: this.note ? this.note : 'Žádná'
            })

            this.sending = true
            try {
                await this.$http.post('/templates/request-tailor-made-template', {
                    service_type: this.chosenServiceType ? this.$t(`templates.service_types.${this.chosenServiceType}.text`) : 'Nezadal/ a',
                    note: this.note ? this.note : 'Žádná'
                })

                this.sending = false
                this.sent = true
            } catch(error) {
                this.sending = false
                this.error = 'Neočekávaná chyba. Prosím zkuste znovu, nebo napište na podporu'
            }

        },
        showTailorMadeTemplates(clickedOn) {
            this.activeCategory = 'tailor_made_templates'
            this.previewTemplateIndex = null
            this.$options.tracker.trackEvent('Displayed tailor-made templates form', {
                'clicked_on': clickedOn
            })
        },
        shouldShowTemplate(template) {
            if(template.hidden === true || template.hidden === 1) {
                return false
            }

            switch (this.hostname) {
            case 'ecomailapp.com':
                if (template.locale !== 'en') {
                    return false
                }
                break
            case 'ecomailapp.pl':
                if (template.locale !== 'en' && template.locale !== 'pl') {
                    return false
                }
                break
            default:
            case 'ecomailapp.cz':
                if (template.locale !== 'cs') {
                    return false
                }
                break
            }

            let isHoliday = template.categories && (template.categories.easter === 1 || template.categories.valentine === 1 || template.categories.halloween === 1 || template.categories.christmas === 1 || template.categories['new-year'] === 1)
            let isActive = template.categories && template.categories[this.activeCategory] === 1
            return (isHoliday && this.activeCategory === 'anniversaries') || this.activeCategory === 'all' || isActive
        }
    }
}
</script>
