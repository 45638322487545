<template>
    <div v-if="total > perPage">
        <ul class="ecm-pagination">
            <li v-if="currentPage !== 1" class="page-item">
                <button class="arrow-button left-arrow" @click.prevent="changePage(currentPage - 1)"><icon key="left" icon="angle-left-solid" /></button>
            </li>
            

            <!-- If there is maximum of 6 pages in total, show them all -->
            <li v-if="lastPage < 7" class="page-items-container">
                <div v-for="page in lastPage" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                    <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </div>
            </li>

            <!-- If there is more than 6 pages, show only nearest 4 pages + first page / last page + ... after first page / ... before last page -->
            <li v-if="lastPage > 6 && currentPage > 3" class="page-item first-page">
                <a href="#" @click.prevent="changePage(1)">1</a>
            </li>
            
            <li v-if="lastPage > 6 && currentPage > 4" class="page-item">
                <span>...</span>
            </li>

            <li v-if="lastPage > 6" class="page-items-container">
                <div v-for="page in visiblePages" :key="`page-${page}`" class="page-item" :class="{'active': page === currentPage}">
                    <a href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </div>
            </li>

            <li v-if="lastPage > 6 && lastPage - currentPage > 3" class="page-item">
                <span>...</span>
            </li>

            <li v-if="lastPage > 6 && lastPage - currentPage > 2" class="page-item last-page">
                <a href="#" @click.prevent="changePage(lastPage)">{{ lastPage }}</a>
            </li>
            

            <li v-if="currentPage !== lastPage" class="page-item">
                <button class="arrow-button right-arrow" @click.prevent="changePage(currentPage + 1)"><icon key="right" icon="angle-right-solid" /></button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Pagination',

    props: ['total', 'perPage', 'currentPage', 'lastPage'],

    data() {
        return {
            maxVisiblePages: 5
        }
    },

    computed: {
        startPage() {
            if(this.currentPage > 2) {
                if(this.lastPage - this.currentPage < 2)
                    return Math.max(1, this.currentPage - (4 - (this.lastPage - this.currentPage)))
                return this.currentPage - 2
            }
            return 1
        },
        endPage() {
            return Math.min(this.lastPage, this.startPage + this.maxVisiblePages - 1)
        },
        visiblePages() {
            const visiblePages = []
            for(let i = this.startPage; i <= this.endPage; i++) {
                visiblePages.push(i)
            }
            return visiblePages
        }
    },

    methods: {
        changePage(pageNumber) {
            this.$emit('handlePageChange', pageNumber)
        }
    }
}
</script>

<style lang="scss" scoped>

    @import '~sass/variables';

    ul.ecm-pagination {
        display: flex;
        overflow-x: auto;

        .page-items-container {
            display: flex;
        }

        .page-item {
            
            span, a {
                display: inline-block;
                padding: 6px 13px;
                font-weight: $semibold;
                font-size: 14px;
                color: $grey-90;
            }

            &:hover {
                a {
                    background-color: $grey-10;
                    text-decoration: none;
                }
            }

            &.active {
                span, a {
                    background-color: $grey-10;
                    box-shadow: inset 0px -2px 0px $green-40
                }
            }

            &.disabled {
                span, a {
                    color: $grey-60;
                    pointer-events: none;
                }
            }
        }

        .arrow-button {
            padding: 7px;
            border-radius: 3px;
            background-color: $grey-50;
            color: $grey-90;

            &.left-arrow {
                margin-right: 12px;
            }
            &.right-arrow {
                margin-left: 12px;
            }
        }
    }
</style>