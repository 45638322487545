
<div class="w-full h-screen flex">
    <div class="m-auto flex items-center error-height px-4 md:px-20 xl:px-0">
        <div class="flex flex-col">
            <h1 class="font-semibold mb-5 txt-28">
                {{ $t(`error_pages.${errorType}.heading`) }}
            </h1>
            <i18n :path="`error_pages.${errorType}.description`" tag="p" class="txt-15 text-gray-900 mb-5">
                <template #linebreak>
                    <br>
                </template>
                <template #action>
                    <a href="#" class="underline" @click.prevent="goToPreviousPage()">{{ $t('error_pages.description_action') }}</a>
                </template>
                <template #link>
                    <a href="/" class="underline">{{ $t('error_pages.description_link') }}</a>
                </template>
            </i18n>
            <p class="txt-15 text-gray-900 mt-0.5 mb-10" v-html="$t('error_pages.support_prompt')" />
            <div class="flex space-x-4">
                <button class="btn btn-lg btn-primary" @click="goToPreviousPage()">{{ $t('error_pages.go_back_button') }}</button>
                <a href="/" class="btn btn-lg btn-tertiary">{{ $t('error_pages.go_to_dashboard_button') }}</a>
            </div>
        </div>
        <img class="hidden lg:block" :src="`/images/errors/${errorType}.png`" alt="error illustration">
    </div>
</div>
