

<div class="notification" @mouseenter="clearTimer()" @mouseleave="startTimer()">
    <div class="notification-content">
        <div class="notification-icon" :class="classObj">
            <icon :icon="selectedIcon.name" />
        </div>
        <p class="notification-message">
            {{ $t('notifications.' + message) }}
        </p>
    </div>
    <div class="notification-close" @click.stop.prevent="onClose">
        <icon icon="times-solid" />
    </div>
</div>

