
<div @keyup.enter="resetPassword">
    <template v-if="emailSent">
        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]" v-html="$t('auth.reset_password.email_sent')" />

        <hr class="mt-5 mb-6">

        <div class="text-auth-base text-center text-auth-gray-700">
            {{ $t('auth.reset_password.instruction_in_mail') }}
            <br><br>
        </div>

        <div class="text-auth-base text-center text-auth-gray-700">
            {{ $t('auth.reset_password.accidental_reset') }}
            <router-link class="semibold hover:no-underline text-auth-green-500 cursor-pointer" :to="{name: 'login'}">
                {{ $t('auth.reset_password.sign_again') }}
            </router-link>
        </div>

    </template>
    <template v-else>
        <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
            {{ $t('auth.reset_password.reset_password') }}
        </div>

        <div class="text-auth-base text-auth-gray-500 text-center">
            {{ $t('auth.login.account_address') }} <span class="semibold text-auth-gray-900">{{ accountUrl }}</span>
        </div>

        <hr class="mt-5 mb-6">

        <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />


        <FormInput id="passwordReset" :errors="emailErrors" name="password" :value="email" class="w-full" type="email" :autofocus="true" :label="$t('auth.login.email.label')" required :placeholder="$t('auth.login.email.placeholder')" @input="emailChanged" />

        <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="resetPassword">
            <span class="text-auth-base">{{ $t('auth.reset_password.reset_password_cta') }}</span>
        </FormButton>

        <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
            <router-link :to="{name: 'login'}" class="cursor-pointer hover:no-underline text-auth-green-500">
                {{ $t('auth.login.sign_in_cta') }}
            </router-link>
        </div>
    </template>
</div>
