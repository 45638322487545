
<ValidationObserver ref="observer" slim>
    <form class="flex items-center flex-wrap" novalidate @submit.prevent="addTag">
        <span class="txt-13 text-gray-700 mr-2 mt-2 font-semibold">{{ $t('appforms.subscriber.tags') }}:</span>
        <button v-if="!tagFormShown" ref="newTagBtn" class="btn btn-primary radius-2 btn-xs whitespace-nowrap icon-left mt-2 mr-2" @click="showTagForm"><icon size="tiny" icon="plus-solid" />{{ $t('appforms.subscriber.add-tag') }}</button>
        <ValidationProvider v-else rules="required|max:50" v-slot="{ errors }" slim>
            <input ref="newTag" v-model="newTag" v-on-clickaway="hideTagForm" type="text" :placeholder="$t('appforms.subscriber.tag_placeholder')" class="tw-input tw-input-xs mt-2 mr-2 radius-2" :style="{width: tagFormWidth + 'px'}">
            <span v-if="errors.length" class="text-red-500 txt-13 mt-2 mr-2">{{ errors[0] }}</span>
        </ValidationProvider>
        <span v-for="(tag, index) in visibleTags" :key="`tag-${index}`" class="tag sub-tag mr-2 mt-2">{{ tag }} <a href="#" class="text-sail-500 -mr-1 hover:text-sail-700" @click.stop.prevent="removeTag(tag)"><icon :key="`tag-${index}-close`" size="tiny" icon="times-solid" /></a></span>
        <button v-if="thistags.length > 12" type="button" class="more-tags flex items-center mt-2" @click.stop.prevent="showAllTags = !showAllTags">
            <template v-if="showAllTags">
                <span>{{ $t('appforms.subscriber.fewer_tags') }}</span>
                <icon key="tags-caret-up" icon="caret-up-solid" size="small" />
            </template>
            <template v-else>
                <span>{{ $t('appforms.subscriber.more_tags') }}</span>
                <icon key="tags-caret-down" icon="caret-down-solid" size="small" />
            </template>
        </button>
    </form>
</ValidationObserver>
