

<div>
    <h2 class="mb-0.5">
        {{ scenario.name }}
    </h2>

    <alert v-if="isCzechTagScenario" type="info" class="my-4" message="Automat bude fungovat jedině tehdy, kdy je spuštěn jeden z reaktivačních automatů (v kterém jsou kontaktům automaticky přidávány štítky podle aktivity)." />

    <p class="txt-14 text-gray-900 mb-8" v-html="scenario.detail" />

    <alert v-if="isCzechTagScenario" type="warning" class="mb-4" message="V automatizaci dojde k automatickému odhlášení kontaktů, které jsou dlouhodobě neaktivní." />

    <img style="min-height: 310px" :src="scenario.schema" alt="Scenario schema">

    <div class="flex justify-between mt-7.5">
        <button class="btn btn-lg btn-tertiary" type="button" @click.stop.prevent="closeModal()">{{ $t('appforms.pipelines.scenario_preview.cancel_button') }}</button>
        <button v-if="!scenario.marketer || marketer" :disabled="sending" class="btn btn-lg btn-primary" type="button" @click="selectScenario()"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.scenario_preview.confirm_button') }}</button>
        <button v-else class="btn btn-lg btn-primary disabled" type="button" disabled>{{ $t('appforms.pipelines.scenario_preview.marketer_only') }}</button>
    </div>
</div>

