
<div @keyup.enter="resetPassword">
    <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
        {{ $t('auth.reset_password.reset_password') }}
    </div>

    <div class="text-auth-base text-auth-gray-500 text-center">
        {{ $t('auth.login.account_address') }} <span class="semibold text-auth-gray-900">{{ accountUrl }}</span>
    </div>

    <hr class="mt-5 mb-6">

    <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

    <FormInput
            id="email"
            :errors="emailErrors"
            name="email"
            :value="email"
            class="w-full"
            type="email"
            :autofocus="!autofocusPassword"
            :label="$t('auth.login.email.label')"
            required
            :placeholder="$t('auth.login.email.placeholder')"
            @input="emailChanged" />

    <FormInput
            ref="password"
            v-model="password"
            :errors="passwordErrors"
            :autofocus="autofocusPassword"
            class="w-full mt-5"
            type="password"
            :label="$t('auth.login.password.label')"
            required
            :placeholder="$t('auth.join.choose_password')" />
    <FormInput v-model="passwordAgain" :errors="passwordAgainErrors" class="w-full mt-5" type="password" :label="$t('auth.login.password_again.label')" required :placeholder="$t('auth.join.choose_password')" />

    <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="resetPassword">
        <span class="text-auth-base">{{ $t('auth.reset_password.reset_password_cta') }}</span>
    </FormButton>

    <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
        <router-link :to="{name: 'login'}" class="cursor-pointer hover:no-underline text-auth-green-500">
            {{ $t('auth.login.sign_in_cta') }}
        </router-link>
    </div>
</div>
