

<div class="mx-9 mt-6 mb-9">
    <h2>
        {{ $t('appforms.subscriber.orders') }}
    </h2>

    <div v-if="loadingOrders" class="w-full my-16 flex items-center justify-center">
        <div class="double-loader loader-sm loader-grey" />
    </div>

    <div v-else-if="totalTransactions === 0" class="panel py-12 mt-5 text-center">
        <span class="text-gray-800 txt-14">{{ $t('appforms.subscriber.no-orders') }}</span>
    </div>

    <div v-else>
        <p class="txt-14 text-gray-1000 mt-6 mb-5" v-html="$tc('appforms.subscriber.transactions.trans_total', (totalAmount ? numberToLocale(totalAmount) : 0, totalTransactions, applicationCurrencyCode), {amount: totalAmount ? numberToLocale(totalAmount) : 0, transactions: totalTransactions, currency: applicationCurrencyCode})" />
        <div v-for="transaction in transactions" :key="`transaction-${transaction.id}`" class="card grey subscriber orders mb-2 flex-col card-left no-hover space-y-2.5">
            <div class="w-full flex justify-between items-center">
                <div class="txt-13 font-semibold items-center">
                    <span v-if="transaction.timestamp" class="text-gray-1000 mr-1">{{ getNumberDate(transaction.timestamp, false) }}</span>
                    <span v-if="transaction.timestamp" class="text-gray-800 mr-3.5">{{ getTime(getIsoDate(transaction.timestamp), true) }}</span>
                    <div v-if="transaction.status" class="pill transaction-status" :class="getStatusClass(transaction.status)">
                        {{ $t(`appforms.subscriber.transactions.status.${transaction.status}`) }}
                    </div>
                </div>
                <div class="txt-13">
                    <strong class="text-gray-1000"><span class="text-gray-800">{{ $t('appforms.subscriber.transactions.total') }}:</span> {{ transaction.amount !== null ? numberToLocale(transaction.amount) : '--' }} {{ applicationCurrencyCode }}</strong>
                </div>
            </div>
            <div v-for="transactionItem in transaction.items" :key="transactionItem.id + '_' + transaction.id" class="w-full flex justify-between text-gray-1000 txt-13 mb-1">
                <div class="flex flex-col">
                    <div v-if="transactionItem.title" class="font-semibold">
                        {{ transactionItem.title }}<span v-if="!!transactionItem.amount && transactionItem.amount > 1"> ({{ transactionItem.amount }}x)</span> <span class="font-normal text-primary-gray-900">({{ transactionItem.code }})</span>
                    </div>
                    <div class="flex flex-wrap gap-1 items-center">
                        <span v-if="transactionItem.category" class="text-gray-700">{{ transactionItem.category }}</span>
                        <template v-if="transactionItem.tags && transactionItem.tags.length">
                            <div v-for="tag in transactionItem.tags" :key="tag" class="tag tag-sm tag-light-blue">
                                {{ tag }}
                            </div>
                        </template>
                    </div>
                </div>
                <span>{{ transactionItem.price !== null ? numberToLocale(transactionItem.price) : '--' }} {{ applicationCurrencyCode }}</span>
            </div>
            <div v-if="transaction.props && Object.keys(transaction.props).length" class="panel panel-white p-4 space-y-1">
                <div v-for="(data, name, index) in transaction.props" :key="`${transaction.id}-prop-${name}-${index}`" class="space-x-1">
                    <span class="font-semibold text-gray-1000">{{ name }}:</span><span class="text-gray-1000">{{ data }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

