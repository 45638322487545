
<div class="mt-6 mb-9">

    <ValidationObserver ref="observer" slim>
        <form novalidate @submit.prevent="updateContact">
            
            <div class="flex flex-col">
                <h2 class="mb-6 ml-9 mr-48">
                    {{ $t('appforms.subscriber.contact-props') }}
                </h2>

                <div v-if="success !== null" class="flex mx-8">
                    <alert v-if="success === true" class="mb-5" type="success" :message="message" />
                    <alert v-if="success === false" class="mb-5" type="error" :message="message" />
                </div>

                <div class="flex flex-wrap ml-9 mr-48">
                    
                    <ValidationProvider v-slot="{ errors }" name="firstName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                            <label for="contactName" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.first_name_label') }}</label>
                            <input id="contactName" v-model="sub.name" type="text" :placeholder="$t('appforms.subscriber.subprops.first_name_placeholder')" name="firstName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors }" name="lastName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                            <label for="contactSurname" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.last_name_label') }}</label>
                            <input id="contactSurname" v-model="sub.surname" type="text" :placeholder="$t('appforms.subscriber.subprops.last_name_placeholder')" name="lastName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    
                    <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                        <label for="contactPretitle" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.title_before_label') }}</label>
                        <input id="contactPretitle" v-model="sub.pretitle" type="text" :placeholder="$t('appforms.subscriber.subprops.title_before_placeholder')" name="titleBefore" class="tw-input">
                    </div>

                    <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                        <label for="contactSurtitle" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.title_after_label') }}</label>
                        <input id="contactSurtitle" v-model="sub.surtitle" type="text" :placeholder="$t('appforms.subscriber.subprops.title_after_placeholder')" name="titleAfter" class="tw-input">
                    </div>

                    <ValidationProvider v-slot="{ errors }" name="vokativFirstName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pr-2.5 mb-4">
                            <label for="contactVokative" class="tw-label mb-2">{{ $t('appforms.subscriber.subprops.vokativ_first_name_label') }}</label>
                            <input id="contactVokative" v-model="sub.vokativ" type="text" :placeholder="$t('appforms.subscriber.subprops.vokativ_first_name_placeholder')" name="vokativFirstName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider v-slot="{ errors }" name="vokativLastName" rules="person_name" slim>
                        <div class="flex flex-col w-1/2 pl-2.5 mb-4">
                            <label for="contactVokativeS" class="tw-label mb-2">{{ $t('appforms.subscriber.subprops.vokativ_last_name_label') }}</label>
                            <input id="contactVokativeS" v-model="sub.vokativ_s" type="text" :placeholder="$t('appforms.subscriber.subprops.vokativ_last_name_placeholder')" name="vokativLastName" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                    
                    <div class="flex w-full mb-4">
                        <div class="w-1/3 pr-2.5">
                            <ecm-select v-model="sub.gender" :options="genderOptions" option-key="gender_list" option-value-attribute="value" :label="$t('appforms.subscriber.subprops.gender.label')" :title="$t('appforms.subscriber.subprops.gender.title')" null-select-enabled />
                        </div>
                        <div class="flex flex-col w-1/3 px-2.5">
                            <span class="txt-14 tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.nameday_label') }}</span>
                            <date-picker v-model="sub.nameday" :placeholder="$t('appforms.subscriber.subprops.nameday_placeholder')" :options="namedayOptions" />
                        </div>
                        <div class="flex flex-col w-1/3 pl-2.5">
                            <span class="txt-14 tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.birthday_label') }}</span>
                            <date-picker v-model="sub.birthday" :placeholder="$t('appforms.subscriber.subprops.birthday_placeholder')" :options="birthdayOptions" />
                        </div>
                    </div>

                </div>

                <div class="mt-2 mb-4 bg-gray-500" style="height: 1px" />

                <div class="flex flex-wrap ml-9 mr-48">
                    <div class="flex w-full mb-4">
                        <div class="flex flex-col w-3/5 pr-2.5">
                            <label for="contactStreet" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.street_label') }}</label>
                            <input id="contactStreet" v-model="sub.street" type="text" :placeholder="$t('appforms.subscriber.subprops.street_placeholder')" name="street" class="tw-input">
                        </div>
                    </div>

                    <div class="flex w-full mb-4">
                        <div class="flex flex-col w-2/5 pr-2.5">
                            <label for="contactCity" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.city_label') }}</label>
                            <input id="contactCity" v-model="sub.city" type="text" :placeholder="$t('appforms.subscriber.subprops.city_placeholder')" name="city" class="tw-input">
                        </div>

                        <ValidationProvider v-slot="{ errors }" name="postalCode" rules="zip" slim>
                            <div class="flex flex-col w-1/5 px-2.5">
                                <label for="contactZip" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.postal_code_label') }}</label>
                                <input id="contactZip" v-model="sub.zip" type="text" :placeholder="$t('appforms.subscriber.subprops.postal_code_placeholder')" name="postalCode" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>

                        <div class="w-2/5 pl-2.5">
                            <ecm-select v-model="sub.country" :searchable="true" :options="countryOptions" option-key="country_list" :label="$t('appforms.subscriber.subprops.country_label')" :title="$t('appforms.subscriber.subprops.country_placeholder')" />
                        </div>
                    </div>

                    <div class="flex w-full mb-4">
                        <div class="flex flex-col w-1/2 pr-2.5">
                            <label for="contactCompany" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.company_label') }}</label>
                            <input id="contactCompany" v-model="sub.company" type="text" :placeholder="$t('appforms.subscriber.subprops.company_placeholder')" name="company" class="tw-input">
                        </div>

                        <ValidationProvider v-slot="{ errors }" name="phoneNumber" rules="phone" slim>
                            <div class="flex flex-col w-1/2 pl-2.5">
                                <label for="contactPhone" class="tw-label mb-2.5">{{ $t('appforms.subscriber.subprops.phone_number_label') }}</label>
                                <input id="contactPhone" v-model="sub.phone" type="text" :placeholder="$t('appforms.subscriber.subprops.phone_number_placeholder')" name="phoneNumber" class="tw-input" :class="{'tw-err': errors.length > 0 }">
                                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div v-if="listsWithCustomFields.length" class="mt-2 mb-4 bg-gray-500" style="height: 1px" />

                <div v-if="listsWithCustomFields.length" class="flex flex-col flex-wrap ml-9 mr-48">
                    <h5 class="font-normal text-gray-1000">
                        {{ $t('appforms.subscriber.subprops.custom-fields') }}
                    </h5>
                    <div v-for="(list, index) in listsWithCustomFields" :key="`custom-field-list-${list.id}`">
                        <div class="flex flex-col pb-5" :class="{'border-t border-gray-500': index !== 0}">
                            <span class="txt-14 text-gray-900 mt-6 my-3">{{ $t('appforms.subscriber.list') }}: <span class="text-gray-1000 semibold">{{ list.owner.name }}</span></span>
                            <div class="grid grid-cols-2 gap-x-10 gap-y-4 place-items-end">
                                <div v-for="(field, fieldIndex) in customFields[list.owner.id].fields" :key="`cf_${index}_${fieldIndex}`" class="w-full">
                                    <div class="input flex flex-col">
                                        <label :for="`${field.slug}_${list.owner.id}`" class="tw-label mb-2">
                                            <span class="mr-2 break-words">{{ field.shortname }}</span>
                                            <div class="inline-block">
                                                <span class="highlight-blue sail-50 semibold monospace p-1 break-all">*|{{ field.slug }}|*</span>
                                            </div>
                                        </label>
                                        <input :id="`${field.slug}_${list.owner.id}`" v-model="cfields[`${field.slug}_${list.owner.id}`]" type="text" :placeholder="$t('appforms.subscriber.subprops.custom-fields')" class="tw-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-2 border-t border-gray-500 tw-modal-buttons w-full px-9">
                    <button type="button" class="btn btn-tertiary tw-modal-close-button btn-lg" :disabled="!formChanged" @click.stop.prevent="clearChanges">{{ $t('appforms.subscriber.cancel-changes') }}</button>
                    <button class="btn btn-primary float-right btn-lg" :disabled="sending || !formChanged"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" /> {{ $t('appforms.subscriber.save-changes') }}</button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</div>
