
<div class="relative flex">
    <!--    To turn off autocomplete when otp-input is password-->
    <input
            v-if="inputType === 'password'"
            autocomplete="off"
            name="hidden"
            type="text"
            style="display: none">
    <SingleOtpInput
            v-for="(item, i) in this.numInputs"
            :key="`input-${i}`"
            :focus="activeInput === i"
            :value="otp[i]"
            :separator="separator"
            :input-type="inputType"
            :input-classes="inputClasses"
            :num-inputs="numInputs"
            :is-third-child="i === 2"
            :is-fourth-child="i === 3"
            :is-last-child="i === numInputs - 1"
            :should-auto-focus="shouldAutoFocus"
            @on-change="handleOnChange"
            @on-paste="handlePaste"
            @on-keydown="handleOnKeyDown"
            @on-focus="handleOnFocus(i)"
            @on-blur="handleOnBlur" />

    <div
            v-if="error"
            data-testid="input-error-message"
            class="absolute text-sm left-2 -bottom-7 text-topol-red-600">
        {{ error }}
    </div>
</div>
