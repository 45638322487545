
<div v-if="prevPage || nextPage">
    <ul class="ecm-pagination">
        <li>
            <button class="arrow-button left-arrow" :disabled="!prevPage" @click.prevent="changePage(currentPage - 1)"><icon key="left" icon="angle-left-solid" /></button>
        </li>

        <li>
            <button class="arrow-button right-arrow" :disabled="!nextPage" @click.prevent="changePage(currentPage + 1)"><icon key="right" icon="angle-right-solid" /></button>
        </li>
    </ul>
</div>
