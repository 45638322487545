<template>

    <div class="overlay-panel-bg" :class="{'is-displayed': panelOverlay}" @click.self.prevent="closeModal()">
        <div class="overlay-panel mc-r ec-container mx-auto" :class="{'is-displayed': panelOverlay}">
            <div class="main-panel">

                <div class="h2 flex justify-between">
                    <h2 class="txt-22">
                        {{ $t('appforms.pipelines.new_automation.title') }}
                    </h2>
                    <button class="btn btn-tertiary icon-left" @click.stop.prevent="closeModal()"><icon icon="times-solid" size="small" /><span>{{ $t('common.close') }}</span></button>
                </div>
                <ValidationObserver ref="observer" slim>

                    <form novalidate method="post" @submit.prevent="createPipeline">
                        <div v-if="step === 1" class="mx-auto px-10 md:px-0" style="max-width: 400px;">

                            <h2 class="mt-8.5">
                                {{ $t('appforms.pipelines.new_automation.heading') }}
                            </h2>

                            <div class="txt-14 text-gray-900 mt-0.5 mb-8">
                                {{ $t('appforms.pipelines.new_automation.description') }}
                                <p v-if="!showImport" class="cursor-pointer" @click="showImport = !showImport" v-html="$t('appforms.pipelines.new_automation.import.description')" />
                            </div>

                            <div class="flex flex-col pb-5">

                                <alert v-if="importError === true" class="mb-5" type="error" :message="$t('appforms.pipelines.new_automation.import.error')" />
                                <alert v-if="success === true" class="mb-5" type="success" :message="$t('common.changes_saved')" />

                                <div v-if="showImport" class="input flex flex-col mb-8">
                                    <label for="pipelineName" class="tw-label mb-2.5">Import automatizace</label>
                                    <div class="txt-14 text-gray-900 ">
                                        <p class="txt-14 text-gray-900" v-html="$t('appforms.pipelines.new_automation.import.info')" />
                                        <UploadNew
                                                id="automation_import"
                                                class="bg-white"
                                                name="automation_import"
                                                accepted="application/zip,application/octet-stream,application/x-zip-compressed "
                                                bucket="ecomail-automation-imports"
                                                @uploaded="fileUploaded"
                                                @removed="fileRemoved" />
                                    </div>
                                </div>
                                <!-- put new automation block here -->
                                <ValidationProvider v-slot="{ errors }" name="pipelineName" rules="required|max:255" slim>
                                    <div class="input flex flex-col mb-8">
                                        <label for="pipelineName" class="tw-label mb-2.5">{{ $t('appforms.pipelines.new_automation.name_label') }}</label>
                                        <input id="pipelineName" v-model="name" name="pipelineName" type="text" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.pipelines.new_automation.name_placeholder')">
                                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                                    </div>
                                </ValidationProvider>

                                <div class="txt-14 uppercase mb-5 font-semibold">
                                    {{ $t('appforms.pipelines.new_automation.parameters_label') }}
                                </div>

                                <ecm-select v-model="multicast" class="mb-4" :options="frequencyOptions" :label="$t('appforms.pipelines.new_automation.frequency_label')" />

                                <ValidationProvider v-slot="{ errors }" name="list" rules="required" slim>
                                    <div class="input flex flex-col mb-7.5">
                                        <ecm-select v-model="list" :options="lists" :label="$t('appforms.pipelines.new_automation.list_label')" :title="$t('appforms.pipelines.new_automation.list_title')" />
                                        <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                                    </div>
                                </ValidationProvider>

                                <div class="tw-modal-buttons">
                                    <button type="button" class="btn btn-tertiary btn-lg" @click.stop.prevent="closeModal()">{{ $t('common.close') }}</button>
                                    <button type="submit" class="btn btn-primary float-right btn-lg" :disabled="sending" @click="nextStep"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ importing ? $t('appforms.pipelines.new_automation.import.cta'): $t('common.continue') }}</button>
                                </div>

                            </div>

                        </div>

                        <div v-else class="mx-auto px-10 md:px-0" style="max-width: 1000px;">

                            <h2 class="mt-8.5">
                                {{ $t('appforms.pipelines.new_automation.scenario_title') }}
                            </h2>

                            <p class="txt-14 text-gray-900 mb-8.5">
                                {{ $t('appforms.pipelines.scenarios-info') }}
                            </p>

                            <alert v-if="success === false" class="mb-5" type="error" :message="message" />

                            <div class="flex mb-5">
                                <a class="panel mr-3 w-1/2 has-green-hover p-4 flex items-center cursor-pointer" @click="startEmpty()">
                                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/blank_template.png" style="height: 80px" alt="Blank template">
                                    <div class="flex flex-col ml-2 text-sm">
                                        <a class="link-text link-blue">
                                            {{ $t('appforms.pipelines.new_automation.empty_scenario') }}
                                        </a>
                                        <div class="text-gray-900 mt-1">
                                            {{ $t('appforms.pipelines.new_automation.empty_scenario_description') }}
                                        </div>
                                    </div>
                                </a>
                                <a class="panel ml-3 w-1/2 has-green-hover p-4 flex items-center cursor-pointer" @click="showTailorMadeModal()">
                                    <img src="https://ecomail-assets.s3.amazonaws.com/old/images/tailor-made-automation.png" style="height: 80px" alt="Tailor made template">
                                    <div class="flex flex-col ml-2 text-sm">
                                        <a class="link-text link-blue">
                                            {{ $t('appforms.pipelines.new_automation.tailor_made') }}
                                        </a>
                                        <div class="text-gray-900 mt-1">
                                            {{ $t('appforms.pipelines.new_automation.tailor_made_description') }}
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="scenarios-list flex flex-wrap -ml-5">
                                <div v-for="scheme in scenarios" :key="scheme.id" class="px-5 w-full md:w-1/4">
                                    <div class="card card-light no-pad template-box">
                                        <div class="screenshot flex items-center justify-center bg-grey-10">
                                            <div class="bg-white rounded-sm flex items-center justify-center">
                                                <img :src="scheme.icon" alt="" style="width: 80px">
                                            </div>
                                        </div>
                                        <div class="hover-info px-5 pt-5">
                                            <p class="dimmed80 txt-12 mb-3">
                                                {{ scheme.perex }}
                                            </p>
                                            <button class="btn btn-tertiary block justify-center mb-3" type="button" @click="preview(scheme.id)">{{ $t('appforms.pipelines.scenario-preview') }}</button>
                                            <button v-if="!scheme.marketer || marketer" :disabled="sending" class="btn btn-primary block justify-center w-full" type="submit" @click="selectScenarioId(scheme.id)"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.use-scenario') }}</button>
                                            <button v-else class="btn btn-primary block justify-center w-full disabled" type="button" disabled>{{ $t('appforms.pipelines.marketer-only') }}</button>
                                        </div>
                                        <div class="info grey-70 break-words">
                                            <strong class="txt-13">{{ scheme.name }}</strong>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>

</template>

<script>

import {ValidationObserver} from 'vee-validate'

import Tracker from '../../../tracker'
import UploadNew from '@component/Upload/UploadNew.vue'
import testing from '@mixins/testing'

export default {

    name: 'NewAutomation',

    mixins: [testing],

    props: ['marketer', 'lists', 'scenarios', 'modal'],

    components: {
        UploadNew,
        ValidationObserver,
    },

    tracker: null,

    data () {
        return {
            panelOverlay: false,
            sending: false,
            success: null,
            message: null,
            id: null,
            name: null,
            multicast: '0',
            frequencyOptions: [
                {
                    name: this.$t('appforms.pipelines.new_automation.frequency_single'),
                    id: '0'
                },
                {
                    name: this.$t('appforms.pipelines.new_automation.frequency_multiple'),
                    id: '1'
                },
                {
                    name: this.$t('appforms.pipelines.new_automation.frequency_parallel'),
                    id: '2'
                }
            ],
            list: null,
            step: 1,
            scenario: null,
            showImport: false,
            importing: false,
            importFilename: null,
            importError: false,
        }
    },

    watch: {
        panelDisplayed(val) {
            this.panelOverlay = val
        }
    },

    created() {
        this.$options.tracker = new Tracker()
    },

    methods: {
        showModal() {
            this.panelOverlay = true
        },

        closeModal() {
            this.panelOverlay = false
            this.step = 1
            this.$emit('closed')
        },

        fileUploaded(file) {
            this.importFilename = file
            this.importing = true
        },

        fileRemoved() {
            this.importing = false
        },

        async createPipeline() {

            const isValid = await this.$refs.observer.validate()
            if(isValid && !this.sending) {
                this.sending = true
                try {
                    const pipeline = {
                        name: this.name,
                        list_id: this.list,
                        migrated: '1'
                    }

                    const response = await this.$http.post('/spa/automation', {
                        pipeline: pipeline,
                        multicast: this.multicast,
                        scenarioId: this.scenario
                    })

                    window.location.href = `/automation/${response.data.automationId}/edit?strategy=${this.scenario}`
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        },
        
        async createFromScenario() {
            const isValid = await this.$refs.observer.validate()
            if(isValid && !this.sending) {
                this.sending = true
                try {

                    const response = await this.$http.post('/spa/automation/create-from-scenario', {
                        name: this.name,
                        list_id: this.list,
                        multicast: this.multicast,
                        scenarioId: this.scenario
                    })

                    window.location.href = `/automation/${response.data.automationId}/edit?strategy=${this.scenario}`
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        },
        

        async nextStep() {
            const isValid = await this.$refs.observer.validate()
            if(isValid) {
                if(this.importing) {
                    await this.importAutomation()
                    return
                }
                this.step++
            }
        },

        async importAutomation() {
            this.sending = true
            try {

                const response = await this.$http.post('spa/automation/import', {
                    name: this.name,
                    list_id: this.list,
                    file_url: this.importFilename,
                    multicast: this.multicast,
                })

                window.location.href = `/automation/${response.data.automationId}/edit`
                this.sending = false
            }
            catch(error) {
                this.sending = false
                this.success = false
                this.importError = true
            }
            this.sending = false
        },

        preview(id) {
            this.$emit('showScenarioPreview', Object.values(this.scenarios).find(scenario => scenario.id === id))
            this.scenario = id
        },

        showTailorMadeModal() {
            this.$emit('showTailorMade')
        },

        startEmpty() {
            this.$options.tracker.trackEvent('PIPELINE_EMPTY')
            this.scenario = ''
            this.createPipeline()
        },

        selectScenarioId(id) {
            this.$options.tracker.trackEvent('PIPELINE_SCENARIO', {id: id})
            this.scenario = id
            this.createFromScenario()
        }
    }
}
</script>
