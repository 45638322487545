

<div>
    <div v-if="loading" class="w-full h-64 flex items-center justify-center">
        <div class="double-loader loader-sm loader-grey mr-2" />
    </div>

    <div v-else-if="errorMessage" class="p-4">
        <alert type="error" :message="errorMessage" />
    </div>

    <div v-else>

        <div class="flex flex-col md:flex-row bb justify-between bg-gray-100 pb-5 px-9" style="padding-top: 26px;">
            <div class="flex">
                <div class="icon-portrait mr-8 hidden md:flex flex-shrink-0 items-center justify-center" :style="{backgroundColor: shortLetterColor}">
                    {{ shortLetter }}
                </div>
                <div>
                    <h3 v-if="subscriber.name && subscriber.surname" class="text-gray-900 txt-16">
                        {{ subscriber.name }}&nbsp;{{ subscriber.surname }}
                    </h3>
                    <h4 class="text-gray-1000 txt-18 semibold">
                        {{ subscriber.email }}
                    </h4>
                    <div class="flex items-center mt-1">
                        <div class="tag pill-gray pl-5 mr-2">
                            <icon icon="star-solid" size="tiny" class="text-orange-500 -ml-1.5" />
                            <span class="text-gray-800 semibold">{{ subscriber.rating }}</span>
                        </div>
                        <div v-if="list" class="tag mr-4" :class="getStatusClass(listStatus)">
                            <span>{{ $t(`appforms.subscriber.sub_status.${listStatus}`) }}</span>
                        </div>
                        <div class="txt-13 semibold text-gray-700">
                            <span v-if="subscriber && subscriber.inserted_at && subscriber.inserted_at !== '0000-00-00 00:00:00'" v-html="$t('appforms.subscriber.inserted.present', {value: getNumberDate(getIsoDate(subscriber.inserted_at))})" />
                            <span v-else v-html="$t('appforms.subscriber.inserted.not_present')" />
                        </div>
                    </div>
                    <subscriber-tags :email="email" :tags="subscriber.tags" @tagsUpdated="contactUpdated" />
                </div>
            </div>
            <div v-if="checkRole(user, 'manage-contacts-unsub')" class="flex flex-row md:flex-col mt-5 md:mt-0 space-y-2.5">
                <button v-if="listStatus === 1" class="btn btn-tertiary justify-center" @click="mode = 'unsub'">{{ $t('appforms.subscriber.unsubscribe') }}</button>
                <button class="btn btn-secondary btn-danger icon-left justify-center" @click="mode = 'delete'">
                    <icon icon="trash-solid" size="small" />
                    {{ $t('appforms.subscriber.delete') }}
                </button>
            </div>
        </div>

        <template v-if="mode === null">
            <div class="shadow-under" :class="{'scrolled': scrolled}">
                <tab-row v-model="active" class="mt-3" :options="['stretch', 'small']">
                    <template #fillBefore>
                        <div key="fill-before" class="flex flex-grow self-end fill" style="height: 1px;" />
                    </template>
                    <template #tab="slotProps">
                        <tab-item v-for="(tab, index) in tabs" :key="`tab-option-${index}`" :value="tab" :title="$t('appforms.subscriber.' + tab)" :selected="active" :count="tab === 'notes' ? notesCount : null" :trigger-select="slotProps.triggerSelect" :options="slotProps.options" />
                    </template>
                    <template #fillAfter>
                        <div key="fill-after" class="flex flex-grow self-end fill" style="height: 1px;" />
                    </template>
                </tab-row>
            </div>

            <vue-custom-scrollbar ref="content-layout" class="scroll-container-height" :settings="settings" @ps-y-reach-start="scrolled = false" @ps-scroll-y="handleScroll()">
                <div v-if="active === 'overview'" class="mt-6 mb-3">
                    <div class="px-9">
                        <h5 class="pb-4 font-normal">
                            {{ $t('appforms.subscriber.last-activity') }}
                        </h5>

                        <sub-activity class="pb-6" :log="log" :application-currency-code="applicationCurrencyCode" />
                    </div>

                    <div v-if="!Array.isArray(lists)" class="bg-gray-500" style="height: 1px" />

                    <div v-if="!Array.isArray(lists)" class="px-9 pt-4 pb-6">
                        <h5 class="mb-4 font-normal">
                            {{ $t('appforms.subscriber.lists') }}
                        </h5>

                        <sub-lists :email="email" :lists="lists" :can-unsubscribe="checkRole(user, 'manage-contacts-unsub')" :doi-dates="doiDates" />
                    </div>
                </div>

                <props v-else-if="active === 'props'" :subscriber="subscriber" :lists="lists" :custom-fields="customFields" @reloadData="loadData" @updateSubscriberLocally="updateSubscriberLocally" @scrollTop="scrollTop" />

                <activities v-else-if="active === 'activity'" :email="email" :application-currency-code="applicationCurrencyCode" @scrollTop="scrollTop" />

                <orders v-else-if="active === 'orders'" :email="email" :application-currency-code="applicationCurrencyCode" />

                <ml v-else-if="active === 'ml'" :email="email" />

                <notes v-else-if="active === 'notes'" :email="email" :notes="subscriber.notes" @reloadData="loadData" />
            </vue-custom-scrollbar>
        </template>

        <vue-custom-scrollbar ref="content-delete-layout" class="scroll-delete-height" :settings="settings">
            <div v-if="mode === 'delete'" class="px-9 pt-6 pb-9">
                <h2 class="mb-5">
                    {{ $t('appforms.subscriber.delete-confirm') }}
                </h2>

                <div class="panel p-4 mb-9 space-y-4" :class="{'panel-danger': confirmDelete}">
                    <div class="tw-checkbox">
                        <input id="deleteContact" v-model="confirmDelete" type="checkbox" value="yes">
                        <label for="deleteContact" class="txt-14 text-black">{{ $t('appforms.subscriber.delete-confirm2') }}</label>
                    </div>
                </div>

                <div class="flex justify-between">
                    <button class="btn btn-lg btn-tertiary" @click="mode = null">{{ $t('appforms.subscriber.cancel') }}</button>
                    <button class="btn btn-lg btn-danger" :disabled="!confirmDelete" @click="deleteContact">{{ $t('appforms.subscriber.delete-contact') }}</button>
                </div>
            </div>

            <div v-if="mode === 'unsub'" class="px-9 pt-6 pb-9">
                <h2 class="mb-5">
                    {{ $t('appforms.subscriber.unsubscribe-confirm') }}
                </h2>
                <div class="panel p-4 mb-9 space-y-4">
                    <div v-for="(subscribedList, index) in subscribedLists" :key="`subscribed-list-${index}`" class="tw-checkbox">
                        <input :id="`subscribed-list-${subscribedList.list_id}`" type="checkbox" :name="subscribedList.list_id" :value="subscribedList.list_id" :checked="listsToUnsub.includes(subscribedList.list_id.toString())" @change="onCheck">
                        <label :for="`subscribed-list-${subscribedList.list_id}`" class="txt-14 text-black">{{ subscribedList.owner.name }}</label>
                    </div>
                </div>

                <div class="flex justify-between">
                    <button class="btn btn-lg btn-tertiary" @click="mode = null">{{ $t('appforms.subscriber.cancel') }}</button>
                    <button class="btn btn-lg btn-primary" :disabled="!listsToUnsub.length" @click="unsubContact()">{{ $t('appforms.subscriber.unsubscribe-contact') }}</button>
                </div>
            </div>
        </vue-custom-scrollbar>

    </div>
</div>

