
<div class="relative flex items-start">
    <div class="flex items-center h-5">
        <input
                :id="label"
                v-model="checkValue"
                type="checkbox"
                class="w-5 h-5 cursor-pointer appearance-none transition ease-in-out duration-150 text-auth-green-600 border border-auth-gray-300 rounded bg-auth-gray-50
                focus:border-auth-green-600 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-auth-green-300 focus:ring-offset-auth-gray-50
                checked:bg-auth-green-600 checked:border-auth-green-600">
    </div>
    <label
            :for="label"
            class="font-semibold ml-3 text-auth-sm leading-5 text-auth-gray-700 cursor-pointer">{{ label }}</label>
</div>
