// App basics
import navbar from '@component/Navbar/Navbar.vue'
import userMenuComponent from '@component/UserMenu/UserMenu.vue'
import notificationsMenuComponent from '@component/Notifications/NotificationsMenu.vue'
import notifications from '@component/Notifications/Notifications.vue'
import alert from '@component/Alerts/Alert.vue'
import globalMessages from '@component/Alerts/GlobalMessages.vue'
import textInput from './components/Inputs/TextInput.vue'
import checkbox from '@component/Inputs/Checkbox.vue'
import tabs from '@component/Storybook/Navigation/Tabs.vue'
import dropdown from './directives/dropdown'
import accordion from './directives/accordion'
import icon from '@component/Icons/Icon.vue'
import pagination from '@component/Pagination/Pagination.vue'
import simplePagination from '@component/Pagination/SimplePagination.vue'

// Forms
import editAutomation from '@component/AppForms/Pipelines/EditAutomation.vue'
import newAutomation from '@component/AppForms/Pipelines/NewAutomation.vue'
import duplicateAutomationForm from '@component/AppForms/Pipelines/DuplicateAutomationForm.vue'
import tailorMadeAutomationForm from '@component/AppForms/Pipelines/TailorMadeAutomationForm.vue'
import scenarioPreview from '@component/AppForms/Pipelines/ScenarioPreview.vue'
import subscriberForm from '@component/AppForms/Subscriber/SubscriberForm.vue'
import ChooseMjml from '@component/Templates/ChooseMjml.vue'
import ecmSelect from '@component/Inputs/EcmSelect.vue'
import ecmAutocomplete from '@component/Inputs/EcmAutocomplete.vue'

// Modals
import twModal from '@component/Modal/TwModal.vue'
import supportModal from '@component/Modal/SupportModal.vue'
import twConfirm from '@component/Modal/TwConfirm.vue'
import searchModal from '@component/Modal/SearchModal.vue'

// Error page
import errorPage from '@pages/errors/errorPage.vue'


const GlobalComponents = {
    install (Vue) {
        Vue.component('navbar', navbar)
        Vue.component('ecm-select', ecmSelect)
        Vue.component('ecm-autocomplete', ecmAutocomplete)
        Vue.component('user-menu', userMenuComponent)
        Vue.component('notifications-menu', notificationsMenuComponent)
        Vue.component('notifications', notifications)
        Vue.component('alert', alert)
        Vue.component('global-messages', globalMessages)
        Vue.component('text-input', textInput)
        Vue.component('ecm-switch', () => import('@component/Inputs/EcmSwitch.vue'))
        Vue.component('tabs', tabs)
        Vue.directive('dropdown', dropdown)
        Vue.directive('accordion', accordion)
        Vue.component('icon', icon)
        Vue.component('pagination', pagination)
        Vue.component('simple-pagination', simplePagination)

        Vue.component('edit-automation', editAutomation)
        Vue.component('new-automation', newAutomation)
        Vue.component('duplicate-automation-form', duplicateAutomationForm)
        Vue.component('tailor-made-automation-form', tailorMadeAutomationForm)
        Vue.component('scenario-preview', scenarioPreview)
        Vue.component('subscriber-form', subscriberForm)
        Vue.component('choose-mjml', ChooseMjml)

        Vue.component('tw-modal', twModal)
        Vue.component('support-modal', supportModal)
        Vue.component('tw-confirm', twConfirm)
        Vue.component('search-modal', searchModal)
        Vue.component('checkbox', checkbox)

        Vue.component('error-page', errorPage)
    }
}

export default GlobalComponents
