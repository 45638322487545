

<div class="space-y-2">
    <div v-for="connection in lists" :key="`list-${connection.id}`" v-accordion="'open'" class="tw-accordion card subscriber mb-2 flex flex-col items-start no-pad no-hover w-full">
        <div class="flex w-full p-3 cursor-pointer items-center" accordion-toggle>
            <span class="mr-3"><icon :key="`status-icon-${connection.status}`" class="flex-none" :class="STATUS_COLORS[connection.status]" :icon="STATUS_ICONS[connection.status]" /></span>
            <span class="txt-13 text-gray-1000 semibold flex-grow">{{ connection.owner.name }}</span>
            <icon class="caret-icon text-gray-900" icon="caret-down-solid" />
        </div>
        <div class="tw-accordion-content px-4 pb-4 w-full">
            <div class="ml-7">
                <alert v-if="savingSuccess === connection.list_id" class="mb-5" type="success" :message="$t('appforms.subscriber.preferences-saved')" />
                <alert v-if="unsubMessage" type="error" class="mb-5" :message="$t('appforms.subscriber.unsub-not-permitted')" />
                <table class="txt-13 text-gray-1000 w-full">
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.list') }}
                        </td>
                        <td>
                            <a :href="'/contacts/' + connection.list_id" class="semibold">{{ connection.owner.name }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.status') }}
                        </td>
                        <td class="space-x-3">
                            <span>{{ $t('appforms.subscriber.sub_status.' + connection.status) }}</span>
                            <a v-if="connection.status === 1 || connection.status === 3" href="#" class="semibold" @click.stop.prevent="unsubscribe(connection.list_id)">{{ $t('appforms.subscriber.unsubscribe') }}</a>
                            <a v-else href="#" class="semibold" @click.stop.prevent="resubscribe(connection.list_id)">{{ $t('appforms.subscriber.subscribe') }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.sms_status') }}
                        </td>
                        <td class="space-x-3">
                            <span>{{ $t('appforms.subscriber.sub_status_sms.' + connection.sms_status) }}</span>
                            <a v-if="connection.sms_status === 1" href="#" class="semibold" @click.stop.prevent="unsubscribeSms(connection.list_id)">{{ $t('appforms.subscriber.unsubscribe') }}</a>
                            <a v-else href="#" class="semibold" @click.stop.prevent="resubscribeSms(connection.list_id)">{{ $t('appforms.subscriber.subscribe') }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ connection.status !== 2 ? $t('appforms.subscriber.signup-date') : $t('appforms.subscriber.unsubscribe-date') }}
                        </td>
                        <td>
                            {{ connection.status !== 2 ? getNumberDate(getIsoDate(connection.subscribed_at)) : getNumberDate(getIsoDate(connection.unsubscribed_at)) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.doi_date') }}
                        </td>
                        <td>
                            {{ getNumberDate(getIsoDate(doiDates[connection.list_id])) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="semibold text-gray-800 py-1 pr-12 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.source') }}
                        </td>
                        <td>
                            {{ connection.source }}
                        </td>
                    </tr>
                    <tr v-if="Object.keys(connection.owner.groups).length > 0">
                        <td class="semibold text-gray-800 align-top py-1 pr-12 pt-3 w-1 whitespace-nowrap">
                            {{ $t('appforms.subscriber.preferences') }}
                        </td>
                        <td class="align-top pt-3 space-y-3">
                            <div v-for="group in connection.owner.groups" :key="'grp' + group.id" class="flex flex-col space-y-2">
                                <template v-if="group.category.length > 0">
                                    <span class="txt-13 semibold text-gray-1000 mb-1">{{ group.name }}</span>
                                    <template v-if="group.type === 'checkbox'">
                                        <div v-for="category in group.category" :key="'cat' + category.id" class="tw-checkbox">
                                            <input :id="category.id" v-model="groups[connection.owner.id][group.id][category.name]" type="checkbox" :name="category.id" @change="changed(connection.list_id)">
                                            <label :for="category.id" class="txt-13">{{ category.name }}</label>
                                        </div>
                                    </template>
                                    <template v-else-if="group.type === 'radio'">
                                        <div v-for="category in group.category" :key="'cat' + category.id" class="tw-radio">
                                            <label :for="category.id">
                                                <input :id="category.id" v-model="groups[connection.owner.id][group.id]" type="radio" :name="group.id" :value="category.name" @change="changed(connection.list_id)"> <span class="radiomark mr-3" /> <span class="txt-13">{{ category.name }}</span>
                                            </label>
                                        </div>
                                    </template>
                                    <template v-else-if="group.type === 'select'">
                                        <select id="invoiceCountry" v-model="groups[connection.owner.id][group.id]" class="tw-select txt-13" style="max-width: 250px" @change="changed(connection.list_id)">
                                            <option v-for="category in group.category" :key="'cat' + category.id" :value="category.name">{{ category.name }}</option>
                                        </select>
                                    </template>
                                    <template v-else-if="group.type === 'hidden'">
                                        <div class="input flex flex-col">
                                            <input id="contactEmail" v-model="groups[connection.owner.id][group.id]" type="text" class="tw-input" style="max-width: 250px" @keyup="changed(connection.list_id)">
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div v-if="preferencesChanged.includes(connection.list_id)" class="flex mt-4 space-x-4">
                                <button type="button" class="btn btn-primary" :disabled="savingPreferences === connection.list_id" @click="savePreferences(connection.owner.id)"><div v-if="savingPreferences === connection.list_id" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.subscriber.save-preferences') }}</button>
                                <button type="button" class="btn btn-tertiary" :disabled="savingPreferences === connection.list_id" @click="resetPreferences(connection.list_id)">{{ $t('appforms.subscriber.cancel-preferences') }}</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

