export default class Tracker {

    constructor() {
        if(window.mixpanel) {
            this.tracker = window.mixpanel
        }
    }

    setUserInAccount(email, account, name = '') {
        if(this.tracker) {
            if(name === '') {
                name = email
            }
            this.tracker.identify(account + '_' + email)
            this.tracker.people.set({
                '$name': name,
                '$email': email,
                'account': account,
            })
            this.setAccount(account)
        }
    }

    setAccount(account) {
        if(this.tracker) {
            this.tracker.set_group('account', [account])
        }
    }

    trackEvent(event, props = {}) {
        if(this.tracker) {
            console.log(event)
            this.tracker.track(event, props)
        }
    }

}
