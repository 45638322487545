<template>
    <div>
        <alert
                v-for="(globalMessage, i) in globalMessages"
                :key="`global-message-${i}`"
                :message="globalMessage.message"
                :label="globalMessage.title"
                :link="linkLeadsElsewhere(globalMessage.link) ? globalMessage.link : null"
                class="mb-4"
                :type="globalMessage.type"
                :closable="globalMessage.type === 'info'"
                :primary-action="globalMessage.primaryAction === 'supportLink' ? supportLink: globalMessage.primaryAction"
                :secondary-action="globalMessage.secondaryAction === 'supportLink' ? supportLink: globalMessage.secondaryAction"
                @confirmPrimary="actionConfirmed({message: globalMessage, isPrimary: true})"
                @confirmSecondary="actionConfirmed({message: globalMessage, isPrimary: false})" />
    </div>
</template>

<script>
import Alert from './Alert.vue'

export default {
        
    name: 'GlobalMessages',

    props: ['globalMessages'],

    components: {
        Alert
    },
    
    data() {
        return {
            supportLink: {
                text: this.$t('common.contact_support')
            }
        }  
    },

    methods: {
        actionConfirmed({message, isPrimary}) {
            if(isPrimary === true && message.primaryAction === 'supportLink') {
                // this could be a mixin
                this.$root.$refs['supportModal'].showModal()
            }
            else if(isPrimary === false && message.secondaryAction === 'supportLink') {
                this.$root.$refs['supportModal'].showModal()
            }
        },

        linkLeadsElsewhere(link) {
            return link && link.url && link.url !== this.$route.path
        }
    }
}
</script>

<style scoped>

</style>