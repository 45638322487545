
<div
        class="flex items-center"
        :class="{ 'sm:mr-2': isThirdChild, 'sm:ml-2': isFourthChild }">
    <input
            ref="input"
            :value="value"
            class="w-10 py-[7px] text-3xl font-medium leading-10 text-auth-gray-900"
            :type="inputType"
            pattern="[0-9]+"
            :class="inputClasses"
            @input="handleOnChange"
            @keydown="handleOnKeyDown"
            @focus="handleOnFocus"
            @blur="handleOnBlur">
    <span v-if="!isLastChild && separator">
        <span v-html="separator" />
    </span>
</div>
