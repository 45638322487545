
<v-date-picker
        v-model="date"
        mode="date"
        :rows="rows"
        :locale="locale"
        :columns="cols"
        :step="1"
        :min-date="minDate"
        :max-date="maxDate"
        data="yyyy-MM-dd"
        :is-range="mode === 'range'">
    <template v-if="mode === 'single'" #default="{ togglePopover }">
        <div class="relative cursor-pointer" @click="togglePopover">
            <input
                    :value="displayDate"
                    class="w-full tw-input"
                    style="cursor: pointer; opacity: 1"
                    :name="name"
                    :placeholder="placeholder"
                    readonly>
            <div class="svg-wrapper large-icon absolute text-gray-900" style="right: 5px; top: 7px">
                <svg-vue icon="calendar-alt-solid" />
            </div>
        </div>
    </template>
    <template v-else-if="mode === 'range'" #default="{ togglePopover }">
        <div class="relative w-full pl-2 pr-8 border-gray-600 border shadow-inner flex justify-around text-gray-900 text-sm bg-white ecm-datepicker-range cursor-pointer" @click="togglePopover">
            <span>
                {{ displayDate !== null && displayDate.start ? displayDate.start: $t('common.datepicker.from_date') }}
            </span>
            <span>
                -
            </span>
            <span>
                {{ displayDate !== null && displayDate.end ? displayDate.end: $t('common.datepicker.to_date') }}
            </span>
            <div class="svg-wrapper large-icon absolute text-gray-900" style="right: 7px; top: 7px">
                <svg-vue icon="calendar-alt-solid" />
            </div>
        </div>
    </template>
</v-date-picker>
