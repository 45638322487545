
<div @keyup.enter="verifyCode">

    <div class="semibold text-auth-xl text-auth-black text-center mb-[2px]">
        {{ $t('auth.2fa.header') }}
    </div>

    <div class="text-auth-base text-auth-gray-500 text-center" v-html="$t('auth.2fa.email.anotation')" />

    <hr class="mt-5 mb-6">

    <alert v-if="showResendMessage" class="mb-5" type="success" :message="$t('auth.2fa.email.email_resent')" />
    <alert v-if="requestError" class="mb-5" type="error" :message="requestError" />

    <FormInput
            id="loginEmail"
            v-model="verificationCode"
            :errors="errors"
            :autofocus="true"
            class="w-full"
            type="email"
            name="email"
            :label="$t('auth.2fa.email.verify_key')"
            required
            :placeholder="$t('auth.2fa.email.verify_key_from_mail')" />

    <FormButton type="button" :loading="loading" xlarge primary square class="mt-8 w-full" @click="verifyCode">
        <span class="text-auth-base">{{ $t('auth.2fa.verify_key_cta') }}</span>
    </FormButton>

    <div class="text-auth-gray-500 text-auth-sm text-center semibold mt-[42px]">
        <span>{{ $t('auth.2fa.email.no_email') }}&nbsp;</span>
        <a href="#" class="cursor-pointer hover:no-underline text-auth-green-500" @click.prevent="resendCode">
            {{ $t('auth.2fa.email.resend_email') }}
        </a>
    </div>
</div>
