

<div v-show="!closed" class="alert" :class="classObj" @click="$emit('click')">
    <div v-if="label" class="alert-title">
        <div class="alert-icon" :class="classObj">
            <icon :icon="selectedIcon.name" size="large" />
        </div>
        <div class="alert-title-text">
            <p class="alert-label" v-html="label" />
            <a v-if="link" target="_blank" :href="link.url" class="alert-link">
                {{ link.text }}
            </a>
        </div>
        <button v-if="closable" class="alert-close" @click.stop="close()">
            <icon icon="times-solid" size="tiny" />
        </button>
    </div>
    <div v-if="message || primaryAction" class="alert-content" :class="withoutLabel">
        <p v-if="message" class="alert-message" v-html="message" />
        <div v-if="primaryAction" class="alert-actions">
            <a :href="primaryAction.url" class="alert-action" target="_blank" @click.stop="confirmPrimaryAction" v-html="primaryAction.text" />
            <a v-if="secondaryAction" :href="secondaryAction.url" target="_blank" class="alert-action" @click.stop="confirmSecondaryAction" v-html="secondaryAction.text" />
        </div>
    </div>
    
</div>

