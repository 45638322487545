

<div class="mx-9 mt-6 mb-9">
    <h2 class="mb-5">
        {{ $t('appforms.subscriber.activities.title') }}
    </h2>

    <div v-if="loadingActivities" class="w-full my-16 flex items-center justify-center">
        <div class="double-loader loader-sm loader-grey" />
    </div>

    <div v-else>
        <div class="flex items-start justify-between txt-10.5 text-gray-900 font-semibold gap-5">
            <div class="flex flex-wrap gap-3">
                <button
                        v-for="(count, event) in showAllActivities ? events : Object.fromEntries(Object.entries(events).slice(0,5))"
                        :key="`event-${event}`"
                        class="event"
                        :class="{'active-event': event === currentEvent}"
                        @click="loadActivity(event)">
                    <span>{{ $t('appforms.subscriber.activities.' + event) }}</span>
                    <span v-if="event !== 'all'" class="count">{{ count }}</span>
                </button>
            </div>
            <button class="event more-activities flex items-center whitespace-nowrap" @click="showAllActivities = !showAllActivities">
                <span>{{ $t('appforms.subscriber.activities.more_activities') }}</span>
                <icon v-if="showAllActivities" key="events-caret-up" icon="caret-up-solid" size="small" />
                <icon v-else key="events-caret-down" icon="caret-down-solid" size="small" />
            </button>
        </div>

        <sub-activity class="mt-8" :log="activitiesEvents" :pagination="pagination" :application-currency-code="applicationCurrencyCode" @handlePageChange="handlePageChange" />

    </div>
</div>

