export default {
    methods: {
        /**
         * Returns seasonal template categories
         *
         * @param Date - date
         * @returns array[string]
         */
        getSeasonalTemplateCategories({date}) {
            const seasonalCategories = [
                {
                    filter: 'easter',
                    from: {
                        day: 14,
                        month: 3,
                    },
                    to: {
                        day: 9,
                        month: 4,
                    }
                },
                {
                    filter: 'valentine',
                    from: {
                        day: 28,
                        month: 1,
                    },
                    to: {
                        day: 16,
                        month: 2,
                    }
                },
                {
                    filter: 'halloween',
                    from: {
                        day: 10,
                        month: 10,
                    },
                    to: {
                        day: 2,
                        month: 12,
                    }
                },
                {
                    filter: 'christmas',
                    from: {
                        day: 25,
                        month: 10,
                    },
                    to: {
                        day: 31,
                        month: 12,
                    }
                },
                {
                    filter: 'new-year',
                    from: {
                        day: 5,
                        month: 12,
                    },
                    to: {
                        day: 31,
                        month: 12,
                    }
                },
                {
                    filter: 'black-friday',
                    from: {
                        day: 1,
                        month: 11,
                    },
                    to: {
                        day: 6,
                        month: 12,
                    }
                },
                {
                    filter: 'cyber-monday',
                    from: {
                        day: 1,
                        month: 11,
                    },
                    to: {
                        day: 6,
                        month: 12,
                    }
                },
            ]

            let response = []

            for (const category of seasonalCategories) {
                if(
                    (date.getMonth() + 1 > category.from.month || (date.getMonth() + 1 === category.from.month && date.getDate() >= category.from.day)) &&
                    (date.getMonth() + 1 < category.to.month || (date.getMonth() + 1 === category.to.month && date.getDate() <= category.to.day))
                ) {
                    response.push({filter: category.filter, seasonal: true})
                }
            }

            return response
        }
    }
}