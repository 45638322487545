<template>

    <ValidationObserver ref="observer" slim>

        <form novalidate method="post" @submit.prevent="duplicateAutomation">
            <div class="mx-auto px-10 md:px-0" style="max-width: 400px;">

                <div class="flex flex-col">

                    <alert v-if="success === true" class="mb-5" type="success" :message="message" />

                    <ValidationProvider v-slot="{ errors }" name="pipelineName" rules="required|max:255" slim>
                        <div class="input flex flex-col mb-8">
                            <label for="pipelineName" class="tw-label mb-2.5">{{ $t('appforms.pipelines.duplicate_automation.name_label') }}</label>
                            <input id="pipelineName" v-model="name" name="pipelineName" type="text" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.pipelines.duplicate_automation.name_placeholder')">
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div class="txt-14 uppercase mb-5 font-semibold">
                        {{ $t('appforms.pipelines.duplicate_automation.parameters_label') }}
                    </div>

                    <ecm-select v-model="multicast" class="mb-4" :options="frequencyOptions" :label="$t('appforms.pipelines.duplicate_automation.frequency_label')" />

                    <ValidationProvider v-slot="{ errors }" name="list" rules="required" slim>
                        <div class="input flex flex-col">
                            <ecm-select v-model="list" :options="lists" :label="$t('appforms.pipelines.duplicate_automation.list_label')" :title="$t('appforms.pipelines.duplicate_automation.list_title')" />
                            <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div class="tw-modal-buttons">
                        <button type="button" class="btn btn-tertiary btn-lg" @click.stop.prevent="closeModal()">{{ $t('common.cancel') }}</button>
                        <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.duplicate_automation.confirm_button') }}</button>
                    </div>

                </div>

            </div>

        </form>
    </ValidationObserver>
</template>

<script>
import {ValidationObserver} from 'vee-validate'

import Tracker from '../../../tracker'

export default {

    name: 'DuplicateAutomationForm',

    props: ['lists', 'modal'],

    components: {
        ValidationObserver
    },

    tracker: null,

    data () {
        return {
            sending: false,
            success: false,
            id: null,
            name: null,
            multicast: '0',
            frequencyOptions: [
                {
                    name: this.$t('appforms.pipelines.duplicate_automation.frequency_single'),
                    id: '0'
                },
                {
                    name: this.$t('appforms.pipelines.duplicate_automation.frequency_multiple'),
                    id: '1'
                },
                {
                    name: this.$t('appforms.pipelines.duplicate_automation.frequency_parallel'),
                    id: '2'
                }
            ],
            list: null
        }
    },

    created() {
        this.$options.tracker = new Tracker()
        if(this.modal.mdata) {
            this.id = this.modal.mdata.automation.id
            this.name = this.modal.mdata.automation.name + ' (copy)'
            this.multicast = (this.modal.mdata.automation.parallel ? '2' : (this.modal.mdata.automation.multicast ? '1' : '0'))
            this.list = this.modal.mdata.automation.list_id
        }
    },

    methods: {

        closeModal() {
            this.modal.mclose()
        },

        async duplicateAutomation() {

            const isValid = await this.$refs.observer.validate()
            if(isValid && !this.sending) {
                this.sending = true
                try {
                    await this.$http.post(`/spa/automation/${this.id}/replicate`, {
                        pipeline_name: this.name,
                        multicast: this.multicast,
                        list_id: this.list
                    })
                    this.closeModal()
                    this.$emit('automationDuplicated', this.name)
                }
                catch(error) {
                    this.success = false
                    this.message = error.message
                }
                this.sending = false
            }
        }
    }
}
</script>
