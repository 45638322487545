<template>

    <div>
        <h2 class="mb-2.5">
            {{ $t('appforms.pipelines.tailor_made_form.heading') }}
        </h2>
        <p class="txt-14 dimmed90 mb-6" v-html="$t('appforms.pipelines.tailor_made_form.description')" />

        <div class="mt-6 tw-label text-sm">
            {{ $t('appforms.pipelines.tailor_made_form.form_title') }}
        </div>
        <alert v-if="success !== null" class="mt-5" :type="success ? 'success' : 'error'" :message="message" />
        <div class="mt-4">
            <div v-for="(type, index) in serviceTypes" :key="`service-type_${index}`" class="p-4 cursor-pointer panel has-green-hover border border-gray-500 rounded mb-2" :class="{'border-green-400': type == chosenServiceType, 'shadow-md': type == chosenServiceType, 'bg-white': type == chosenServiceType }" @click="chosenServiceType = type">
                <div class="tw-radio">
                    <label :for="`template_type-${index}`">
                        <input :id="`template_type-${index}`" v-model="chosenServiceType" type="radio" name="type" :value="type">
                        <span class="radiomark mr-3" /> <span>{{ $t(`appforms.pipelines.tailor_made_form.service_types.${type}.text`) }} </span>
                    </label>
                </div>

                <div v-if="type == chosenServiceType" class="pt-1 ml-8 text-gray-800" style="font-size: 13px" v-html="$t(`appforms.pipelines.tailor_made_form.service_types.${type}.hint`)" />
            </div>
        </div>

        <a v-if="!showNote" class="my-6 flex items-center semibold text-sm cursor-pointer" @click="showNote = true">
            <icon icon="plus-circle-solid" size="small" />
            {{ $t('appforms.pipelines.tailor_made_form.add_note') }}
        </a>
        <div v-else class="my-6">
            <div class="mb-4 tw-label text-sm">
                {{ $t('appforms.pipelines.tailor_made_form.note_label') }}
            </div>
            <textarea id="importNotes" v-model="note" name="note" rows="3" class="tw-input w-full" :placeholder="$t('appforms.pipelines.tailor_made_form.note_placeholder')" />
        </div>

        <div class="tw-modal-buttons">
            <button class="btn btn-tertiary tw-modal-close-button btn-lg" @click.stop.prevent="closeModal">{{ $t('common.cancel') }}</button>
            <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg" @click="requestTailorMadeTemplate()"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.tailor_made_form.button') }}</button>
        </div>
    </div>

</template>

<script>

export default {

    name: 'TailorMadeAutomationForm',

    props: ['modal'],

    data () {
        return {
            sending: false,
            success: null,
            message: null,
            serviceTypes: ['whole_automation', 'another_platform', 'edit_template'],
            chosenServiceType: null,
            showNote: false,
            note: null
        }
    },

    methods: {

        closeModal() {
            this.modal.mclose()
        },

        async requestTailorMadeTemplate() {
            this.success = null
            this.message = null
            this.sending = true
            try {
                await this.$http.post('/spa/automation/request-tailor-made-automation', {
                    service_type: this.chosenServiceType ? this.$t(`appforms.pipelines.tailor_made_form.service_types.${this.chosenServiceType}.text`) : 'Nezadal/ a',
                    note: this.note ? this.note : 'Žádná'
                })
                this.success = true
                this.message = this.$t('appforms.pipelines.tailor_made_form.request_sent')
            } catch(error) {
                this.success = false
                this.message = this.$t('common.unexpected_error')
            }
            this.sending = false
        },
    }
}
</script>
