
<div class="relative">
    <div ref="scrollable" class="hide-scrollbar overflow-y-hidden overflow-x-auto" @scroll="onScroll">
        <draggable v-model="localTabs" v-bind="dragOptions" handle=".tab-handle" @change="onSort" @start="drag = true" @end="drag = false">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="flex space-x-1">
                <slot name="fillBefore" />
                <slot name="tab" :options="options" :trigger-select="handleSelect" />
                <slot name="fillAfter" />
            </transition-group>
        </draggable>
    </div>
    <template v-if="scrollable && !isOnStart">
        <div class="absolute h-full left-gradient" style="width: 53px; left: -1px; top: 0" />
        <button class="btn btn-tertiary icon-only absolute" style="left: 0; bottom: 9px;" @click.prevent="moveLeft">
            <icon key="left-angle" class="text-gray-900" icon="angle-left-solid" />
        </button>
    </template>
    <template v-if="scrollable && !isOnEnd">
        <div class="absolute h-full right-gradient" style="width: 53px; right: -1px; top: 0" />
        <button class="btn btn-tertiary icon-only absolute" style="right: 0; bottom: 9px;" @click.prevent="moveRight">
            <icon key="right-angle" class="text-gray-900" icon="angle-right-solid" />
        </button>
    </template>
</div>
