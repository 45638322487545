import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@stores/index'
import i18n from './i18n'
const { isNavigationFailure } = VueRouter

// Auth components
import login from '@pages/auth/login.vue'
import passwordReset from '@pages/auth/passwordReset.vue'
import compromisedPasswordReset from '@pages/auth/compromisedPasswordReset.vue'
import setNewPassword from '@pages/auth/setNewPassword.vue'
import join from '@pages/auth/join.vue'
import twoFactorEmail from '@pages/auth/twoFactorEmail.vue'
import twoFactorAuth from '@pages/auth/twoFactorAuthenticator.vue'
import twoFactorRecoveryCodes from '@pages/auth/twoFactorRecoveryCodes.vue'

// Landing page components
import shopifyConnected from '@pages/shopifyConnected.vue'

// Automations components
import automationsIndex from '@pages/automations/index.vue'
import automationsReport from '@pages/automations/report.vue'
import automationsSettings from '@pages/automations/settings.vue'
import automationsEdit from '@pages/automations/edit.vue'
import automationsActivities from '@pages/automations/activities.vue'
import automationsTesting from '@pages/automations/testing.vue'

// Error pages
import errorPage from '@pages/errors/errorPage.vue'

const AutomationDetailLayout = () => import('@layouts/automationDetail.vue')
const AuthLayout = () => import('@layouts/auth.vue')

Vue.use(VueRouter)

const routes = [
    { path: '/auth', component: AuthLayout, meta : { title: i18n.t('meta.auth') },
        children: [
            { path: 'login', name: 'login', component: login },
            { path: 'reset-password', name: 'passwordReset', component: passwordReset },
            { path: 'set-new-password/:token', name: 'setNewPassword', component: setNewPassword },
            { path: 'join', name: 'join', component: join },
            { path: '2fa-email', name: 'twoFactorEmail', component: twoFactorEmail },
            { path: '2fa-auth', name: 'twoFactorAuth', component: twoFactorAuth },
            { path: '2fa-auth-recovery', name: 'twoFactorAuthRecovery', component: twoFactorRecoveryCodes },
            { path: 'compromised-reset-password', name: 'compromisedPasswordReset', component: compromisedPasswordReset },
        ]
    },
    { path: '/integrations/shopify-landing/connected', component: shopifyConnected, meta : { title: i18n.t('meta.account') }},
    { path: '/automation', name: 'automationsIndex', component: automationsIndex, meta : { title: i18n.t('meta.automations') }},
    { path: '/automation/:automation(\\d+)', component: AutomationDetailLayout, meta : { title: i18n.t('meta.automations') },
        children: [
            { path: 'report', name: 'automationsReport', component: automationsReport},
            { path: '', name: 'automationsSettings', component: automationsSettings},
            { path: 'activities/:activity', name: 'automationsActivities', component: automationsActivities},
            { path: 'testing', name: 'automationsTesting', component: automationsTesting},
        ]
    },
    { path: '/automation/:automation(\\d+)/edit', name: 'automationsEdit', component: automationsEdit, meta : { title: i18n.t('meta.automations') }},
    { path: '/login', redirect: '/auth/login', meta : { title: i18n.t('meta.auth') } },
    { path: '/password/reset', redirect: '/auth/reset-password', meta : { title: i18n.t('meta.reset_password') } },
    { path: '/public/join', redirect: '/auth/join', meta : { title: i18n.t('meta.join') } },
    { path: '*', name: 'errorPage', component: errorPage },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// reset vuex module when switching between sections
router.beforeEach((to, from, next) => {
    const fromSection = from.path.split('/')[1]
    const toSection = to.path.split('/')[1]
    if (fromSection !== toSection) {
        store.dispatch('resetModule', toSection)
    }
    next()
})

// set meta title
router.afterEach((to) => {
    if(to.matched && to.matched[0] && to.matched[0].meta.title) {
        const isImpersonating = window.isImpersonating
        const accountNameAbbreviated = window.location.hostname.split('.')[0].slice(0,5)
        const section = to.matched[0].meta.title
        if(isImpersonating) {
            document.title = `${accountNameAbbreviated} | ${section} | Ecomail`
        }
        else {
            document.title = `${section} | Ecomail`
        }
    }
})

// ignore duplicate navigation errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(error =>  {
        if(!isNavigationFailure(error))
            throw error
    })
}

export default router
