<template>
    <button
            :type="type"
            :disabled="disabledOrLoading"
            class="flex items-center justify-center transition duration-200 ease-in-out focus:outline-none focus:ring-4 ring-auth-green-500 ring-opacity-50 active:bg-auth-green-700"
            :class="{
                'px-5 py-2.5 text-auth-sm h-[44px]': base && !icon,
                'pl-4 pr-5 py-2.5 text-auth-sm h-[44px]': base && icon,
                'rounded-[22px]': base && rounded,
                'rounded-[22px] p-3': iconfull,
                'py-3 px-6 text-auth-base leading-6 h-12': large && !icon,
                'py-3 pl-4 pr-6 text-auth-base leading-6 h-12': large && icon,
                'rounded-xl': large && rounded,
                'py-[10px] h-[44px] px-[64px] md:px-[36px] md:py-[18px] md:h-[60px]': xlarge && !icon,
                'pl-[20px] pr-[36px] py-[18px] h-[60px]': xlarge && icon,
                'rounded-[30px]': xlarge && rounded,
                'bg-auth-green-500 hover:bg-auth-green-600 text-auth-white font-medium hover:shadow-md':
                    primary && !disabled,
                'hover:bg-[#E8E8E8] text-auth-white font-medium': primary && disabled,
                'bg-auth-green-200 text-auth-green-500 hover:text-auth-green-600 hover:bg-auth-green-300':
                    secondary,
                'rounded-md': square,
                'cursor-not-allowed bg-[#E8E8E8]  border-[#D7D7D7] border text-[#A8A8A8] hover:shadow-none':
                    disabled,
                'cursor-pointer': !disabled,
            }"
            @click="$emit('click')">
        <svg v-if="loading" class="animate-spin -ml-8 mr-3 h-5 w-5 text-[#A8A8A8]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" />
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>

        <div
                :class="{
                    'pr-2 ': base && icon,
                    'pr-2.5': large && icon,
                    'pr-4': xlarge && icon,
                }">
            <slot name="icon" />
        </div>
        <div class="bold">
            <slot />
        </div>
    </button>
</template>

<script>
export default {
    props: {
        base: Boolean,
        large: Boolean,
        xlarge: Boolean,
        rounded: Boolean,
        primary: Boolean,
        secondary: Boolean,
        square: Boolean,
        disabled: Boolean,
        icon: Boolean,
        iconfull: Boolean,
        type: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        disabledOrLoading() {
            return this.disabled || this.loading
        }
    }
}
</script>

<style scoped>

</style>
