
// Legancy js
var domIsReady = (function(domIsReady) {
    var isBrowserIeOrNot = function() {
        return (!document.attachEvent || typeof document.attachEvent === 'undefined' ? 'not-ie' : 'ie')
    }

    domIsReady = function(callback) {
        if(callback && typeof callback === 'function'){
            if(isBrowserIeOrNot() !== 'ie') {
                document.addEventListener('DOMContentLoaded', function() {
                    return callback()
                })
            } else {
                document.attachEvent('onreadystatechange', function() {
                    if(document.readyState === 'complete') {
                        return callback()
                    }
                })
            }
        } else {
            console.error('The callback is not a function!')
        }
    }

    return domIsReady
})(domIsReady || {});
(function(document, window, domIsReady, undefined) {
    domIsReady(function() {

        // Search globally
        if(document.querySelector('#searchButton') !== null) {
            document.querySelector('#searchButton').addEventListener('click', function () {
                var el = document.querySelector('#searchModal')
                el.classList.toggle('open')
                setTimeout(() => {
                    el.classList.toggle('in')
                }, 10)
            })
            document.querySelector('#searchModal').addEventListener('click', function (tg) {
                //if(tg.target);
                if(tg.target.id === 'searchModal') {
                    var el = document.querySelector('#searchModal')
                    el.classList.toggle('in')
                    setTimeout(() => {
                        el.classList.toggle('open')
                    }, 250)
                }
            })
        }

        // Modals
        if(document.querySelectorAll('[data-tw-modal]') !== null) {
            let elems = document.querySelectorAll('[data-tw-modal]')
            for(var i = 0; i < elems.length; i++) {
                elems[i].addEventListener('click', function (el) {
                    el.preventDefault()
                    try {
                        if (this.dataset.twModalTitle) {
                            window.vueApp.$refs[this.dataset.twModal].setTitle(this.dataset.twModalTitle)
                        } else {
                            window.vueApp.$refs[this.dataset.twModal].setTitle(null)
                        }
                        if (this.dataset.twModalData) {
                            window.vueApp.$refs[this.dataset.twModal].showModalWithData(this.dataset.twModalData)
                        } else {
                            window.vueApp.$refs[this.dataset.twModal].showModal()
                        }
                    } catch (e) {

                    }
                    return false
                })
            }
        }
        if(document.querySelectorAll('[data-tw-confirm]') !== null) {
            let elems = document.querySelectorAll('[data-tw-confirm]')
            for(var i = 0; i < elems.length; i++) {
                elems[i].addEventListener('click', function (el) {
                    el.preventDefault()
                    window.vueApp.$refs['confirm'].setData(this.dataset)
                    window.vueApp.$refs['confirm'].showModal()
                    return false
                })
            }
        }

        // Clickmap Iframe
        if(document.querySelector('#templateClickMapIframe') !== null) {

            document.querySelector('#templateClickMapIframe').addEventListener('load', function (tg) {

                var stylesHtml = '.clickrate{position: absolute !important;display: block !important;background-color: black !important;opacity: 0.6 !important;padding: 5px !important;color: white !important;font-size: 12px !important;text-transform: none !important;font-family: Arial !important;line-height: 12px !important;font-weight: bold !important;width: 80px !important;height: 12px;z-index: 99999;}.clickrate .bg{background-color: green !important;height: 22px;position: absolute;top: 0;left: 0;}.clickrate span{position: relative;}'
                var css = document.createElement('style')
                if (css.styleSheet) {
                    css.styleSheet.cssText = stylesHtml
                } else {
                    css.appendChild(document.createTextNode(stylesHtml))
                }

                try {
                    tg.target.contentWindow.document.body.append(css)
                    tg.target.height = tg.target.contentWindow.document.body.offsetHeight + 'px'
                } catch (exception) {
                    console.log(exception)
                    return false
                }

                var totalClicks = document.getElementById('totalLinkClicks').value
                var table = document.getElementById('linksStatsTable')
                var count = table.rows.length
                for(var r=0; r < count; r++) {
                    var link = table.rows[r].cells[1].querySelector('a').getAttribute('href')
                    var clicks = table.rows[r].cells[0].textContent
                    var linkElements = tg.target.contentWindow.document.body.querySelectorAll('a[href="' + link + '"]')
                    if(linkElements.length == 0) {
                        linkElements = tg.target.contentWindow.document.body.querySelectorAll('a[href*="' + link + '"]')
                    }
                    for(var i = 0; i < linkElements.length; i++) {
                        var e = document.createElement('div')
                        e.innerHTML = '<div class="clickrate"><div class="bg" style="width:' + Math.round((clicks / totalClicks)*100) + '%"></div><span>' + Math.round((clicks / totalClicks)*100) + '%&nbsp;(' + clicks + ')</span></div>'
                        linkElements[i].style.position = 'relative'
                        linkElements[i].appendChild(e.firstChild)
                    }
                }
            })

            /*
            $("#templateIframe").load(function() {
                $(this).height( $(this).contents().find("body").height() + 150);
                if($('#linksStatsTable').length){
                    showClickMap();
                }
            });
            $('#formIframe').load(function() {
                autoSizeformIframe();
            });

            function showClickMap(){

                var stylesHtml = '<style>.clickrate{position: absolute !important;display: block !important;background-color: black !important;opacity: 0.6 !important;padding: 5px !important;color: white !important;font-size: 12px !important;text-transform: none !important;font-family: Arial !important;line-height: 12px !important;font-weight: bold !important;width: 80px !important;height: 12px;z-index: 99999;}.clickrate .bg{background-color: green !important;height: 22px;position: absolute;top: 0;left: 0;}.clickrate span{position: relative;}</style>'
                var totalClicks = $('input[name="totalClicks"]').val();
                $("#templateIframe").contents().find('body').append(stylesHtml);
                $('#linksStatsTable').find('tr').each(function( index ) {
                    if($(this).find('td a').length){
                        var link = $(this).find('td a').attr('href');
                        var clicks = $(this).find('td').last().text();
                        //$("#templateIframe").contents().find('body').prepend('test');
                        highlightLink($("#templateIframe").contents().find('body').find('a[href="'+link+'"]'), clicks, Math.round((clicks / totalClicks)*100));
                    }
                });
            }*/

        }

        // Campaigns overview mass actions
        if(document.querySelector('#campaignsOverview') !== null) {
            let elems = document.querySelectorAll('#campaignsOverview .checkbox-solo input')
            for(var i = 0; i < elems.length; i++) {
                elems[i].addEventListener('change', function (el) {
                    let allElems = document.querySelectorAll('#campaignsOverview .checkbox-solo input')
                    let campaigns = []
                    for(var i = 0; i < allElems.length; i++) {
                        if(allElems[i].checked) {
                            window.vueApp.$refs['moveCampaignsToFolder'].setVisible()
                            campaigns.push(elems[i].value)
                        }
                    }
                    window.vueApp.$refs['moveCampaignsToFolder'].setChecked(campaigns)
                    if(campaigns.length === 0) {
                        window.vueApp.$refs['moveCampaignsToFolder'].setHidden()
                    }
                })
            }
        }

        // Contacts overview mass actions
        if(document.querySelector('#contactsOverview') !== null) {
            let elems = document.querySelectorAll('#contactsOverview tbody .checkbox-solo input')
            document.querySelector('#contactsOverview thead .checkbox-solo input').addEventListener('change', function (tg) {
                if(this.checked) {
                    let emails = []
                    for(var i = 0; i < elems.length; i++) {
                        elems[i].checked = true
                        emails.push(elems[i].value)
                    }
                    document.getElementById('massActionsDropdown').disabled = false
                    document.getElementById('massSelectedEmails').value = JSON.stringify(emails)
                } else {
                    for(var i = 0; i < elems.length; i++) {
                        elems[i].checked = false
                    }
                    document.getElementById('massActionsDropdown').disabled = true
                }
            })
            for(var i = 0; i < elems.length; i++) {
                elems[i].addEventListener('change', function (el) {
                    let allElems = document.querySelectorAll('#contactsOverview tbody .checkbox-solo input')
                    let emails = []
                    for(var i = 0; i < allElems.length; i++) {
                        if(allElems[i].checked) {
                            document.getElementById('massActionsDropdown').disabled = false
                            emails.push(elems[i].value)
                        }
                    }
                    document.getElementById('massSelectedEmails').value = JSON.stringify(emails)
                    if(emails.length === 0) {
                        document.getElementById('massActionsDropdown').disabled = true
                    }
                })
            }
        }

    })
})(document, window, domIsReady)
