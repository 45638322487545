<template>
    <div class="relative">
        <div ref="scrollable" class="hide-scrollbar overflow-y-hidden overflow-x-auto" @scroll="onScroll">
            <draggable v-model="localTabs" v-bind="dragOptions" handle=".tab-handle" @change="onSort" @start="drag = true" @end="drag = false">
                <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="flex space-x-1">
                    <slot name="fillBefore" />
                    <slot name="tab" :options="options" :trigger-select="handleSelect" />
                    <slot name="fillAfter" />
                </transition-group>
            </draggable>
        </div>
        <template v-if="scrollable && !isOnStart">
            <div class="absolute h-full left-gradient" style="width: 53px; left: -1px; top: 0" />
            <button class="btn btn-tertiary icon-only absolute" style="left: 0; bottom: 9px;" @click.prevent="moveLeft">
                <icon key="left-angle" class="text-gray-900" icon="angle-left-solid" />
            </button>
        </template>
        <template v-if="scrollable && !isOnEnd">
            <div class="absolute h-full right-gradient" style="width: 53px; right: -1px; top: 0" />
            <button class="btn btn-tertiary icon-only absolute" style="right: 0; bottom: 9px;" @click.prevent="moveRight">
                <icon key="right-angle" class="text-gray-900" icon="angle-right-solid" />
            </button>
        </template>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'TabRow',

    props: {
        value: {
            type: [String, Number],
            required: true
        },
        // initializes referential localTabs array size
        draggableLength: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            default() {
                return []
            }
        }
    },

    components: {
        draggable
    },

    data () {
        return {
            localValue: null,
            localTabs: [],
            drag: false,
            scrollable: false,
            maxScroll: 0,
            stepScroll: 0,
            isOnStart: false,
            isOnEnd: false,
        }
    },

    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'tabs',
                disabled: !this.options.includes('sortable') || !this.draggableLength === 0
            }
        }
    },

    watch: {
        // keeps indexes in the correct range
        draggableLength(newVal, oldVal) {
            this.checkScroll()
            if(newVal < oldVal) {
                this.localTabs.pop()
            }
            else {
                this.localTabs.push(0)
            }
        },
        value(val) {
            this.localValue = val
        },
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true
                return
            }
            this.$nextTick(() => {
                this.delayedDragging = false
            })
        }
    },

    mounted() {
        this.checkScroll()
        window.addEventListener('resize', this.checkScroll)
        this.localValue = this.value
        // only serves as a reference to the real tabs in parent component (does not contain tangible data)
        this.localTabs = new Array(this.draggableLength).fill(0)
    },

    destroyed() {
        window.removeEventListener('resize', this.checkScroll)
    },

    methods: {
        handleSelect(value) {
            this.localValue = value
            this.$emit('input', this.localValue)
        },
        // sends only old index and new index, not the actual data
        onSort(event) {
            if(event.moved) {
                this.$emit('tabSorted', event.moved)
            }
        },
        moveLeft() {
            this.$refs.scrollable.scrollLeft -= this.stepScroll
            this.$refs.scrollable.scrollLeft = this.$refs.scrollable.scrollLeft >= 0 ? this.$refs.scrollable.scrollLeft: 0
        },
        moveRight() {
            this.$refs.scrollable.scrollLeft += this.stepScroll
            this.$refs.scrollable.scrollLeft = this.$refs.scrollable.scrollLeft <= this.maxScroll ? this.$refs.scrollable.scrollLeft: this.maxScroll
        },
        checkScroll() {
            this.$nextTick(() => {
                if(this.$refs.scrollable && this.$refs.scrollable.clientWidth < this.$refs.scrollable.scrollWidth){
                    this.scrollable = true
                    this.maxScroll = this.$refs.scrollable.scrollWidth - this.$refs.scrollable.clientWidth
                    this.stepScroll = this.$refs.scrollable.clientWidth / 4
                    this.onScroll()
                } else {
                    this.scrollable = false
                    this.maxScroll = 0
                }
            })

        },
        onScroll() {
            this.isOnStart = this.$refs.scrollable.scrollLeft <= 0
            this.isOnEnd = this.$refs.scrollable.scrollLeft >= this.maxScroll - 10
        },
    }
}
</script>