
<ValidationObserver ref="observer" slim>
    <form novalidate method="post" class="flex flex-col mb-0" @submit.prevent="updateAutomation">

        <alert v-if="success === false" class="mb-5" type="success" :message="message.error" />

        <h2>
            {{ $t('appforms.pipelines.edit_automation.heading') }}
        </h2>

        <div class="txt-14 text-gray-900 mt-0.5 mb-9">
            {{ $t('appforms.pipelines.edit_automation.description') }}
        </div>

        <ValidationProvider v-slot="{ errors }" name="pipelineName" rules="required|max:255" slim>
            <div class="input flex flex-col mb-8">
                <label for="pipelineName" class="tw-label mb-2.5">{{ $t('appforms.pipelines.edit_automation.name_label') }}</label>
                <input id="pipelineName" v-model="name" name="pipelineName" type="text" class="tw-input" :class="{'tw-err': errors.length > 0 }" :placeholder="$t('appforms.pipelines.edit_automation.name_placeholder')">
                <span v-if="errors[0]" class="tw-errors">{{ errors[0] }}</span>
            </div>
        </ValidationProvider>

        <div class="txt-14 uppercase mb-5 font-semibold">
            {{ $t('appforms.pipelines.edit_automation.parameters_label') }}
        </div>

        <ecm-select v-model="multicast" :options="frequencyOptions" :label="$t('appforms.pipelines.edit_automation.frequency_label')" />

        <div class="tw-modal-buttons">
            <button type="button" class="btn btn-tertiary btn-lg" @click.stop.prevent="closeModal()">{{ $t('common.close') }}</button>
            <button :disabled="sending" type="submit" class="btn btn-primary float-right btn-lg"><div v-if="sending" class="double-loader loader-sm loader-grey mr-2" />{{ $t('appforms.pipelines.edit_automation.confirm_button') }}</button>
        </div>
    </form>
</ValidationObserver>
