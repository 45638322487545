

<div class="mx-9 mt-6 mb-9">
    <h2>
        {{ $t('appforms.subscriber.ml') }}
    </h2>

    <div v-if="loadingMl" class="w-full my-16 flex items-center justify-center">
        <div class="double-loader loader-sm loader-grey" />
    </div>

    <div v-else-if="error === 'no-recommender'" class="panel p-10 w-full mt-5">

        <div class="flex mb-3 dimmed90 txt-14 items-center w-full">
            <icon class="mr-2 alert-55" icon="info-circle-solid" />

            <span v-html="$t('appforms.subscriber.machine_learning')" />
        </div>

    </div>

    <div v-else class="mt-5">

        <div v-for="(recomm, index) in recomms" :key="`recomm-${index}`" class="card grey subscriber orders mb-2 flex-col card-left no-hover space-y-2.5">
            <div class="w-full flex justify-between text-gray-1000 txt-13 mb-1">
                <div class="flex flex-col">
                    <span class="font-semibold">{{ recomm.product }}</span>
                    <span class="text-gray-700">{{ recomm.category }}</span>
                </div>
                <span>{{ recomm.price_vat }} Kč</span>
            </div>
        </div>

    </div>
</div>

