

<transition name="slidein">
    <div v-if="visible" class="search-modal-bg" @mousedown.self="closeModal" @keyup="keyUp">

        <div class="search-modal-container">
            <div class="search-modal-content">
                <div class="flex justify-between items-center">
                    <div class="flex items-center w-full">
                        <input ref="search-input" v-model="query" type="text" :placeholder="$t('search-modal.input_placeholder')" class="ml-1">
                    </div>
                    <button class="btn btn-primary btn-lg" @click="search">{{ $t('search-modal.button') }}</button>
                </div>
            </div>

        </div>

    </div>
</transition>

