<template>
    <transition name="long-fadeout">
        <div class="mc-r w-full">
            <div class="main-panel solo">
                <div v-if="automation" class="automations-header">
                    <h2>
                        {{ $t('automations.edit_automation') }}
                    </h2>
                    <div class="flex flex-grow justify-between">
                        <a href="#" class="automation-props-btn btn btn-tertiary" @click.stop.prevent="showPropsModal">
                            <div class="br mr-2">
                                <span class="txt-18 regular pr-2.5 br overflow-hidden truncate" style="max-width: 260px">{{ automation.name }}</span>
                                <span class="px-2.5 flex items-center br"><icon icon="play-circle-solid" size="small" class="mr-1 text-gray-800" /> {{ $t('automations.type.' + typeText) }}</span>
                                <span class="px-2.5 flex items-center"><icon icon="list-regular" size="small" class="mr-1 text-gray-800" /> {{ list ? list.name : 'N/A' }}</span>
                            </div>
                            <icon icon="pen-solid" />
                        </a>

                        <div class="flex items-center">
                            <a href="#" class="btn btn-tertiary mr-3" @click.stop.prevent="$refs['automation-builder'].gotoPipelineOverview()">{{ account.isBlocked ? $t('automations.save-close-builder') : $t('automations.close-builder') }}</a>
                            <a v-if="!account.isBlocked" href="#" class="btn btn-primary icon-left" @click.stop.prevent="$refs['automation-builder'].startAndGotoPipelineOverview()">
                                <icon icon="play-circle-solid" size="small" />
                                {{ $t('automations.start-and-close') }}
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="!automation && !editLoaded" class="h-screen mt-4 ml-4 double-loader" />

                <div v-else class="pr-4">
                    <automation-builder
                            ref="automation-builder"
                            :marketer="account.hasAllowedMarketerFunctions"
                            :can-create-sms-campaign="account.canSendSms"
                            :editable="true"
                            :list-id="list ? list.id : null"
                            :list-name="list ? list.name : null"
                            :fbpages="pages"
                            :initpipeline="automation"
                            :plumbing="plumbing"
                            :integrations="integrations"
                            :lists-with-custom-fields="listsWithCustomFields"
                            :customfields="list ? list.custom_fields : {}"
                            :strategy="strategy"
                            :is-english-account="account.isEnglishAccount" />
                </div>

                <tw-modal ref="pipelineProps" :title="$t('appforms.pipelines.edit_automation.title')" width="472" close-button="yes">
                    <template #default="slotProps">

                        <edit-automation :modal="slotProps" @automationEdited="automationEdited" />

                    </template>
                </tw-modal>
            </div>
        </div>
    </transition>
</template>

<script>
import store from '@stores/index'
import { mapState } from 'vuex'

export default {
    name: 'Edit',

    store,

    computed: {
        ...mapState(['account']),
        ...mapState('automationsModule', [
            'automation', 'editLoaded', 'actions', 'list', 'pages', 'starts', 'integrations', 'plumbing', 'strategy', 'mode', 'listsWithCustomFields'
        ]),
        typeText () {
            if(this.automation.parallel) {
                return 'parallel'
            } else if(this.automation.multicast) {
                return 'multicast'
            }

            return 'regular'
        },
        typeVal () {
            if(this.automation.parallel) {
                return '2'
            } else if(this.automation.multicast) {
                return '1'
            }

            return '0'
        },
    },

    beforeRouteEnter(to, from, next) {
        store.dispatch('automationsModule/resetAutomation')
        next(vm => {
            vm.$root.showSpaLoader = false
        })
    },

    created() {
        this.loadData()
    },

    methods: {
        async loadData () {
            await store.dispatch('loadAccountData')
            await store.dispatch('automationsModule/loadEdit', {automationId: this.$route.params.automation, editor: true, strategy: this.$route.query.strategy})
        },

        showPropsModal() {
            this.$refs['pipelineProps'].showModalRawData({
                id: this.automation.id,
                name: this.automation.name,
                type: this.typeVal
            })
        },

        automationEdited(props) {
            store.dispatch('automationsModule/editAutomationProps', props)
            this.$message.success(this.$t('common.changes_saved'))
        }
    }
}
</script>
